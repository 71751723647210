import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-exams-results',
  templateUrl: './exams-results.component.html',
})
export class ExamsResultsComponent implements OnInit {

  title: string ="Resultados de examen";
  subTitle: string ="Consumiendo los servicios especificados desde la API de Saludtools usted puede cargar, leer y buscar documentos del paciente, permitiendo al usuario una integración más sencilla."
  paragraphTitle: string ="¿La información de mis pacientes es confidencial?";
  paragraph: string ="TOTALMENTE. Saludtools es un sistema de primer nivel que garantiza totalmente la confidencialidad de la información personal de sus pacientes. NUNCA se compartirá con un tercero o se comercializará sin la autorización expresa del médico o el paciente. Este compromiso se documenta en nuestra licencia de uso, que deja este punto totalmente claro.";

  constructor() { }

  ngOnInit(): void {
  }

}
