import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-parameters-nav-tabs',
  templateUrl: './parameters-nav-tabs.component.html',
  styleUrls: ['./parameters-nav-tabs.component.css']
})
export class ParametersNavTabsComponent implements OnInit {

  show=true;

  environmentShow(){
    if(sessionStorage.getItem('environment') == '0' ){
      this.show = false;
    }
  }

  constructor() { }

  ngOnInit(): void {
    this.environmentShow()
  }

}
