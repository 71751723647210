import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationError } from 'src/app/models/authentication-error';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'app-authentication',
  templateUrl: './authentication.component.html',
  styleUrls: ['./authentication.component.css']
})
export class AuthenticationComponent implements OnInit {

  authenticationForm: FormGroup;
  submitted = false;
  json: any;
  showClass: boolean = false;
  showClass2: boolean = false;

  constructor(private formBuilder: FormBuilder, private authenticationService: AuthenticationService) { }

  ngOnInit() {
    this.authenticationForm = this.formBuilder.group(
      {
        key: ["", Validators.required],
        secret: ["", Validators.required],
      },

    );
  }


  get form() {
    return this.authenticationForm.controls;
  }

  onSubmit() {

    if (this.authenticationForm.invalid) {
      if (this.form.key.errors) {
        this.showClass = true;
      }

      if (this.form.secret.errors) {
        this.showClass2 = true;
      }
      return;
    }

    this.authenticationService.authentication(this.authenticationForm.value).subscribe(
      response => {
        localStorage.setItem('access_token', response.access_token);
        this.json = response;
      },
      (error: AuthenticationError) => {
        this.json = error.error.message
      }
    )
    this.submitted = true;
  }

  onReset() {
    this.submitted = false;
    this.authenticationForm.reset();
    this.showClass = false;
    this.showClass2 = false;
  }

}
