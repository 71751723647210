import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthenticationResponse } from '../models/authentication-response';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  
  constructor(private http: HttpClient) { }

  authentication(credentials: JSON){

    let url:string = 'https://saludtools.qa.carecloud.com.co/integration/authenticate/apikey/v1/';

    if(sessionStorage.environment == 0){
      url= 'https://saludtools.carecloud.com.co/integration/authenticate/apikey/v1/';
    }
    if(sessionStorage.environment == 1){
      url= 'https://saludtools.qa.carecloud.com.co/integration/authenticate/apikey/v1/';
    }

    const urlAuthenticationLocal = 'http://localhost:8090/integration/authenticate/apikey/v1/';
  
    return this.http.post<AuthenticationResponse>(url, credentials);
   ;
  }
  
}
