<ul class="nav nav-tabs" id="classtab">
    <li class="active"><a class="active" data-toggle="tab" *ngIf="show">Prueba</a></li>
    <li class="active"><a class="active" data-toggle="tab" *ngIf="!show">Producción</a></li>
</ul>
<div class="tab-content" id="classcont">
    <div class="tab-pane fade in active show">
        <div class="body-param">
            <h4 class="body-param-title">{{curlEjemplo.title}}</h4>
            <pre class="pre-code" *ngIf="show">
                    <code [highlight]="curlEjemplo.bodyPrueba" > 
                    </code>
            </pre>
            <pre class="pre-code" *ngIf="!show">
                <code  [highlight]="curlEjemplo.bodyProduccion" > 
                </code>
            </pre>
            <p class="p-code">{{curlEjemplo.subtitle}}</p>
        </div>
    </div>
</div>
<br>