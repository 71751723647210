import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-patient-search',
  templateUrl: './patient-search.component.html',
  styleUrls: ['./patient-search.component.css']
})
export class PatientSearchComponent implements OnInit {

  title: string = "Buscar Paciente";
  subTitle: string = "Puede filtrar una lista de pacientes dado una cantidad de parámetros."

  paragraphTitle: string = "¿Cómo filtrar pacientes?";
  paragraph: string = "Para buscar una lista de pacientes, primero debe tener un token de acceso activo. Si su token se venció, o aun no ha generado un token de acceso. genere uno nuevo. Si ya está autenticado debe colocar en el body los parámetros que quiera filtrar. Puede elegir un parámetro o hacer una combinación de los parámetros que desee agregar para filtrar los pacientes.";

  parameters = [
    {
      "Parameters": "eventType",
      "requerido": true,
      "Type": "String",
      "Description": "Es el tipo de evento que se quiere invocar en este caso debe ser \"PATIENT\"."
    },
    {
      "Parameters": "actionType",
      "requerido": true,
      "Type": "String",
      "Description": "Es la acción que se quiere realizar para buscar pacientea debe ser \"SEARCH\""
    },
    {
      "Parameters": "firstName",
      "requerido": false,
      "Type": "String",
      "Description": "Primer nombre del paciente"
    },
    {
      "Parameters": "secondName",
      "requerido": false,
      "Type": "String",
      "Description": "Segundo nombre del paciente"
    },
    {
      "Parameters": "firstLastName",
      "requerido": false,
      "Type": "String",
      "Description": "Primer apellido del paciente"
    },
    {
      "Parameters": "secondLastName",
      "requerido": false,
      "Type": "String",
      "Description": "Segundo apellido del paciente"
    },
    {
      "Parameters": "birthDate",
      "requerido": false,
      "Type": "LocalDate",
      "Description": "Fecha de nacimiento del paciente Formato yyyy-mm-dd."
    },
    {
      "Parameters": "gender",
      "requerido": false,
      "Type": "Long",
      "Description": "Id de género del paciente"
    },
    {
      "Parameters": "documentType",
      "requerido": false,
      "Type": "Long",
      "Description": "Id del tipo de documento de identidad del paciente"
    },
    {
      "Parameters": "documentNumber",
      "requerido": false,
      "Type": "String",
      "Description": "Número del documento de identidad del paciente"
    },
    {
      "Parameters": "phone",
      "requerido": false,
      "Type": "String",
      "Description": "Número de teléfono del paciente"
    },
    {
      "Parameters": "cellPhone",
      "requerido": false,
      "Type": "String",
      "Description": "Número de celular del paciente"
    },
    {
      "Parameters": "email",
      "requerido": false,
      "Type": "String",
      "Description": "Correo electrónico del paciente"
    },
    {
      "Parameters": "eps",
      "requerido": false,
      "Type": "Long",
      "Description": "eps registrada del paciente"
    },
    {
      "Parameters": "habeasData",
      "requerido": false,
      "Type": "Boolean",
      "Description": "Confirmación del paciente de recibir comunicación (Habeas Data)"
    },
    {
      "Parameters": "page",
      "requerido": true,
      "Type": "Integer",
      "Description": "Página que se va a mostrar. la primera Página es 0"
    },
    {
      "Parameters": "size",
      "requerido": true,
      "Type": "Integer",
      "Description": "Cantidad de pacientes que se mostrarán en cada página"
    }
  ]

  jsonEjemplos =
    [{
      "title": "Body",
      "body":
      {
        "eventType": "PATIENT",
        "actionType": "SEARCH",
        "body": {
          "firstName": "Luis",
          "secondName": "Andres",
          "firstLastName": "Gutierrez",
          "secondLastName": "Gamez",
          "birthDate": "1967-12-05",
          "gender": 2,
          "documentType": 1,
          "documentNumber": "177400432",
          "phone": "3144780243",
          "cellPhone": "3144780243",
          "email": "email@empresa.com",
          "eps": 3,
          "habeasData": false,
          "pageable": {
            "page": 0,
            "size": 20
          }
        }
      },
      "subtitle": "Body para filtrar todos los pacientes que cumplan con esos parámetros."
    },
    {
      "title": "Body",
      "body":
      {
        "eventType": "PATIENT",
        "actionType": "SEARCH",
        "body": {
          "firstName": "Antonio",
          "pageable": {
            "page": 0,
            "size": 20
          }
        }
      },
      "subtitle": "Body para filtrar todos los pacientes que tengan de primer nombre Antonio."
    }
    ]

  curlEjemplo = {
    "title": "cURL",
    "bodyPrueba": "curl --location --request POST 'https://saludtools.qa.carecloud.com.co/integration/sync/event/v1/' --header 'Authorization: Bearer XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX' --header 'Content-Type: application/json' --data-raw" + '\'{ "eventType":"PATIENT", "actionType":"SEARCH", "body": { "firstName":"Luis", "secondName": "Andres", "firstLastName" : "Gutierrez", "secondLastName" : "Gamez", "birthDate" : "1967-12-05", "gender" : 2, "documentType": 1, "documentNumber" : "177400432", "phone" : "3144780243", "cellPhone" : "3144780243", "email" : "lzarate@carecloud.com.co", "eps" : 3, "habeasData": false, "pageable" :{ "page" : 0, "size" : 20 } } }\'',
    "bodyProduccion": "curl --location --request POST 'https://saludtools.carecloud.com.co/integration/sync/event/v1/' --header 'Authorization: Bearer XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX' --header 'Content-Type: application/json' --data-raw" + '\'{ "eventType":"PATIENT", "actionType":"SEARCH", "body": { "firstName":"Luis", "secondName": "Andres", "firstLastName" : "Gutierrez", "secondLastName" : "Gamez", "birthDate" : "1967-12-05", "gender" : 2, "documentType": 1, "documentNumber" : "177400432", "phone" : "3144780243", "cellPhone" : "3144780243", "email" : "lzarate@carecloud.com.co", "eps" : 3, "habeasData": false, "pageable" :{ "page" : 0, "size" : 20 } } }\'',
    "subtitle": "Después de Bearer sustituya  las X por su access-token generado y cambie los parámetros por los datos a filtrar."
  }

  jsonResultados = [
    {
      "title": "OK 200",
      "body": {
        "id": null,
        "code": 200,
        "message": "Se filtra informacion de el paciente",
        "eventId": "5ea5d7ffd67542948e5fcbb3be8c1801",
        "body": {
          "content": [
            {
              "id": 1,
              "pageable": null,
              "firstName": "Prueba",
              "secondName": "null",
              "firstLastName": "ORTIZ",
              "secondLastName": "null",
              "birthDate": "1999-05-14",
              "gender": 1,
              "documentType": 1,
              "documentNumber": "123456789",
              "phone": "123456789",
              "cellPhone": "0123456789",
              "email": null,
              "address": null,
              "eps": null,
              "habeasData": true
            },
            {
              "id": 2,
              "pageable": null,
              "firstName": "Prueba",
              "secondName": "Ejemplo",
              "firstLastName": "FERNANDEZ",
              "secondLastName": "null",
              "birthDate": "1997-12-25",
              "gender": 1,
              "documentType": 1,
              "documentNumber": "123456789",
              "phone": "6012062138",
              "cellPhone": "null",
              "email": null,
              "address": null,
              "eps": 6,
              "habeasData": false
            },
            {
              "id": 64755,
              "pageable": null,
              "firstName": "Prueba",
              "secondName": "null",
              "firstLastName": "null",
              "secondLastName": "64755",
              "birthDate": null,
              "gender": null,
              "documentType": 1,
              "documentNumber": "64755",
              "phone": "8445502052",
              "cellPhone": "7552230095",
              "email": null,
              "address": null,
              "eps": null,
              "habeasData": false
            },
            {
              "id": 215160,
              "pageable": null,
              "firstName": "Prueba",
              "secondName": "null",
              "firstLastName": "Zarate",
              "secondLastName": "",
              "birthDate": null,
              "gender": null,
              "documentType": 1,
              "documentNumber": "123456789",
              "phone": null,
              "cellPhone": "null",
              "email": "email@compañia.com",
              "address": null,
              "eps": null,
              "habeasData": false
            }
          ],
          "pageable": {
            "sort": {
              "sorted": false,
              "unsorted": true,
              "empty": true
            },
            "pageNumber": 0,
            "pageSize": 20,
            "offset": 0,
            "unpaged": false,
            "paged": true
          },
          "totalPages": 1,
          "totalElements": 4,
          "last": false,
          "sort": {
            "sorted": false,
            "unsorted": true,
            "empty": true
          },
          "numberOfElements": 20,
          "first": true,
          "size": 20,
          "number": 0,
          "empty": false
        }
      },
      "subtitle": "La solicitud se realizó con éxito, se han filtrado todas las citas que cumplen con los parámetros y además podrá observar información adicional como el total de citas que se encontraron  (totalElements) y la cantidad de paginas (totalPages).",
      "status": "200"
    },
    {
      "title": "401 Unauthorized",
      "body":
        "No tiene permisos para acceder al servidor",
      "subtitle": "Coloque un token de acceso válido.",
      "status": "401"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
        "code": 412,
        "message": "No se ha enviado un tipo de evento valido",
        "eventId": "fe645e7a430c40bb946d221fb688351d",
        "body": null
      },
      "subtitle": "Se omitió el parámetro \"eventType\", para buscar un paciente es obligatorio el parametro \"eventType\", por favor agrégalo al inicio del body como en el ejemplo.",
      "status": "212"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
        "code": 412,
        "message": "No se ha enviado un tipo de evento valido",
        "eventId": "48c76fbf91cf46a19ebd948c3d2a6d5e",
        "body": null
      },
      "subtitle": "Error en el valor del parámetro \"eventType\", para buscar un paciente el valor permitido del \"eventType\"es \"PATIENT\".",
      "status": "412"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
        "code": 412,
        "message": "No se ha enviado una accion válida",
        "eventId": "dd24b96692b744e4b97b397e5a3c1dd9",
        "body": null
      },
      "subtitle": "Se omitió el parámetro \"actionType\", para buscar un paciente es obligatorio el parámetro \"actionType\" que indica la accion de filtrar los paciente, por favor agrégalo al inicio del body como en el ejemplo.",
      "status": "412"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
        "code": 412,
        "message": "No se ha enviado una accion válida",
        "eventId": "a3d1a85dc6b649a2b4cb5ccc10d3e1c3",
        "body": null
      },
      "subtitle": "Error en el valor del parámetro \"actionType\", para buscar un paciente el valor permitido del parámetro \"actionType\" es \"SEARCH\".",
      "status": "412"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
        "code": 412,
        "message": "Request method 'PUT' not supported",
        "eventId": "81cf71d0bf1b41f391ff690352cad890",
        "body": null
      },
      "subtitle": "El método usado no es compatible con el recurso enviado, recuerde que el método que utiliza este servicio es POST.",
      "status": "412"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
        "code": 412,
        "message": "Por favor envie los datos de paginacion de la consulta",
        "eventId": "7bd7685fd780428caa7cc5fb62dfd236",
        "body": null
      },
      "subtitle": "Los datos de paginación son obligatorios. debe colocar la página que desea mostrar y la cantidad de elementos que va contener cada página.",
      "status": "412"
    }]

  constructor() { }

  ngOnInit(): void {
  }

}
