import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-examples-code',
  templateUrl: './examples-code.component.html',
  styleUrls: ['./examples-code.component.css']
})
export class ExamplesCodeComponent implements OnInit {

  @Input() json:[];

  constructor() { }





  ngOnInit(): void {
  }

}
