import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-event-nav-tabs',
  templateUrl: './event-nav-tabs.component.html',
  styleUrls: ['./event-nav-tabs.component.css']
})
export class EventNavTabsComponent implements OnInit {

  @Input() isFormData: Boolean;
  @Input() endPoint: String;

  show=true;

  environmentShow(){
    if(sessionStorage.getItem('environment') == '0' ){
      this.show = false;
    }
  }

  constructor() { }

  ngOnInit(): void {
    this.environmentShow()
  }

}
