<div id="layoutSidenav">
    <div id="layoutSidenav_content">
        <br>
        <div class="container">
            <app-main-title [title]="title" [subTitle]="subTitle"></app-main-title>

            <p>El API de Saludtools es un servicio que te permite integrar Saludtools con una aplicación
                externa.
            </p>
            <p>Actualmente nuestra API puede:
            </p>

            <ol>
                <li> Integrar pacientes con aplicaciones externas. (crear, leer, actualizar, buscar y borrar)
                </li>
                <li> Integrar citas para los pacientes, consultarlas, actualizarla y borrarlas</li>
                <li> Activar WebHooks dentro de Saludtools</li>
                <li> Integración de medicamentos (crear, leer, actualizar y borrar)</li>
            </ol>
            <hr>

            <p>Nuestra API está basada en principios REST (Representational State Transfer), esto para facilitar
                la comunicación de tu conexión y desarrollo.
                Los métodos HTTP utilizados son (GET, POST, PUT,DELETE) son utilizados para acceder a los
                recursos.
                El sistema procesa el request (solicitud) y retorna un código de respuesta en un formato de
                fácil compresión.
            </p>
            <hr>

            <app-table [title]="respuestasHTTPTitle" [tableRow]="respuestasHTTP"></app-table>
            <hr>
            <h4>Cómo utilizar la API</h4>

            <p>Actualmente la API maneja dos servicios POST. uno para autenticarse y otro que maneja los eventos.
            </p>

            <h5>1) Servicio de autenticación:</h5>
            <p>Será el encargado de hacer el proceso de autenticarse con Saludtools generando un token de acceso
                válido (access_token).</p>
            <app-authentication-nav-tabs></app-authentication-nav-tabs>

            <h5>2) Servicio de eventos:</h5>
            <p>Será el encargado de
                manejar todos los eventos relacionados a pacientes y citas (eventType). A estos
                eventos se le asigna una actividad (actionType), que puede ser ( crear, leer,
                actualizar, buscar y borrar). Esto se tiene que indicar
                en el body del servicio.
            </p>
            <app-event-nav-tabs></app-event-nav-tabs>
            <app-table [tableRow]="eventType"></app-table>
            <br>
            <app-table [tableRow]="actionType"></app-table>
            <hr>

            <h4>Collection</h4>
            <p>Una collection es un archivo en formato Json, que tiene la lista de endpoints
                de las solicitudes que puede realizar la API de Saludtools. Importe este archivo a las colecciones de
                Postman y de una manera más fácil podrá configurar y guardar
                todas sus solicitudes para usarlas cuantas veces sea necesario. 
                <a download href="/assets/IntegracionSaludtools.postman_collection.json">Descargar Collection aquí</a>
            </p>
            <hr>
            <app-footer></app-footer>
        </div>
    </div>
</div>