<div class="modal-header">
    <h4 class="modal-title">Consulta id</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="row">
        <div class="col-md-12">
            <div class="body-param">
                <form (ngSubmit)="onSubmit()" [formGroup]="authenticationForm">
                    <p class="label-gray">Es necesario indicar el tipo de documento, numero de documento y la fecha de la atención con el formato dd/MM/yyyy</p>
                    <div class="input-whith-icon">
                        <input type="text" formControlName="documentType" class="form-control" placeholder="documentType"
                            [class.is-invalid]="(authenticationForm.get('documentType').touched && authenticationForm.get('documentType').invalid) || showClass" />
                        <i class="fa fa-user"></i>
                        <div *ngIf="form.documentType.errors" class="invalid-feedback">
                            <div *ngIf="form.documentType.errors.required">El tipo de documento es obligatorio</div>
                        </div>
                    </div>
                    <br>
                    <div class="input-whith-icon">
                        <input type="text" formControlName="documentNumber" class="form-control" placeholder="documentNumber"
                            [class.is-invalid]="(authenticationForm.get('documentNumber').touched && authenticationForm.get('documentNumber').invalid) || showClass" />
                        <i class="fa fa-user"></i>
                        <div *ngIf="form.documentNumber.errors" class="invalid-feedback">
                            <div *ngIf="form.documentNumber.errors.required">El numero de documento del paciente es obligatorio</div>
                        </div>
                    </div>
                    <br>
                    <div class="input-whith-icon">
                        <input type="text" formControlName="startDate" class="form-control" placeholder="fecha inicial" maxlength="10"
                            [class.is-invalid]="(authenticationForm.get('startDate').touched && authenticationForm.get('startDate').invalid) || showClass2" />
                        <i class="fa fa-lock"></i>
                        <div *ngIf="form.startDate.errors" class="invalid-feedback">
                            <div *ngIf="form.startDate.errors.required">La fecha inicial de la busqueda es obligatoria dd/mm/aaaa</div>
                        </div>
                    </div>
                    <br>
                    <div class="input-whith-icon">
                        <input type="text" formControlName="endDate" class="form-control" placeholder="fecha final" maxlength="10"
                            [class.is-invalid]="(authenticationForm.get('endDate').touched && authenticationForm.get('endDate').invalid) || showClass3" />
                        <i class="fa fa-lock"></i>
                        <div *ngIf="form.endDate.errors" class="invalid-feedback">
                            <div *ngIf="form.endDate.errors.required">La fecha final de la busqueda es obligatorio dd/mm/aaaa</div>
                        </div>
                    </div>
                    <br>
                    <div *ngIf="submitted">
                        <h4 class="body-param-title">RESPUESTA</h4>
                        <pre class="pre-code">
                        <code [highlight]="json | json">
                        </code>
                        </pre>
                    </div>
                    <div class="col-md-12 text-right">
                        <button class="stlinkbutton" type="reset" (click)="onReset()">Limpiar</button>
                        <button class="stbutton">Enviar</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="activeModal.close('Close click')">Cancelar</button>
</div>
