<div class="row" *ngFor="let json of json">
    <div class="col-md-12">
        <div class="body-param">
            <h4 class="body-param-title"><span class="" [ngClass]="json.status==200 ? 'status200' : 'status400'" *ngIf="json.status"></span>{{json.title}}</h4>
            <pre class="pre-code">
            <code [highlight]="json.body | json">

            </code>
            </pre>
            <p class="p-code">{{json.subtitle}}</p>
        </div>
    </div>
</div>
