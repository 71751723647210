<div class="modal-header">
  <h4 class="modal-title">{{title}}</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <table class="table table table-hover">
    <thead>
      <tr>
        <th scope="col" *ngIf="json[0].id">id</th>
        <th scope="col" *ngIf="json[0].value">Value</th>
        <th scope="col">Name</th>
      </tr>
    </thead>
    <tbody *ngFor="let json of json">
      <tr>
        <td *ngIf="json.id" >{{json.id}}</td>
        <td *ngIf="json.value">{{json.value}}</td>
        <td>{{json.name}}</td>
      </tr>
    </tbody>
  </table>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary" (click)="activeModal.close('Close click')">Close</button>
</div>