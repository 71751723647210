import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-webhook',
  templateUrl: './webhook.component.html',
  styleUrls: ['./webhook.component.css']
})
export class WebhookComponent implements OnInit {

  title: string ="WebHooks";
  subTitle: string ="Es un sistema de comunicación automático entre aplicaciones. Podrá obtener respuestas de los eventos de entrada y salidas de Saludtools."

  paragraphTitle: string ="¿Cómo activar WebHooks en Saludtools?";
  paragraph: string ="Activar WebHooks en saludtools es muy fácil. Solo debe seguir los siguientes pasos:";

  imgCard =   [
    {
      "imgCardTitle": "Paso 1: Ingrese a Saludtools y coloque su usuario y contraseña para loguearse normalmente en Saludtools",
      "imgCardhref": "assets/img/ApiKey/Paso1.png",
    },
    {
      "imgCardTitle": "Paso 2: Dentro de Saludtools hacer clic sobre su nombre de perfil para desplegar el menú",
      "imgCardhref": "assets/img/ApiKey/Paso2.png",

    },
    {
      "imgCardTitle": "Paso 3: En el menú desplegado hacer clic en configuración",
      "imgCardhref": "assets/img/ApiKey/Paso3.png",
    },
    {
      "imgCardTitle": "Paso 4: Ir al panel izquierdo y hacer clic en integraciones.",
      "imgCardhref": "assets/img/ApiKey/Paso4.png",
    },
    {
      "imgCardTitle": "Paso 5: En el menú desplegado hacer clic en WebHooks.",
      "imgCardhref": "assets/img/WebHooks/Paso5.png",
    },
    {
      "imgCardTitle": "Paso 6: En el menú superior elegir entre las dos opciones (Paciente o Agendamiento) si desea eventos de paciente haga clic en Paciente, si desea activar eventos de agendamiento, haga clic en Agendamiento.",
      "imgCardhref": "assets/img/WebHooks/Paso6.png",
    },
    {
      "imgCardTitle": "Paso 7: Busque la actividad que desea capturar. Para las opciones de paciente y agendamiento podrá activar las notificaciones de crear, actualizar y eliminar, una vez seleccione la actividad  haga clic en el botón para encender la notificación.",
      "imgCardhref": "assets/img/WebHooks/Paso7.png",
      "imgCardSub": "La imagen muestra un ejemplo para activar la Notificación actualizar paciente."
    },
    {
      "imgCardTitle": "Paso 8: Seleccione el método REST que llegará a su servidor (POST, PUT, DELETE).",
      "imgCardhref": "assets/img/WebHooks/Paso8.png",
    },
    {
      "imgCardTitle": "Paso 9: En el input URL escriba el endPoint de su servidor donde llegará el evento.",
      "imgCardhref": "assets/img/WebHooks/Paso9.png",
    },
    {
      "imgCardTitle": "Paso 10 (Opcional): Para agregar parámetros al evento, seleccione primero URL Params y haga clic en agregar parametro (esta acción desplegará los input del nuevo parametro).",
      "imgCardhref": "assets/img/WebHooks/Paso10.png",
    },
    {
      "imgCardTitle": "Paso 10.1: Escriba la información de los nuevos campos, donde Key es el nombre clave del nuevo parámetro y Value refire a su valor.",
      "imgCardhref": "assets/img/WebHooks/Paso10_1.png",
    },
    {
      "imgCardTitle": "Paso 10.2: Para agregar más de un parámetro al evento, solo debe hacer clic en agregar parámetro y se desplegarán los campos del nuevo parámetro a crear.",
      "imgCardhref": "assets/img/WebHooks/Paso10_2.png",
    },
    {
      "imgCardTitle": "Paso 10.3: Puede eliminar parámetros creados, solo haga clic en el botón eliminar.",
      "imgCardhref": "assets/img/WebHooks/Paso10_3.png",
    },
    {
      "imgCardTitle": "Paso 11 (opcional): Podrá agregar un Header al evento. Seleccione primero HTTP Header y haga clic en Agregar Header (esta acción desplegará los input del nuevo Header).",
      "imgCardhref": "assets/img/WebHooks/Paso11.png",
    },
    {
      "imgCardTitle": "Paso 11.1: Escriba la información de los campos del nuevo Header, donde Key es el nombre clave del nuevo Header y Value  es su valor.",
      "imgCardhref": "assets/img/WebHooks/Paso11_1.png",
    },
    {
      "imgCardTitle": "Paso 11.2: Podrá agregar más de un Header al evento. Para esto solo debe hacer clic en agregar Header y se desplgarán los campos del nuevo Header a crear.",
      "imgCardhref": "assets/img/WebHooks/Paso11_2.png",
    },
    {
      "imgCardTitle": "Paso 11.3: Puede eliminar Header creados, solo haga clic en el botón eliminar.",
      "imgCardhref": "assets/img/WebHooks/Paso11_3.png",
    },
    {
      "imgCardTitle": "Paso 12: Luego de  realizar la activación y configurción de su evento, debe dirigirse a la parte superior del inicio de la hoja y hacer clic en Guardar Cambios.",
      "imgCardhref": "assets/img/WebHooks/Paso12.png",
    }
    ]

  constructor() { }

  ngOnInit(): void {
  }

}
