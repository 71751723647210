<h4>Parámetros</h4>
<div class="row">
    <div class="col-md-12">
       <div class="body-param">
            <h4 class="body-param-title"> Body {{isFormData && "/ form-data"}} Parámetros</h4>
            <div class="body-param-row" *ngFor="let parameters of parameters;">
                <div class="body-param-col-name">
                    <strong class="body-param-name" >{{parameters.Parameters}}</strong>
                    <div class="body-param-require" *ngIf="parameters.requerido">requerido</div>
                </div>
                <div class="body-param-col-type">
                    <strong class="body-param-name">{{parameters.Type}}</strong>
                    <p class="body-param-description">{{parameters.Description}} <a *ngIf="parameters.link && parameters.textlink" href="{{parameters.link}}">{{parameters.textlink}}</a></p>
                </div>
            </div>
       </div>
    </div>
</div>
