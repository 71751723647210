import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-patient-familiar-antecedent-search',
  templateUrl: './patient-familiar-antecedent-search.component.html',
  styleUrls: ['./patient-familiar-antecedent-search.component.css']
})
export class PatientFamiliarAntecedentSearchComponent implements OnInit {

  title: string = "Buscar Antecedentes Familiares";
  subTitle: string = "Puede filtrar una lista de antecedentes Familiares dado una cantidad de parámetros."

  paragraphTitle: string = "¿Cómo filtrar los Antecedentes Familiares?";
  paragraph: string = "Para buscar una lista de antecedentes Familiares, primero debe tener un token de acceso activo. Si su token se venció, o aún no ha generado un token de acceso, genere uno nuevo. Si ya está autenticado, debe colocar en el body los parámetros que quiera filtrar. Puede elegir un parámetro o hacer una combinación de los parámetros que desee agregar para filtrar los antecedentes Familiares.";

  parameters = [
    {
      "Parameters": "eventType",
      "requerido": true,
      "Type": "String",
      "Description": "Es el tipo de evento que se quiere invocar en este caso debe ser \"FAMILY_HISTORY\"."
    },
    {
      "Parameters": "actionType",
      "requerido": true,
      "Type": "String",
      "Description": "Es la acción que se quiere realizar para buscar los antecedentes Familiares de un paciente debe ser \"SEARCH\""
    },
    {
      "Parameters": "documentType",
      "requerido": true,
      "Type": "Long",
      "Description": "Id del tipo de documento de identidad del paciente"
    },
    {
      "Parameters": "documentNumber",
      "requerido": true,
      "Type": "String",
      "Description": "Número del documento de identidad del paciente"
    },
    {
      "Parameters": "encounterCommonInfo",
      "requerido": false,
      "Type": "Long",
      "Description": "ID de la historia clínica"
    },
  ]

  jsonEjemplos =
    [{
      "title": "Body",
      "body":
      {
        "eventType": "FAMILY_HISTORY",
        "actionType": "SEARCH",
        "body": {
          "documentType": 1,
          "documentNumber": "123456789",
          "encounterCommonInfo":"123456789",
          "pageable": {
            "page": 5,
            "size": 5
          }
        }
      },
      "subtitle": "Body para filtrar todos los antecedentes Familiares que cumplan con esos parámetros. Si envía el parámetro encounterCommonInfo, se buscara únicamente los antecedentes familiares relacionados con el encuentro. "
    }
    ]

    curlEjemplo = {
      "title": "cURL",
      "bodyPrueba": "curl --location --request POST 'https://saludtools.qa.carecloud.com.co/integration/sync/event/v1/' --header 'Authorization: Bearer XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX' --header 'Content-Type: application/json' --data-raw" + '\'{ "eventType":"FAMILY_HISTORY", "actionType":"SEARCH", "body": '+ JSON.stringify(this.jsonEjemplos[0].body.body)+'}\'',
      "bodyProduccion": "curl --location --request POST 'https://saludtools.carecloud.com.co/integration/sync/event/v1/' --header 'Authorization: Bearer XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX' --header 'Content-Type: application/json' --data-raw" + '\'{ "eventType":"FAMILY_HISTORY", "actionType":"SEARCH", "body": '+ JSON.stringify(this.jsonEjemplos[0].body.body)+'}\'',
      "subtitle": "Después de Bearer sustituya  las X por su access-token generado y cambie los parámetros por los datos de su antecedente familiar a buscar."
    }

  jsonResultados = [
    {
      "title": "OK 200",
      "body": {
        "id": null,
        "code": 200,
        "message": "Se filtra informacion de ",
        "eventId": "9c2e389959c54f4f91b0d3b637f4d09f",
        "body": {
          "content": [
            {
                "id": 9737,
                "documentType": 1,
                "documentNumber": "65135",
                "encounterCommonInfo": 306837,
                "diagnosticText": "diagnostico encuentro",
                "diagnosisDate": "2022-11-04",
                "comments": "comentarios",
                "familiarRelationshipType": 13
            },
            {
                "id": 9736,
                "documentType": 1,
                "documentNumber": "65135",
                "encounterCommonInfo": 306837,
                "diagnosticType": "B002",
                "diagnosisDate": "2022-11-04",
                "comments": "comentario dos",
                "familiarRelationshipType": 1
            }
          ],
        "pageable": {
          "sort": {
              "unsorted": true,
              "sorted": false,
              "empty": true
          },
          "offset": 0,
          "pageSize": 20,
          "pageNumber": 0,
          "unpaged": false,
          "paged": true
      },
      "last": true,
      "totalPages": 1,
      "totalElements": 2,
      "size": 20,
      "number": 0,
      "sort": {
          "unsorted": true,
          "sorted": false,
          "empty": true
      },
      "numberOfElements": 2,
      "first": true,
      "empty": false
        }
      },
      "subtitle": "La solicitud se realizó con éxito, se han filtrado todas las citas que cumplen con los parámetros y además podrá observar información adicional como el total de citas que se encontraron  (totalElements) y la cantidad de páginas (totalPages).",
      "status": "200"
    },
    {
      "title": "401 Unauthorized",
      "body":
        "No tiene permisos para acceder al servidor",
      "subtitle": "Coloque un token de acceso válido.",
      "status": "401"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
        "code": 412,
        "message": "No se ha enviado un tipo de evento valido",
        "eventId": "fe645e7a430c40bb946d221fb688351d",
        "body": null
      },
      "subtitle": "Se omitió el parámetro \"eventType\", para buscar los antecedentes Familiares de un paciente es obligatorio el parámetro \"eventType\", por favor agrégalo al inicio del body como en el ejemplo.",
      "status": "212"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
        "code": 412,
        "message": "No se ha enviado un tipo de evento valido",
        "eventId": "48c76fbf91cf46a19ebd948c3d2a6d5e",
        "body": null
      },
      "subtitle": "Error en el valor del parámetro \"eventType\", para buscar los antecedentes Familiares de un paciente el valor permitido del \"eventType\"es \"FAMILY_HISTORY\".",
      "status": "412"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
        "code": 412,
        "message": "No se ha enviado una accion válida",
        "eventId": "dd24b96692b744e4b97b397e5a3c1dd9",
        "body": null
      },
      "subtitle": "Se omitió el parámetro \"actionType\", para buscar los antecedentes Familiares de un paciente es obligatorio el parámetro \"actionType\" que indica la acción de filtrar los antecedentes familiares, por favor agrégalo al inicio del body como en el ejemplo.",
      "status": "412"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
        "code": 412,
        "message": "No se ha enviado una accion válida",
        "eventId": "a3d1a85dc6b649a2b4cb5ccc10d3e1c3",
        "body": null
      },
      "subtitle": "Error en el valor del parámetro \"actionType\", para buscar los antecedentes Familiares de un paciente el valor permitido del parámetro \"actionType\" es \"SEARCH\".",
      "status": "412"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
        "code": 412,
        "message": "Request method 'PUT' not supported",
        "eventId": "81cf71d0bf1b41f391ff690352cad890",
        "body": null
      },
      "subtitle": "El método usado no es compatible con el recurso enviado, recuerde que el método que utiliza este servicio es POST.",
      "status": "412"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
        "code": 412,
        "message": "Por favor envié los datos de paginación de la consulta",
        "eventId": "7bd7685fd780428caa7cc5fb62dfd236",
        "body": null
      },
      "subtitle": "Los datos de paginación son obligatorios. debe colocar la página que desea mostrar y la cantidad de elementos que va a contener cada página.",
      "status": "412"
    }]

  constructor() { }

  ngOnInit(): void {
  }

}
