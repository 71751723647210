<div id="layoutSidenav">
    <div id="layoutSidenav_content">
        <br>
        <div class="container">
            <app-main-title [title]="title" [subTitle]="subTitle"></app-main-title>
            <app-main-paragraph [paragraphTitle]="paragraphTitle" [paragraph]="paragraph"></app-main-paragraph>
         
            <h4>Los Antecedentes Ginecoobst&eacute;tricos del paciente contienen los siguientes atributos</h4>
            <table class="table table-bordered">
                <thead class="thead-dark">
                    <tr style="padding: 0px !important;">
                        <th scope="col">Nombre</th>
                        <th scope="col">Tipo</th>
                        <th scope="col">Descripci&oacute;n</th>
                        <th scope="col">Ejemplo</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th scope="row">documentType</th>
                        <td>Long</td>
                        <td>
                            Tipo de documento del Paciente:  
                                <ul>
                                    <li>1 = Cédula ciudadanía</li>
                                    <li>2 = Cédula de extranjería</li>
                                    <li>3 = Carné diplomático</li>
                                    <app-parameters-modal-button [type]="'documents'"></app-parameters-modal-button>
                                </ul>
                        </td>
                        <td>2</td> 
                    </tr>
                    <tr>
                        <th scope="row">documentNumber</th>
                        <td>String</td>
                        <td>Número de documento del paciente</td>
                        <td>"123456789"</td>     
                    </tr>
                    <tr>
                        <th scope="row">fup</th>
                        <td>LocalDate</td>
                        <td>Fecha del &uacute;ltimo parto ("yyyy-mm-dd")</td>
                        <td>"2022-10-10"</td>
                    </tr> 
                    <tr>
                        <th scope="row">weeksOfPregnancy</th>
                        <td>Integer</td>
                        <td>Semanas de embarazo</td>
                        <td>20</td>
                    </tr> 
                    <tr>
                        <th scope="row">otherObservations</th>
                        <td>String</td>
                        <td>Detalles del parto (máximo 2000 caracteres)</td>
                        <td>"Detalles del parto"</td>
                    </tr> 
                    <tr>
                        <th scope="row">fur</th>
                        <td>LocalDate</td>
                        <td>&Uacute;ltima menstruaci&oacute;n ("yyyy-mm-dd")</td>
                        <td>"2022-10-10"</td>
                    </tr> 
                    <tr>
                        <th scope="row">trustworthy</th>
                        <td>Boolean</td>
                        <td>¿Es la fecha confiable?</td>
                        <td>true</td>
                    </tr> 
                    <tr>
                        <th scope="row">contraceptiveType</th>
                        <td>Long</td>
                        <td>
                            Tipo de m&eacute;todo de planificaci&oacute;n:
                            <ul>
                                <li>26 = Barrera</li>
                                <li>27 = Anticonceptivos orales</li>
                                <li>28 = Anticonceptivos inyectables</li>
                                <li>29 = DIU</li>
                                <li>30 = Naturales</li>
                                <li>31 = Quirúrgicos</li>
                                <li>32 = Implantes Subdérmicos</li>
                                <li>33 = No Aplica</li>
                                <li>34 = No Planifica</li>
                                <app-parameters-modal-button [type]="'contraceptivetype'"></app-parameters-modal-button> 
                            </ul>
                        </td>
                        <td>
                            26
                        </td>
                    </tr> 
                    <tr>
                        <th scope="row">detailContraceptiveMethod</th>
                        <td>String</td>
                        <td>Detalle del m&eacute;todo de planificaci&oacute;n, (máximo 2.000 caracteres)</td>
                        <td>"Implante puesto el 20/11/2021"</td>
                    </tr> 
                    <tr>
                        <th scope="row">aliveBirths</th>
                        <td>Short</td>
                        <td>Nacimientos vivos</td>
                        <td>2</td>
                    </tr> 
                    <tr>
                        <th scope="row">pregnancies</th>
                        <td>Short</td>
                        <td>Número de embarazos</td>
                        <td>2</td>
                    </tr> 
                    <tr>
                        <th scope="row">births</th>
                        <td>Short</td>
                        <td>Partos</td>
                        <td>2</td>
                    </tr> 
                    <tr>
                        <th scope="row">cesareans</th>
                        <td>Short</td>
                        <td>Ces&aacute;reas</td>
                        <td>1</td>
                    </tr> 
                    <tr>
                        <th scope="row">ceases</th>
                        <td>Short</td>
                        <td>Abortos</td>
                        <td>0</td>
                    </tr> 
                    <tr>
                        <th scope="row">molas</th>
                        <td>Short</td>
                        <td>Molas</td>
                        <td>2</td>
                    </tr> 
                    <tr>
                        <th scope="row">ectopics</th>
                        <td>Short</td>
                        <td>Ect&oacute;picos</td>
                        <td>2</td>
                    </tr> 
                    <tr>
                        <th scope="row">menarche</th>
                        <td>Long</td>
                        <td>Menarquia (edad), Aparici&oacute;n de la primera menstruaci&oacute;n</td>
                        <td>13</td>
                    </tr> 
                    <tr>
                        <th scope="row">pubarchy</th>
                        <td>Short</td>
                        <td>Pubarquia (edad), Primera aparici&oacute;n de vello p&uacute;bico</td>
                        <td>13</td>
                    </tr> 
                    <tr>
                        <th scope="row">telarchy</th>
                        <td>Short</td>
                        <td>Telarquia (edad), Inicio del desarrollo de la mama</td>
                        <td>10</td>
                    </tr> 
                    <tr>
                        <th scope="row">irs</th>
                        <td>Short</td>
                        <td>Sexarquia (edad), Inicio de las relaciones sexuales</td>
                        <td>20</td>
                    </tr> 
                    <tr>
                        <th scope="row">sexualPartners</th>
                        <td>Short</td>
                        <td>Compañeros sexuales</td>
                        <td>4</td>
                    </tr> 
                    <tr>
                        <th scope="row">menstrualRegularity</th>
                        <td>String</td>
                        <td>Regularidad del ciclo menstrual:
                            <ul>
                                <li>REGULAR</li>
                                <li>IRREGULAR</li>
                            </ul>
                        </td>
                        <td>
                            "REGULAR"
                        </td>
                    </tr> 
                    <tr>
                        <th scope="row">menstrualCycles</th>
                        <td>String</td>
                        <td>Ciclos menstruales, duraci&oacute;n y periodicidad, (máximo 5000 caracteres)</td>
                        <td>"Ciclo de 30 d&iacute;as, cada mes y medio"</td>
                    </tr>  
                    <tr>
                        <th scope="row">lastCytologyDate</th>
                        <td>LocalDate</td>
                        <td>&Uacute;ltima citolog&iacute;a ("yyyy-mm-dd")</td>
                        <td>"2021-05-22"</td>
                    </tr> 
                    <tr>
                        <th scope="row">previousGestationalObservations</th>
                        <td>String</td>
                        <td>Observaciones de las gestaciones anteriores,(máximo 2000 caracteres) </td>
                        <td>"Observaciones de las gestaciones anteriores"</td>
                    </tr> 
                    <tr>
                        <th scope="row">currentlyPregnant</th>
                        <td>Boolean</td>
                        <td>¿Actualmente embarazada?, Si el valor del campo es true, se guardan los siguientes campos:
                            ultrasoundDate, gestationWeek, gestationDay, observations.
                        </td>
                        <td>true</td>
                    </tr> 
                    <tr>
                        <th scope="row">ultrasoundDate</th>
                        <td>LocalDate</td>
                        <td>&Uacute;ltima ecograf&iacute;a ("yyyy-mm-dd")</td>
                        <td>""2022-11-01</td>
                    </tr> 
                    <tr>
                        <th scope="row">gestationWeek</th>
                        <td>Short</td>
                        <td>Semanas de gestaci&oacute;n</td>
                        <td>23</td>
                    </tr> 
                    <tr>
                        <th scope="row">gestationDay</th>
                        <td>Short</td>
                        <td>D&iacute;as de gestaci&oacute;n</td>
                        <td>5</td>
                    </tr> 
                    <tr>
                        <th scope="row">observations</th>
                        <td>String</td>
                        <td>Observaciones de la gestaci&oacute;n actual (máximo 2000 caracteres)</td>
                        <td>"Embarazo con normalidad"</td>
                    </tr> 
                </tbody>
            </table>
            <hr>
            <app-footer></app-footer>
        </div>
    </div>
</div>
