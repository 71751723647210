import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-clinical-laboratories-create',
  templateUrl: './clinical-laboratories-create.component.html',
  styleUrls: ['./clinical-laboratories-create.component.css']
})
export class ClinicalLaboratoriesCreateComponent implements OnInit {

  title: string = "Crear un resultado de laboratorio clínico";
  subTitle: string = "Cree una nuevo resultado"

  paragraphTitle: string = "¿Cómo crear un resultado de laboratorio clínico?";
  paragraph: string = "Para crear un resultado de laboratorio clínico , primero debe tener un Token de acceso activo. Si su Token se venció, o aún no ha generado un Token de acceso. Genere un nuevo Token y consuma el evento de crear laboratorio clínico.";

  parameters = [
    {
      "Parameters": "eventType",
      "requerido": true,
      "Type": "String",
      "Description": "Es el tipo de evento que se quiere invocar en este caso debe ser \"PARACLINICS\"."
    },
    {
      "Parameters": "actionType",
      "requerido": true,
      "Type": "String",
      "Description": "Es la acción que se quiere realizar para crear un resultado de laboratorio clínico debe ser \"CREATE\""
    },
    {
      "Parameters": "encounterId",
      "requerido": true,
      "Type": "Long",
      "Description": "Id de la atención si el resultado de laboratorio clínico esta vinculada a una atención médica"

    },
    {
      "Parameters": "documentType",
      "requerido": true,
      "Type": "Long",
      "Description": "Id del tipo de documento de identidad del paciente"
    },
    {
      "Parameters": "documentNumber",
      "requerido": true,
      "Type": "String",
      "Description": "Número del documento de identidad del paciente"
    },
    {
      "Parameters": "patientParaclinic",
      "requerido": true,
      "Type": "List<ParaclinicBasicResponseDTO>	",
      "Description": "Listado de resultado con paraclinicos a registrar en el laboratorio clínico"
    }, {
      "Parameters": "[{",
      "requerido": false,
      "Type": "",
      "Description": ""
    },
    {
      "Parameters": "value",
      "requerido": true,
      "Type": "String",
      "Description": "Valor del resultado del laboratorio clínicio"
    },
    {
      "Parameters": "classification",
      "requerido": true,
      "Type": "Long",
      "Description": "Este dato se recibirá como el ID de la clasificación"
    }, {
      "Parameters": "typeId",
      "requerido": true,
      "Type": "Long",
      "Description": "Este dato se recibirá como el tipo del paraclínico"
    }, {
      "Parameters": "unitId",
      "requerido": true,
      "Type": "Long",
      "Description": "Este dato se recibirá como el ID de la unidad de medición"
    }, {
      "Parameters": "comments",
      "requerido": false,
      "Type": "String",
      "Description": "En este campo se debe registrar el texto que se registre de forma adicional"
    }, {
      "Parameters": "examDate",
      "requerido": false,
      "Type": "String",
      "Description": "Este registro debe hacerse con el formato yyyy-MM-dd HH:mm"
    },
    {
      "Parameters": "}]",
      "requerido": false,
      "Type": "",
      "Description": ""
    },

  ]

  jsonEjemplos =
    [{
      "title": "Body",
      "body":
      {
        "eventType": "PARACLINICS",
        "actionType": "CREATE",
        "body": {
          "encounterID": 123,
          "patientDocumentType": 1,
          "patientDocumentNumber": "77837",
          "patientParaclinic": [
            {
              "value": "4",
              "classification": 1,
              "typeId": 1,
              "unitId": 2,
              "comments": null,
              "examDate": "2022-09-05 00:00"
            },
            {
              "value": "8",
              "classification": 2,
              "typeId": 2,
              "unitId": 63,
              "comments": null,
              "examDate": "2022-09-05 00:00"
            }
          ]
        }
      },
      "subtitle": "Body para crear un resultado de laboratorio clínico, sustituya los parámetros por los de los resultados a crear."
    }
    ]

  curlEjemplo = {
    "title": "cURL",
    "bodyPrueba": "curl --location --request POST 'https://saludtools.qa.carecloud.com.co/integration/sync/event/v1/' --header 'Authorization: Bearer XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX' --header 'Content-Type: application/json' --data-raw" + '{"eventType":"PARACLINICS","actionType":"CREATE", "body": {"encounterID": 123,"patientDocumentType":1,"patientDocumentNumber":"77837","patientParaclinic": [{"value": "4","classification": 1,"typeId": 1,"unitId": 2,"comments": null,"examDate": "2022-09-05 00:00"}]}}',
    "bodyProduccion": "curl --location --request POST 'https://saludtools.carecloud.com.co/integration/sync/event/v1/' --header 'Authorization: Bearer XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX' --header 'Content-Type: application/json' --data-raw" + '{"eventType":"PARACLINICS","actionType":"CREATE", "body": {"encounterID": 123,"patientDocumentType":1,"patientDocumentNumber":"77837","patientParaclinic": [{"value": "4","classification": 1,"typeId": 1,"unitId": 2,"comments": null,"examDate": "2022-09-05 00:00"}]}}',
    "subtitle": "Después de Bearer sustituya  las X por su access-token generado y cambie los parámetros por los datos de el resultado a crear."
  }

  jsonResultados = [
    {
      "title": "OK 200",
      "body": {
        "id": 349705,
        "code": 200,
        "message": "Se registra la antencion id: 349705",
        "eventId": "128b6f55ff094c9586cd4f3f2ca03cda",
        "body": null
      },
      "subtitle": "La solicitud se realizó con éxito y la atencion ha sido creado.",
      "status": "200"
    },
    {
      "title": "401 Unauthorized",
      "body":
        "No tiene permisos para acceder al servidor",
      "subtitle": "Coloque un token de acceso válido.",
      "status": "401"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
        "code": 412,
        "message": "No se ha enviado un tipo de evento valido",
        "eventId": "fe645e7a430c40bb946d221fb688351d",
        "body": null
      },
      "subtitle": "Se omitió el parámetro \"eventType\", Para crear un resultado clínico es obligatorio el parametro \"eventType\", por favor agrégalo al inicio del body como en el ejemplo.",
      "status": "412"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
        "code": 412,
        "message": "No se ha enviado un tipo de evento valido",
        "eventId": "48c76fbf91cf46a19ebd948c3d2a6d5e",
        "body": null
      },
      "subtitle": "Error en el valor del parámetro \"eventType\". Para crear un resultado clínico el valor permitido del \"eventType\"es \"CLINIC_HISTORY\".",
      "status": "412"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
        "code": 412,
        "message": "No se ha enviado una accion válida",
        "eventId": "dd24b96692b744e4b97b397e5a3c1dd9",
        "body": null
      },
      "subtitle": "Se omitió el parámetro \"actionType\", Para crear un resultado clínico es obligatorio el parámetro \"actionType\" que indica la acción de crear, por favor agrégalo al inicio del body como en el ejemplo.",
      "status": "412"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
        "code": 412,
        "message": "No se ha enviado una accion válida",
        "eventId": "a3d1a85dc6b649a2b4cb5ccc10d3e1c3",
        "body": null
      },
      "subtitle": "Error en el valor del parámetro \"actionType\". Para crear un resultado clínico el valor permitido del parámetro \"actionType\" es \"CREATE\".",
      "status": "412"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
        "code": 412,
        "message": "Request method 'PUT' not supported",
        "eventId": "81cf71d0bf1b41f391ff690352cad890",
        "body": null
      },
      "subtitle": "El método usado no es compatible con el recurso enviado, recuerde que el método que utiliza este servicio es POST.",
      "status": "412"
    }]

  constructor() { }

  ngOnInit(): void {
  }

}
