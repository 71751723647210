import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ApiKeyComponent } from './pages/api-key/api-key.component';
import { ApikeyCreateComponent } from './pages/apikey-create/apikey-create.component';
import { ApikeyDeleteComponent } from './pages/apikey-delete/apikey-delete.component';
import { AppointmentCreateComponent } from './pages/appointment-create/appointment-create.component';
import { AppointmentDeleteComponent } from './pages/appointment-delete/appointment-delete.component';
import { AppointmentReadComponent } from './pages/appointment-read/appointment-read.component';
import { AppointmentSearchComponent } from './pages/appointment-search/appointment-search.component';
import { AppointmentUpdateComponent } from './pages/appointment-update/appointment-update.component';
import { AppointmentComponent } from './pages/appointment/appointment.component';
import { ClinicHistoryCreateComponent } from './pages/clinic-history-create/clinic-history-create.component';
import { ClinicHistoryReadComponent } from './pages/clinic-history-read/clinic-history-read.component';
import { ClinicHistoryComponent } from './pages/clinic-history/clinic-history.component';
import { ClinicalLaboratoriesCreateComponent } from './pages/clinical-laboratories-create/clinical-laboratories-create.component';
import { ClinicalLaboratoriesReadComponent } from './pages/clinical-laboratories-read/clinical-laboratories-read.component';
import { ClinicalLaboratoriesComponent } from './pages/clinical-laboratories/clinical-laboratories.component';
import { ExamsPrescriptionCreateComponent } from './pages/exams-prescription-create/exams-prescription-create.component';
import { ExamsPrescriptionReadComponent } from './pages/exams-prescription-read/exams-prescription-read.component';
import { ExamsPrescriptionComponent } from './pages/exams-prescription/exams-prescription.component';
import { HomeComponent } from './pages/home/home.component';
import { MedicineCreateComponent } from './pages/medicine-create/medicine-create.component';
import { MedicineDeleteComponent } from './pages/medicine-delete/medicine-delete.component';
import { MedicineReadComponent } from './pages/medicine-read/medicine-read.component';
import { MedicineUpdateComponent } from './pages/medicine-update/medicine-update.component';
import { MedicineComponent } from './pages/medicine/medicine.component';
import { ParameterComponent } from './pages/parameter/parameter.component';
import { PatientCreateComponent } from './pages/patient-create/patient-create.component';
import { PatientDeleteComponent } from './pages/patient-delete/patient-delete.component';
import { PatientReadComponent } from './pages/patient-read/patient-read.component';
import { PatientSearchComponent } from './pages/patient-search/patient-search.component';
import { PatientUpdateComponent } from './pages/patient-update/patient-update.component';
import { PatientComponent } from './pages/patient/patient.component';
import { TokenComponent } from './pages/token/token.component';
import { WebhookComponent } from './pages/webhook/webhook.component';
import { PatientFileCreateComponent } from './pages/patient-file-create/patient-file-create.component';
import { PatientFileComponent } from './pages/patient-file/patient-file.component';
import { PatientFileSearchComponent } from './pages/patient-file-search/patient-file-search.component';
import { PatientFileReadComponent } from './pages/patient-file-read/patient-file-read.component';
import { PatientInabilityWorkComponent } from './pages/patient-inability-work/patient-inability-work.component';
import { PatientInabilityWorkCreateComponent } from './pages/patient-inability-work-create/patient-inability-work-create.component';
import { PatientInabilityWorkReadComponent } from './pages/patient-inability-work-read/patient-inability-work-read.component';
import { PatientInabilityWorkSearchComponent } from './pages/patient-inability-work-search/patient-inability-work-search.component';
import { ExamsResultsComponent } from './pages/exams-results/exams-results.component';
import { ExamsResultsSearchComponent } from './pages/exams-results-search/exams-results-search.component';
import { ExamsResultsReadComponent } from './pages/exams-results-read/exams-results-read.component';
import { ExamsResultsCreateComponent } from './pages/exams-results-create/exams-results-create.component';
import { PatientGinecoInformationComponent } from './pages/patient-gineco-information/patient-gineco-information.component';
import { PatientGinecoInformationCreateComponent } from './pages/patient-gineco-information-create/patient-gineco-information-create.component';
import { PatientGinecoInformationReadComponent } from './pages/patient-gineco-information-read/patient-gineco-information-read.component';
import { PatientFamiliarAntecedentComponent } from './pages/patient-familiar-antecedent/patient-familiar-antecedent.component';
import { PatientFamiliarAntecedentCreateComponent } from './pages/patient-familiar-antecedent-create/patient-familiar-antecedent-create.component';
import { PatientFamiliarAntecedentReadComponent } from './pages/patient-familiar-antecedent-read/patient-familiar-antecedent-read.component';
import { PatientFamiliarAntecedentSearchComponent } from './pages/patient-familiar-antecedent-search/patient-familiar-antecedent-search.component';
import { MedicineReadLastComponent } from './pages/medicine-read-last/medicine-read-last.component';
import { AntecedentPersonalComponent } from './pages/antecedent-personal/antecedent-personal.component';
import { AntecedentPersonalCreateComponent } from './pages/antecedent-personal-create/antecedent-personal-create.component';
import { AntecedentPersonalReadComponent } from './pages/antecedent-personal-read/antecedent-personal-read.component';

const routes: Routes = [
  {path: 'home' , component: HomeComponent},
  {path: 'apikey' , component: ApiKeyComponent},
    {path: 'apikeycreate' , component: ApikeyCreateComponent},
    {path: 'apikeydelete' , component: ApikeyDeleteComponent},
    {path: 'token' , component: TokenComponent},
  {path: 'patient' , component: PatientComponent},
    {path: 'patientcreate' , component: PatientCreateComponent},
    {path: 'patientread' , component: PatientReadComponent},
    {path: 'patientupdate' , component: PatientUpdateComponent},
    {path: 'patientsearch' , component: PatientSearchComponent},
    {path: 'patientdelete' , component: PatientDeleteComponent},
  {path: 'appointment' , component: AppointmentComponent},
    {path: 'appointmentcreate' , component: AppointmentCreateComponent},
    {path: 'appointmentread' , component: AppointmentReadComponent},
    {path: 'appointmentupdate' , component: AppointmentUpdateComponent},
    {path: 'appointmentsearch' , component: AppointmentSearchComponent},
    {path: 'appointmentdelete' , component: AppointmentDeleteComponent},
  {path: 'medicine' , component: MedicineComponent},
    {path: 'medicinecreate' , component: MedicineCreateComponent},
    {path: 'medicineread' , component: MedicineReadComponent},
    {path: 'medicinereadlast' , component: MedicineReadLastComponent},
    {path: 'medicineupdate' , component: MedicineUpdateComponent},
    {path: 'medicinedelete' , component: MedicineDeleteComponent},
  {path: 'clinicHistory' , component: ClinicHistoryComponent},
    {path: 'clinicHistoryCreate', component: ClinicHistoryCreateComponent},
    {path: 'clinicHistoryRead', component: ClinicHistoryReadComponent},
  {path: 'clinicalLaboratories' , component: ClinicalLaboratoriesComponent},
    {path: 'clinicalLaboratoriesCreate', component:ClinicalLaboratoriesCreateComponent},
    {path: 'clinicalLaboratoriesRead', component: ClinicalLaboratoriesReadComponent},
  {path: 'examsprescription', component: ExamsPrescriptionComponent},
    {path: 'examsprescriptioncreate', component: ExamsPrescriptionCreateComponent},
    {path: 'examsprescriptionread', component: ExamsPrescriptionReadComponent},
  {path: 'documents', component: PatientFileComponent},
    {path: 'patientfileupload', component: PatientFileCreateComponent},
    {path: 'patientfilesearch', component: PatientFileSearchComponent},
    {path: 'patientfileread', component: PatientFileReadComponent},
  {path: 'inabilitywork', component: PatientInabilityWorkComponent},
    {path: 'inabilityworkcreate', component: PatientInabilityWorkCreateComponent},
    {path: 'inabilityworksearch', component: PatientInabilityWorkSearchComponent},
    {path: 'inabilityworkread', component: PatientInabilityWorkReadComponent},
  {path: 'examsResults', component: ExamsResultsComponent},
    {path: 'examsResultsCreate', component: ExamsResultsCreateComponent},
    {path: 'examsResultsSearch', component: ExamsResultsSearchComponent},
    {path: 'examsResultsRead', component: ExamsResultsReadComponent},
  {path: 'patientGinecoInformation', component: PatientGinecoInformationComponent},
    {path: 'patientGinecoInformationCreate', component: PatientGinecoInformationCreateComponent},
    {path: 'patientGinecoInformationRead', component: PatientGinecoInformationReadComponent},
  {path: 'antecedentPersonal', component: AntecedentPersonalComponent},
    {path: 'antecedentPersonalCreate', component: AntecedentPersonalCreateComponent},
    {path: 'antecedentPersonalRead', component: AntecedentPersonalReadComponent},
  {path: 'patientFamiliarAntecedent', component: PatientFamiliarAntecedentComponent},
    {path: 'patientFamiliarAntecedentCreate', component: PatientFamiliarAntecedentCreateComponent},
    {path: 'patientFamiliarAntecedentRead', component:PatientFamiliarAntecedentReadComponent },
    {path: 'patientFamiliarAntecedentSearch', component: PatientFamiliarAntecedentSearchComponent},
  {path: 'webhook' , component: WebhookComponent},
  {path: 'parameter' , component: ParameterComponent},
  {path: '**' , pathMatch:'full', redirectTo: 'home'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
