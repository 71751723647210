<div class="modal-header">
    <h4 class="modal-title">Autenticación</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="row">
        <div class="col-md-12">
            <div class="body-param">
                <form (ngSubmit)="onSubmit()" [formGroup]="authenticationForm">
                    <h3>Generar Token</h3>
                    <p class="label-gray">Es necesario generar un token para poder realizar cualquier acción (como la lectura de datos param&eacute;tricos), por favor revisa la secci&oacute;n <a href="/apikeycreate">Crear ApiKey</a> para que pueda crear un key y un secret y pueda consultar la informaci&oacute;n.</p>
                    <div class="input-whith-icon">
                        <input type="text" formControlName="key" class="form-control" placeholder="key"
                            [class.is-invalid]="(authenticationForm.get('key').touched && authenticationForm.get('key').invalid) || showClass" />
                        <i class="fa fa-user"></i>
                        <div *ngIf="form.key.errors" class="invalid-feedback">
                            <div *ngIf="form.key.errors.required">El Key es obligatorio</div>
                        </div>
                    </div>
                    <br>
                    <div class="input-whith-icon">
                        <input type="text" formControlName="secret" class="form-control" placeholder="secret"
                            [class.is-invalid]="(authenticationForm.get('secret').touched && authenticationForm.get('secret').invalid) || showClass2" />
                        <i class="fa fa-lock"></i>
                        <div *ngIf="form.secret.errors" class="invalid-feedback">
                            <div *ngIf="form.secret.errors.required">El secret es obligatorio</div>
                        </div>
                    </div>
                    <br>
                    <div *ngIf="submitted">
                        <h4 class="body-param-title">RESPUESTA</h4>
                        <pre class="pre-code">
                        <code [highlight]="json | json">
                        </code>
                        </pre>
                    </div>
                    <div class="col-md-12 text-right">
                        <button class="stlinkbutton" type="reset" (click)="onReset()">Limpiar</button>
                        <button class="stbutton">Enviar</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="activeModal.close('Close click')">Cancelar</button>
</div>