import { JsonpClientBackend } from '@angular/common/http';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationRedirectionComponent } from '../authentication-redirection/authentication-redirection.component';
import { ParametersService } from 'src/app/services/parameters.service';
import { Subject } from 'rxjs';
import {catchError, debounceTime, distinctUntilChanged, filter, map, switchMap, tap} from "rxjs/operators";
import { PaginatorComponent } from '../paginator/paginator.component';

@Component({
  selector: 'app-parameters-modal-paginator',
  templateUrl: './parameters-modal-paginator.component.html',
  styleUrls: ['./parameters-modal-paginator.component.css']
})
export class ParametersModalPaginatorComponent implements OnInit {

  @Input() searchTex:string = "";

  @Input() json;
  @Input() title:string = "Parametro";
  @Input() type:string;




  actualPage: number =1;
    disabledShowPrevious: boolean = true;
    disabledShowNext: boolean = true;
    totalPages: number;
  


  open() {
   this.parametersService.getParameters(this.type, "null", this.searchTex ).subscribe(
     resp => {
     this.json = resp;
     this.ngOnInit();
   },
   error =>{
     const modalRef = this.modalService.open(AuthenticationRedirectionComponent);
     modalRef.componentInstance.type = this.type;
     modalRef.componentInstance.title = this.title;
   },
   );
 }


 search(){
  this.actualPage = 1;
  this.page.pages = "0";
  this.open();
 }


  constructor(public activeModal: NgbActiveModal, private modalService: NgbModal, private parametersService: ParametersService, private page: ParametersService) { }

  ngOnInit(): void {
    this.page.pages = (this.actualPage -1).toString();
    this.totalPages = this.json.totalPages
    this.showPrevious();
    this.showNext();
  }

  previous(){
    this.actualPage = this.actualPage -1;
    this.ngOnInit()
    this.page.pages = (this.actualPage -1).toString();
    this.open();
  }

  next(){
    this.actualPage = this.actualPage +1;
    this.ngOnInit()
    this.page.pages = (this.actualPage -1).toString();
    this.open();
  }

  showPrevious(){
    if (this.actualPage == 1){
      this.disabledShowPrevious = false;
    } else {
      this.disabledShowPrevious = true;
    }
  }

  showNext(){
    if (this.actualPage == this.totalPages || this.actualPage > this.totalPages ){
      this.disabledShowNext = false;
    } else {
      this.disabledShowNext = true;
    }
  }

}
