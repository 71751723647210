import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ParametersService } from 'src/app/services/parameters.service';
import { AuthenticationRedirectionComponent } from '../authentication-redirection/authentication-redirection.component';

@Component({
  selector: 'app-parameters-modal-encounter-content',
  templateUrl: './parameters-modal-encounter-content.component.html',
  styleUrls: ['./parameters-modal-encounter-content.component.css']
})
export class ParametersModalEncounterContentComponent implements OnInit {

  @Input() searchTex:string = "";
  @Input() json;
  @Input() title:string = "Parametro";
  @Input() type:string;

  @Input() documentType:string;
  @Input() documentNumber:string;
  @Input() startDate:string;
  @Input() endDate:string;

  actualPage: number =1;
  disabledShowPrevious: boolean = true;
  disabledShowNext: boolean = true;
  totalPages: number;

  open() {
    this.parametersService.getEncounterId(this.type, this.documentType,this.documentNumber, this.startDate, this.endDate  ).subscribe(
      resp => {
      this.json = resp;
      this.ngOnInit();
    },
    error =>{
      const modalRef = this.modalService.open(AuthenticationRedirectionComponent);
      modalRef.componentInstance.type = this.type;
      modalRef.componentInstance.title = this.title;
    },
    );
  }

  search(){
    this.actualPage = 1;
    this.page.pages = "0";
    this.open();
   }

  constructor(public activeModal: NgbActiveModal, private modalService: NgbModal, private parametersService: ParametersService, private page: ParametersService) { }

  ngOnInit(): void {
    this.page.pages = (this.actualPage -1).toString();
    this.totalPages = this.json.totalPages
    this.showPrevious();
    this.showNext();
  }

  previous(){
    this.actualPage = this.actualPage -1;
    this.ngOnInit()
    this.page.pages = (this.actualPage -1).toString();
    this.open();
  }

  next(){
    this.actualPage = this.actualPage +1;
    this.ngOnInit()
    this.page.pages = (this.actualPage -1).toString();
    this.open();
  }

  showPrevious(){
    if (this.actualPage == 1){
      this.disabledShowPrevious = false;
    } else {
      this.disabledShowPrevious = true;
    }
  }

  showNext(){
    if (this.actualPage == this.totalPages || this.actualPage > this.totalPages ){
      this.disabledShowNext = false;
    } else {
      this.disabledShowNext = true;
    }
  }

}
