import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-exams-prescription-create',
  templateUrl: './exams-prescription-create.component.html',
  styleUrls: ['./exams-prescription-create.component.css']
})
export class ExamsPrescriptionCreateComponent implements OnInit {

  title: string = "Crear Prescripción de exámenes";
  subTitle: string = "Cree una nueva Prescripción de exámenes"

  paragraphTitle: string = "¿Cómo crear una Prescripción de exámenes?";
  paragraph: string = "Para crear una Prescripción de exámenes, primero debe tener un Token de acceso activo. Si su Token se venció, o aún no ha generado un Token de acceso. Genere un nuevo Token y consuma el evento de crear Prescripción de exámenes.";

  parameters = [
    {
      "Parameters": "eventType",
      "requerido": true,
      "Type": "String",
      "Description": "Es el tipo de evento que se quiere invocar en este caso debe ser \"EXAMS_PRESCRIPTION\"."
    },
    {
      "Parameters": "actionType",
      "requerido": true,
      "Type": "String",
      "Description": "Es la acción que se quiere realizar para crear un prescripción de exámenes debe ser \"CREATE\""
    },
    {
      "Parameters": "name",
      "requerido": false,
      "Type": "String",
      "Description": "Nombre del container que tendra los examenes prescritos"
    },
    {
      "Parameters": "encounterId",
      "requerido": false,
      "Type": "Long",
      "Description": "id del encuentro si la prescripción esta vínculada a uno"
    },
    {
      "Parameters": "documentType",
      "requerido": true,
      "Type": "Long",
      "Description": "Id del tipo de documento de identidad del paciente"
    },
    {
      "Parameters": "documentNumber",
      "requerido": true,
      "Type": "String",
      "Description": "Número del documento de identidad del paciente"
    },
    {
      "Parameters": "examsRemissions",
      "requerido": true,
      "Type": "List<ExamPrescriptionDto>",
      "Description": "Lista de examenes que se prescriben"
    },
    {
      "Parameters": "{",
      "requerido": false,
      "Type": "",
      "Description": ""
    },  
    {
      "Parameters": "examPrescriptionType",
      "requerido": false,
      "Type": "String",
      "Description": "Tipo de prescripción"
    },
    {
      "Parameters": "examTypeCode",
      "requerido": false,
      "Type": "String",
      "Description": "Código del tipo de examen, requerida si es de tipo PRESCRIPTION_CUPS"
    },
    {
      "Parameters": "comments",
      "requerido": false,
      "Type": "String",
      "Description": "Comentario"    
    },
    {
      "Parameters": "freePrescriptionText",
      "requerido": false,
      "Type": "String",
      "Description": "Prescripción libre, requerida si es de tipo PRESCRIPTION_FREE"
    },
    {
      "Parameters": "}",
      "requerido": false,
      "Type": "",
      "Description": ""
    }
  ]

  jsonEjemplos =
    [{
      "title": "Body",
      "body":{
        "eventType":"EXAMS_PRESCRIPTION",
        "actionType":"CREATE",
        "body": {
          "name" :"Nombre de prueba" ,
          "encounterId": null,
          "patientDocumentType":1 ,
          "patientDocumentNumber":"753698" ,
          "examsRemissions": [{
                    "examPrescriptionType" : "PRESCRIPTION_CUPS",
                  "examTypeCode" : "010101",
                  "comments" : "primer comentario cupd",
                  "freePrescriptionText" : "texto dos"
            },
             {
                    "examPrescriptionType" : "PRESCRIPTION_FREE",
                  "examTypeCode" : "010101",
                  "comments" : "segundo comentario libre",
                  "freePrescriptionText" : "pres libre"
            }]
        }
    },
      "subtitle": "Body para crear una prescripción de examenes, sustituya los parámetros por los de la prescripción a crear."
    }
    ]

  curlEjemplo = {
    "title": "cURL",
    "bodyPrueba": "curl --location --request POST 'https://saludtools.carecloud.com.co/integration/sync/event/v1/' --header 'Authorization: Bearer XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX' --header 'Content-Type: application/json' --data-raw" + '\'{ "eventType":"EXAMS_PRESCRIPTION", "actionType":"CREATE", "body": { "name" :"Nombre de prueba" , "encounterId": null, "patientDocumentType":1 , "patientDocumentNumber":"753698" , "examsRemissions": [{ "examPrescriptionType" : "PRESCRIPTION_CUPS", "examTypeCode" : "010101", "comments" : "primer comentario cupd", "freePrescriptionText" : "texto dos" }, { "examPrescriptionType" : "PRESCRIPTION_FREE", "examTypeCode" : "010101", "comments" : "segundo comentario libre", "freePrescriptionText" : "pres libre" }] } }\'',
    "bodyProduccion": "curl --location --request POST 'https://saludtools.qa.carecloud.com.co/integration/sync/event/v1/' --header 'Authorization: Bearer XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX' --header 'Content-Type: application/json' --data-raw" + '\'{ "eventType":"EXAMS_PRESCRIPTION", "actionType":"CREATE", "body": { "name" :"Nombre de prueba" , "encounterId": null, "patientDocumentType":1 , "patientDocumentNumber":"753698" , "examsRemissions": [{ "examPrescriptionType" : "PRESCRIPTION_CUPS", "examTypeCode" : "010101", "comments" : "primer comentario cupd", "freePrescriptionText" : "texto dos" }, { "examPrescriptionType" : "PRESCRIPTION_FREE", "examTypeCode" : "010101", "comments" : "segundo comentario libre", "freePrescriptionText" : "pres libre" }] } }\'',
    "subtitle": "Después de Bearer sustituya  las X por su access-token generado y cambie los parámetros por los datos de su prescripción a crear."
  }

  jsonResultados = [
    {
      "title": "OK 200",
      "body": {
        "id": 349705,
        "code": 200,
        "message": "Se registra id: 349705",
        "eventId": "128b6f55ff094c9586cd4f3f2ca03cda",
        "body": null
      },
      "subtitle": "La solicitud se realizó con éxito y prescripción ha sido creada.",
      "status": "200"
    },
    {
      "title": "401 Unauthorized",
      "body": 
        "No tiene permisos para acceder al servidor",
      "subtitle": "Coloque un token de acceso válido.",
      "status": "401"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
    "code": 412,
    "message": "No se ha enviado un tipo de evento valido",
    "eventId": "fe645e7a430c40bb946d221fb688351d",
    "body": null
      },
      "subtitle": "Se omitió el parámetro \"eventType\", para crear una prescripción de examenes es obligatorio el parametro \"eventType\", por favor agrégalo al inicio del body como en el ejemplo.",
      "status": "412"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
        "code": 412,
        "message": "No se ha enviado un tipo de evento valido",
        "eventId": "48c76fbf91cf46a19ebd948c3d2a6d5e",
        "body": null
      },
      "subtitle": "Error en el valor del parámetro \"eventType\". Para crear una prescripción de examenes el valor permitido del \"eventType\"es \"EXAMS_PRESCRIPTION\".",
      "status": "412"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
    "code": 412,
    "message": "No se ha enviado una accion válida",
    "eventId": "dd24b96692b744e4b97b397e5a3c1dd9",
    "body": null
      },
      "subtitle": "Se omitió el parámetro \"actionType\", para crear una prescripción de examenes es obligatorio el parámetro \"actionType\" que indica la acción de crear, por favor agrégalo al inicio del body como en el ejemplo.",
      "status": "412"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
        "code": 412,
        "message": "No se ha enviado una accion válida",
        "eventId": "a3d1a85dc6b649a2b4cb5ccc10d3e1c3",
        "body": null
      },
      "subtitle": "Error en el valor del parámetro \"actionType\". Para crear una prescripción de examenes el valor permitido del parámetro \"actionType\" es \"CREATE\".",
      "status": "412"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
        "code": 412,
        "message": "Request method 'PUT' not supported",
        "eventId": "81cf71d0bf1b41f391ff690352cad890",
        "body": null
      },
      "subtitle": "El método usado no es compatible con el recurso enviado, recuerde que el método que utiliza este servicio es POST.",
      "status": "412"
    }]

  constructor() { }

  ngOnInit(): void {
  }

}
