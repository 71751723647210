<div id="layoutSidenav">
    <div id="layoutSidenav_content">
        <br>
        <div class="container">
            <app-main-title [title]="title" [subTitle]="subTitle"></app-main-title>
            <app-main-paragraph [paragraphTitle]="paragraphTitle" [paragraph]="paragraph"></app-main-paragraph>
         
            <h4>Una prescripción de examenes contiene los siguientes atributos</h4>
                    <table class="table  table-bordered">
                        <thead class="thead-dark">
                            <tr style="padding: 0px !important;">
                                <th scope="col">Nombre</th>
                                <th scope="col">Tipo</th>
                                <th scope="col">Descripción</th>
                                <th scope="col">Ejemplo</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th scope="row">name</th>
                                <td>String</td>
                                <td>Nombre del container que tendra los examenes prescritos</td>
                                <td>"Nombre de prescripción"</td>
                            </tr>
                            <tr>
                                <th scope="row">encounterId</th>
                                <td>Long</td>
                                <td>id del encuentro si la prescripción esta vínculada a uno</td>
                                <td>1</td>
                            </tr>
                            <tr>
                                <th scope="row">patientDocumentType</th>
                                <td>Long</td>
                                <td>
                                    Tipo de documento del Paciente:  
                                        <ul>
                                            <li>1 = Cédula ciudadanía</li>
                                            <li>2 = Cédula de extranjería</li>
                                            <li>3 = Carné diplomático</li>
                                            <app-parameters-modal-button [type]="'documents'"></app-parameters-modal-button>
                                        </ul>
                                </td>
                                <td>2</td> 
                            </tr>
                            <tr>
                                <th scope="row">documentNumber</th>
                                <td>String</td>
                                <td>Número de documento del paciente</td>
                                <td>"123456789"</td>     
                            </tr>
                            <tr>
                                <th scope="row">examsRemissions</th>
                                <td>List&lt;ExamPrescriptionDto&gt;</td>
                                <td>Lista de examenes que se prescriben</td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>

                    <h4>ExamPrescriptionDto contiene los siguientes atributos</h4>
                    <table class="table  table-bordered">
                        <thead class="thead-dark">
                            <tr style="padding: 0px !important;">
                                <th scope="col">Nombre</th>
                                <th scope="col">Tipo</th>
                                <th scope="col">Descripción</th>
                                <th scope="col">Ejemplo</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th scope="row">examPrescriptionType</th>
                                <td>String</td>
                                <td>Tipo de prescripción
                                    <ul>
                                        <li>PRESCRIPTION_CUPS = Procedimiento codificado(CUPS)</li>
                                        <li>PRESCRIPTION_FREE = Prescripción libre</li>
                                    </ul>
                                </td>
                                <td>"PRESCRIPTION_CUPS"</td>
                            </tr>
                            <tr>
                                <th scope="row">examTypeCode</th>
                                <td>String</td>
                                <td>Código del tipo de examen si es de tipo PRESCRIPTION_CUPS </td>
                                <td>"010101"</td>
                            </tr>
                            <tr>
                                <th scope="row">comments</th>
                                <td>String</td>
                                <td>Comentario</td>
                                <td>"Esto es un comentario"</td>
                            </tr>
                            <tr>
                                <th scope="row">freePrescriptionText</th>
                                <td>String</td>
                                <td>Prescripción libre, si es de tipo PRESCRIPTION_FREE</td>
                                <td>"Esto es una prescrión libre"</td>
                            </tr>
                        </tbody>
                    </table>
                    <hr>
                    <app-footer></app-footer>
        </div>
    </div>
</div>
