import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-appointment-search',
  templateUrl: './appointment-search.component.html',
  styleUrls: ['./appointment-search.component.css']
})
export class AppointmentSearchComponent implements OnInit {

  title: string = "Buscar citas";
  subTitle: string = "Puede filtrar una lista de citas dado una cantidad de parámetros."

  paragraphTitle: string = "¿Cómo filtrar citas?";
  paragraph: string = "Para buscar una lista de citas, primero debe tener un Token de acceso activo. Si su Token se venció, o aún no ha generado un Token de acceso debe generar un nuevo Token, si ya está autenticado debe colocar en el body los parámetros que quiera filtrar. Puede elegir un parámetro o hacer una combinación de los parámetros que desee agregar para filtrar las citas.";

  parameters = [
    {
      "Parameters": "eventType",
      "requerido": true,
      "Type": "String",
      "Description": "Es el tipo de evento que se quiere invocar en este caso debe ser \"APPOINTMENT\"."
    },
    {
      "Parameters": "actionType",
      "requerido": true,
      "Type": "String",
      "Description": "Es la acción que se quiere realizar para filtrar citas debe ser \"SEARCH\""
    },
    {
      "Parameters": "startAppointment",
      "requerido": false,
      "Type": "ZonedDateTime",
      "Description": "Fecha y hora de inicio de la cita médica en formato yyyy-mm-dd HH:mm"
    },
    {
      "Parameters": "endAppointment",
      "requerido": false,
      "Type": "ZonedDateTime",
      "Description": "Fecha y hora de finalización de la cita médica en formato yyyy-mm-dd HH:mm"
    },
    {
      "Parameters": "patientDocumentType",
      "requerido": false,
      "Type": "Long",
      "Description": "Id del tipo de documento de identidad del paciente"
    },
    {
      "Parameters": "patientDocumentNumber",
      "requerido": false,
      "Type": "String",
      "Description": "Número del documento de identidad del paciente"
    },
    {
      "Parameters": "doctorDocumentType",
      "requerido": false,
      "Type": "Long",
      "Description": "Id del tipo de documento de identidad del doctor"
    },
    {
      "Parameters": "doctorDocumentNumber",
      "requerido": false,
      "Type": "String",
      "Description": "Número del documento de identidad del doctor"
    },
    {
      "Parameters": "modality",
      "requerido": false,
      "Type": "String",
      "Description": "Modalidad de la atención: CONVENTIONAL, TELEMEDICINE o DOMICILIARY"
    },
    {
      "Parameters": "stateAppointment",
      "requerido": false,
      "Type": "String",
      "Description": "Estado de la cita"
    },
    {
      "Parameters": "notificationState",
      "requerido": false,
      "Type": "String",
      "Description": "Estado de confirmación de la cita por parte del paciente"
    },
    {
      "Parameters": "appointmentType",
      "requerido": false,
      "Type": "String",
      "Description": "Tipo de cita"
    },
    {
      "Parameters": "clinic",
      "requerido": false,
      "Type": "Long",
      "Description": "Clínica donde se realizará la cita"
    },
    {
      "Parameters": "comment",
      "requerido": false,
      "Type": "String",
      "Description": "Comentarios de la cita"
    },
    {
      "Parameters": "page",
      "requerido": true,
      "Type": "Integer",
      "Description": "Página que se va a mostrar. La primera Página es 0"
    },
    {
      "Parameters": "size",
      "requerido": true,
      "Type": "Integer",
      "Description": "Cantidad de citas que se mostrarán en cada página"
    }
  ]

  jsonEjemplos =
    [{
      "title": "Body",
      "body":
      {
        "eventType": "APPOINTMENT",
        "actionType": "SEARCH",
        "body": {
          "startAppointment": "2021-05-05 11:00",
          "endAppointment": "2021-05-05 11:30",
          "patientDocumentType": 1,
          "patientDocumentNumber": "1414141",
          "doctorDocumentType": 1,
          "doctorDocumentNumber": "11111",
          "modality": "CONVENTIONAL",
          "stateAppointment": "IN_ROOM",
          "notificationState": "ATTEND",
          "appointmentType": "CITADEPRUEBA",
          "clinic": 8,
          "comment": "Estos son mis comentarios",
          "pageable": {
            "page": 0,
            "size": 20
          }
        }
      },
      "subtitle": "Body para filtrar todas las citas que cumplan con esos parámetros."
    },
    {
      "title": "Body",
      "body":
      {
        "eventType": "APPOINTMENT",
        "actionType": "SEARCH",
        "body": {
          "doctorDocumentType": 1,
          "doctorDocumentNumber": "11111",
          "pageable": {
            "page": 0,
            "size": 20
          }
        }
      },
      "subtitle": "Body para filtrar todas las citas del doctor que tenga ese número de documento."
    }
    ]

  curlEjemplo = {
    "title": "cURL",
    "bodyPrueba": "curl --location --request POST 'https://saludtools.qa.carecloud.com.co/integration/sync/event/v1/' --header 'Authorization: Bearer XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX' --header 'Content-Type: application/json' --data-raw" + '\'{ "eventType":"APPOINTMENT", "actionType":"SEARCH", "body": { "startAppointment":"2021-05-05 11:00", "endAppointment":"2021-05-05 11:30", "patientDocumentType":1, "patientDocumentNumber": "1414141", "doctorDocumentType":1, "doctorDocumentNumber":"11111", "modality":"CONVENTIONAL", "stateAppointment":"IN_ROOM", "notificationState": "ATTEND", "appointmentType":"CITADEPRUEBA", "clinic":8,"comment":"Estos son mis comentariosuuu", "pageable" :{ "page" : 0, "size" : 20 } } }\'',
    "bodyProduccion": "curl --location --request POST 'https://saludtools.carecloud.com.co/integration/sync/event/v1/' --header 'Authorization: Bearer XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX' --header 'Content-Type: application/json' --data-raw" + '\'{ "eventType":"APPOINTMENT", "actionType":"SEARCH", "body": { "startAppointment":"2021-05-05 11:00", "endAppointment":"2021-05-05 11:30", "patientDocumentType":1, "patientDocumentNumber": "1414141", "doctorDocumentType":1, "doctorDocumentNumber":"11111", "modality":"CONVENTIONAL", "stateAppointment":"IN_ROOM", "notificationState": "ATTEND", "appointmentType":"CITADEPRUEBA", "clinic":8,"comment":"Estos son mis comentariosuuu", "pageable" :{ "page" : 0, "size" : 20 } } }\'',
    "subtitle": "Después de Bearer sustituya  las X por su access-token generado y cambie los parámetros por los datos que desee filtrar"
  }

  jsonResultados = [
    {
      "title": "OK 200",
      "body": {
        "id": null,
        "code": 200,
        "message": "Se filtra informacion de ",
        "eventId": "735614fb7f934c38bad39c414b6c94a2",
        "body": {
          "content": [
            {
              "id": 2593841,
              "pageable": null,
              "startAppointment": "2021-04-30 08:00",
              "endAppointment": "2021-04-30 08:30",
              "patientDocumentType": 1,
              "patientDocumentNumber": "1414141",
              "doctorDocumentType": 1,
              "doctorDocumentNumber": "11111",
              "modality": "CONVENTIONAL",
              "stateAppointment": "PENDING",
              "notificationState": "ATTEND",
              "appointmentType": "CITADEPRUEBA",
              "clinic": 8,
              "comment": null
            },
            {
              "id": 2593842,
              "pageable": null,
              "startAppointment": "2021-05-04 08:00",
              "endAppointment": "2021-05-04 08:30",
              "patientDocumentType": 1,
              "patientDocumentNumber": "1414141",
              "doctorDocumentType": 1,
              "doctorDocumentNumber": "11111",
              "modality": "CONVENTIONAL",
              "stateAppointment": "PENDING",
              "notificationState": "ATTEND",
              "appointmentType": "CITADEPRUEBA",
              "clinic": 8,
              "comment": null
            },
            {
              "id": 2593844,
              "pageable": null,
              "startAppointment": "2021-05-05 11:00",
              "endAppointment": "2021-05-05 11:30",
              "patientDocumentType": 1,
              "patientDocumentNumber": "123456789",
              "doctorDocumentType": 1,
              "doctorDocumentNumber": "11111",
              "modality": "CONVENTIONAL",
              "stateAppointment": "IN_ROOM",
              "notificationState": "ATTEND",
              "appointmentType": "CITADEPRUEBA",
              "clinic": 8,
              "comment": "Estos son mis comentariosuuu"
            }
          ],
          "pageable": {
            "sort": {
              "sorted": false,
              "unsorted": true,
              "empty": true
            },
            "pageNumber": 0,
            "pageSize": 20,
            "offset": 0,
            "unpaged": false,
            "paged": true
          },
          "totalPages": 1,
          "totalElements": 3,
          "last": true,
          "sort": {
            "sorted": false,
            "unsorted": true,
            "empty": true
          },
          "numberOfElements": 3,
          "first": true,
          "size": 20,
          "number": 0,
          "empty": false
        }
      },
      "subtitle": "La solicitud se realizó con éxito, se han filtrado todas las citas que cumplen con los parámetros y además podrá observar información adicional como el total de citas que se encontraron y la cantidad de paginas.",
      "status": "200"
    },
    {
      "title": "401 Unauthorized",
      "body":
        "No tiene permisos para acceder al servidor",
      "subtitle": "Coloque un token de acceso válido.",
      "status": "401"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
        "code": 412,
        "message": "No se ha enviado un tipo de evento valido",
        "eventId": "fe645e7a430c40bb946d221fb688351d",
        "body": null
      },
      "subtitle": "Se omitió el parámetro \"eventType\", para filtrar citas es obligatorio el parametro \"eventType\", por favor agrégalo al inicio del body como en el ejemplo.",
      "status": "412"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
        "code": 412,
        "message": "No se ha enviado un tipo de evento valido",
        "eventId": "81b1de90a8894eeea01ea5ce20fc65a5",
        "body": null
      },
      "subtitle": "Error en el valor del parámetro \"eventType\". Para filtrar cita el valor permitido del \"eventType\"es \"APPOINTMENT\".",
      "status": "412"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
        "code": 412,
        "message": "No se ha enviado una accion válida",
        "eventId": "dd24b96692b744e4b97b397e5a3c1dd9",
        "body": null
      },
      "subtitle": "Se omitió el parámetro \"actionType\", para filtrar una cita es obligatorio el parámetro \"actionType\" que indica la accion que va a realizar el evento, por favor agrégalo al inicio del body como en el ejemplo.",
      "status": "412"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
        "code": 412,
        "message": "No se ha enviado una accion válida",
        "eventId": "3b29ed2a419d4dca857943c1f6daf601",
        "body": null

      },
      "subtitle": "Error en el valor del parámetro \"actionType\". Para filtrar citas el valor permitido del parámetro \"actionType\" es \"SEARCH\".",
      "status": "412"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
        "code": 412,
        "message": "Request method 'PUT' not supported",
        "eventId": "81cf71d0bf1b41f391ff690352cad890",
        "body": null
      },
      "subtitle": "El método usado no es compatible con el recurso enviado, recuerde que el método que utiliza este servicio es POST.",
      "status": "412"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
        "code": 412,
        "message": "Por favor envie los datos de paginacion de la consulta",
        "eventId": "7bd7685fd780428caa7cc5fb62dfd236",
        "body": null
      },
      "subtitle": "Los datos de paginación son obligatorios. debe colocar la página que desea mostrar y la cantidad de elementos que va contener cada página.",
      "status": "412"
    }]

  constructor() { }

  ngOnInit(): void {
  }

}
