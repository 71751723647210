import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-patient-create',
  templateUrl: './patient-create.component.html',
  styleUrls: ['./patient-create.component.css']
})
export class PatientCreateComponent implements OnInit {

  title: string = "Crear Paciente";
  subTitle: string = "Cree un nuevo paciente en Saludtools."

  paragraphTitle: string = "¿Cómo crear un nuevo paciente?";
  paragraph: string = "Para crear un nuevo paciente, primero debe tener un Token de acceso activo. Si su Token se venció, o aún no ha generado un Token de acceso. Genere un nuevo Token y consuma el evento de crear paciente.";

  parameters = [
    {
      "Parameters": "eventType",
      "requerido": true,
      "Type": "String",
      "Description": "Es el tipo de evento que se quiere invocar en este caso debe ser \"PATIENT\"."
    },
    {
      "Parameters": "actionType",
      "requerido": true,
      "Type": "String",
      "Description": "Es la acción que se quiere realizar para crear un paciente debe ser \"CREATE\""
    },
    {
      "Parameters": "firstName",
      "requerido": false,
      "Type": "String",
      "Description": "Primer nombre del paciente"
    },
    {
      "Parameters": "secondName",
      "requerido": false,
      "Type": "String",
      "Description": "Segundo nombre del paciente"
    },
    {
      "Parameters": "firstLastName",
      "requerido": false,
      "Type": "String",
      "Description": "Primer apellido del paciente"
    },
    {
      "Parameters": "secondLastName",
      "requerido": false,
      "Type": "String",
      "Description": "Segundo apellido del paciente"
    },
    {
      "Parameters": "birthDate",
      "requerido": false,
      "Type": "LocalDate",
      "Description": "Fecha de nacimiento del paciente formato yyyy-mm-dd."
    },
    {
      "Parameters": "gender",
      "requerido": false,
      "Type": "Long",
      "Description": "Id de género del paciente"
    },
    {
      "Parameters": "documentType",
      "requerido": true,
      "Type": "Long",
      "Description": "Id del tipo de documento de identidad del paciente"
    },
    {
      "Parameters": "documentNumber",
      "requerido": true,
      "Type": "String",
      "Description": "Número del documento de identidad del paciente"
    },
    {
      "Parameters": "phone",
      "requerido": false,
      "Type": "String",
      "Description": "Número de teléfono del paciente"
    },
    {
      "Parameters": "cellPhone",
      "requerido": false,
      "Type": "String",
      "Description": "Número de celular del paciente"
    },
    {
      "Parameters": "email",
      "requerido": false,
      "Type": "String",
      "Description": "Correo electrónico del paciente"
    },
    {
      "Parameters": "eps",
      "requerido": false,
      "Type": "Long",
      "Description": "Eps registrada del paciente"
    },
    {
      "Parameters": "habeasData",
      "requerido": false,
      "Type": "Boolean",
      "Description": "Confirmación del paciente de recibir comunicación (Habeas Data)"
    }
  ]

  jsonEjemplos =
    [{
      "title": "Body",
      "body":
      {
        "eventType": "PATIENT",
        "actionType": "CREATE",
        "body": {
          "firstName": "José",
          "secondName": "Andres",
          "firstLastName": "Gutierrez",
          "secondLastName": "Gomez",
          "birthDate": "1998-12-24",
          "gender": 2,
          "documentType": 1,
          "documentNumber": "123456789",
          "phone": "123456789",
          "cellPhone": "04258789555",
          "email": "email@empresa.com",
          "eps": 3,
          "habeasData": false
        }
      },
      "subtitle": "Body de un paciente, sustituya los parámetros por los del paciente a crear."
    }
    ]

  curlEjemplo = {
    "title": "cURL",
    "bodyPrueba": "curl --location --request POST 'https://saludtools.qa.carecloud.com.co/integration/sync/event/v1/' --header 'Authorization: Bearer XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX' --header 'Content-Type: application/json' --data-raw" + '\'{ "eventType":"PATIENT", "actionType":"CREATE", "body": { "firstName":"José", "secondName": "Andres", "firstLastName" : "Gutierrez", "secondLastName" : "Gomez", "birthDate" : "1998-12-24", "gender" : 2, "documentType": 1, "documentNumber" : "123456789", "phone" : "123456789", "cellPhone" : "04258789555", "email" : "email@empresa.com", "eps" : 3, "habeasData": false }}\'',
    "bodyProduccion": "curl --location --request POST 'https://saludtools.carecloud.com.co/integration/sync/event/v1/' --header 'Authorization: Bearer XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX' --header 'Content-Type: application/json' --data-raw" + '\'{ "eventType":"PATIENT", "actionType":"CREATE", "body": { "firstName":"José", "secondName": "Andres", "firstLastName" : "Gutierrez", "secondLastName" : "Gomez", "birthDate" : "1998-12-24", "gender" : 2, "documentType": 1, "documentNumber" : "123456789", "phone" : "123456789", "cellPhone" : "04258789555", "email" : "email@empresa.com", "eps" : 3, "habeasData": false }}\'',
    "subtitle": "Después de Bearer sustituya  las X por su access-token generado y cambie los parámetros por los datos de su paciente a crear."
  }

  jsonResultados = [
    {
      "title": "OK 200",
      "body": {
        "id": 349705,
        "code": 200,
        "message": "Se registra el paciente id: 349705",
        "eventId": "128b6f55ff094c9586cd4f3f2ca03cda",
        "body": null
      },
      "subtitle": "La solicitud se realizó con éxito y el paciente ha sido creado.",
      "status": "200"
    },
    {
      "title": "401 Unauthorized",
      "body": 
        "No tiene permisos para acceder al servidor",
      "subtitle": "Coloque un token de acceso válido.",
      "status": "401"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
    "code": 412,
    "message": "No se ha enviado un tipo de evento valido",
    "eventId": "fe645e7a430c40bb946d221fb688351d",
    "body": null
      },
      "subtitle": "Se omitió el parámetro \"eventType\", para crear un paciente es obligatorio el parametro \"eventType\", por favor agrégalo al inicio del body como en el ejemplo.",
      "status": "412"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
        "code": 412,
        "message": "No se ha enviado un tipo de evento valido",
        "eventId": "48c76fbf91cf46a19ebd948c3d2a6d5e",
        "body": null
      },
      "subtitle": "Error en el valor del parámetro \"eventType\". Para crear un paciente el valor permitido del \"eventType\"es \"PATIENT\".",
      "status": "412"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
    "code": 412,
    "message": "No se ha enviado una accion válida",
    "eventId": "dd24b96692b744e4b97b397e5a3c1dd9",
    "body": null
      },
      "subtitle": "Se omitió el parámetro \"actionType\", para crear un paciente es obligatorio el parámetro \"actionType\" que indica la acción de crear el paciente, por favor agrégalo al inicio del body como en el ejemplo.",
      "status": "412"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
        "code": 412,
        "message": "No se ha enviado una accion válida",
        "eventId": "a3d1a85dc6b649a2b4cb5ccc10d3e1c3",
        "body": null
      },
      "subtitle": "Error en el valor del parámetro \"actionType\". Para crear un paciente el valor permitido del parámetro \"actionType\" es \"CREATE\".",
      "status": "412"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
        "code": 412,
        "message": "Request method 'PUT' not supported",
        "eventId": "81cf71d0bf1b41f391ff690352cad890",
        "body": null
      },
      "subtitle": "El método usado no es compatible con el recurso enviado, recuerde que el método que utiliza este servicio es POST.",
      "status": "412"
    }]
    


  constructor() { }

  ngOnInit(): void {
  }

}
