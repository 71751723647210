import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-patient-gineco-information-create',
  templateUrl: './patient-gineco-information-create.component.html',
  styleUrls: ['./patient-gineco-information-create.component.css']
})
export class PatientGinecoInformationCreateComponent implements OnInit {

  title: string = "Crear Antecedentes Ginecoobstétricos";
  subTitle: string = "Cree un nuevo Antecedente Ginecoobstétrico"

  paragraphTitle: string = "¿Cómo crear un Antecedente Ginecoobstétrico?";
  paragraph: string = "Para crear un Antecedente Ginecoobstétrico, primero debe tener un Token de acceso activo. Si su Token se venció, o aún no ha generado un Token de acceso. Genere un nuevo Token y consuma el evento de crear un Antecedente Ginecoobstétrico.";

  parameters = [
    {
      "Parameters": "eventType",
      "requerido": true,
      "Type": "String",
      "Description": "Es el tipo de evento que se quiere invocar en este caso debe ser \"GYNECOOBS_HISTORY\"."
    },
    {
      "Parameters": "actionType",
      "requerido": true,
      "Type": "String",
      "Description": "Es la acción que se quiere realizar para crear un Antecedente Ginecoobstétrico debe ser \"CREATE\""
    },
    {
      "Parameters": "documentType",
      "requerido": true,
      "Type": "Long",
      "Description": "Id del tipo de documento de identidad del paciente"
    },
    {
      "Parameters": "documentNumber",
      "requerido": true,
      "Type": "String",
      "Description": "Número del documento de identidad del paciente"
    },
    {
      "Parameters": "fup",
      "requerido": false,
      "Type": "LocalDate",
      "Description": "Fecha del último parto (yyyy-mm-dd)"
    },
    {
      "Parameters": "weeksOfPregnancy",
      "requerido": false,
      "Type": "Integer",
      "Description": "Semanas de embarazo"
    },
    {
      "Parameters": "otherObservations",
      "requerido": false,
      "Type": "String",
      "Description": "Detalles del parto (máximo 2000 caracteres)"
    },
    {
      "Parameters": "fur",
      "requerido": false,
      "Type": "LocalDate",
      "Description": "Última menstruación (yyyy-mm-dd)"
    },
    {
      "Parameters": "trustworthy",
      "requerido": false,
      "Type": "Boolean",
      "Description": "¿Es la fecha confiable?"
    },
    {
      "Parameters": "contraceptiveType",
      "requerido": false,
      "Type": "Long",
      "Description": "Id del tipo de método anticonceptivo del paciente"
    },
    {
      "Parameters": "detailContraceptiveMethod",
      "requerido": false,
      "Type": "String",
      "Description": "Detalle del método de planificación (máximo 2000 caracteres)"
    },
    {
      "Parameters": "aliveBirths",
      "requerido": false,
      "Type": "Short",
      "Description": "Nacimientos vivos"
    },
    {
      "Parameters": "pregnancies",
      "requerido": false,
      "Type": "Short",
      "Description": "Número de embarazos"
    },
    {
      "Parameters": "births",
      "requerido": false,
      "Type": "Short",
      "Description": "Partos"
    },
    {
      "Parameters": "cesareans",
      "requerido": false,
      "Type": "Short",
      "Description": "Cesáreas"
    },
    {
      "Parameters": "ceases",
      "requerido": false,
      "Type": "Short",
      "Description": "Abortos"
    },
    {
      "Parameters": "molas",
      "requerido": false,
      "Type": "Short",
      "Description": "Molas"
    },
    {
      "Parameters": "ectopics",
      "requerido": false,
      "Type": "Short",
      "Description": "Ectópicos"
    },
    {
      "Parameters": "menarche",
      "requerido": false,
      "Type": "Long",
      "Description": "Menarquia (edad), Aparición de la primera menstruación"
    },
    {
      "Parameters": "pubarchy",
      "requerido": false,
      "Type": "Short",
      "Description": "Pubarquia (edad), Primera aparición de vello púbico"
    },
    {
      "Parameters": "telarchy",
      "requerido": false,
      "Type": "Short",
      "Description": "Telarquia (edad), Inicio del desarrollo de la mama"
    },
    {
      "Parameters": "irs",
      "requerido": false,
      "Type": "Short",
      "Description": "Sexarquia (edad), Inicio de las relaciones sexuales"
    },
    {
      "Parameters": "sexualPartners",
      "requerido": false,
      "Type": "Short",
      "Description": "Compañeros sexuales"
    },
    {
      "Parameters": "menstrualRegularity",
      "requerido": false,
      "Type": "String",
      "Description": "Regularidad del ciclo menstrual"
    },
    {
      "Parameters": "menstrualCycles",
      "requerido": false,
      "Type": "String",
      "Description": "Ciclos menstruales, duración y periodicidad, (máximo 5000 caracteres)"
    },
    {
      "Parameters": "lastCytologyDate",
      "requerido": false,
      "Type": "LocalDate",
      "Description": "Última citología (yyyy-mm-dd)"
    },
    {
      "Parameters": "previousGestationalObservations",
      "requerido": false,
      "Type": "String",
      "Description": "Observaciones de las gestaciones anteriores,(máximo 2000 caracteres)"
    },
    {
      "Parameters": "currentlyPregnant",
      "requerido": false,
      "Type": "Boolean",
      "Description": "¿Actualmente embarazada?, Si el valor del campo es true, se guardan los siguientes campos: ultrasoundDate, gestationWeek, gestationDay, observations."
    },
    {
      "Parameters": "ultrasoundDate",
      "requerido": false,
      "Type": "LocalDate",
      "Description": "Última ecografía (yyyy-mm-dd)"
    },
    {
      "Parameters": "gestationWeek",
      "requerido": false,
      "Type": "Short",
      "Description": "Semanas de gestación"
    },
    {
      "Parameters": "gestationDay",
      "requerido": false,
      "Type": "Short",
      "Description": "Días de gestación"
    },
    {
      "Parameters": "observations",
      "requerido": false,
      "Type": "String",
      "Description": "Observaciones de la gestación actual (máximo 2000 caracteres)"
    },
    
    
  ]

  jsonEjemplos =
    [{
      "title": "Body",
      "body":{
        "eventType":"GYNECOOBS_HISTORY",
        "actionType":"CREATE",
        "body": {
          "documentType": 1,
          "documentNumber": "65135",
          "menarche": 8,
          "ceases": 5,
          "irs": 11,
          "fur": "2022-07-05",
          "fup": "2020-07-13",
          "contraceptiveType": 26,
          "detailContraceptiveMethod": "Detalle del método de planificación",
          "observations": "Gestación actual",
          "aliveBirths": 1,
          "pregnancies": 2,
          "births": 3,
          "cesareans": 4,
          "molas": 6,
          "ectopics": 7,
          "pubarchy": 9,
          "telarchy": 10,
          "sexualPartners": 12,
          "menstrualCycles": "Ciclos menstruales, duración y periocidad",
          "menstrualRegularity": "REGULAR",
          "lastCytologyDate": "2022-10-10",
          "currentlyPregnant": true,
          "isTrustworthy": true,
          "previousGestationalObservations": "observaciones de las gestaciones",
          "ultrasoundDate": "2022-07-12",
          "gestationWeek": 6,
          "gestationDay": 2,
          "weeksOfPregnancy": 20,
          "otherObservations": "Detalles del parto"
        }
    },
      "subtitle": "Body para crear un Antecedente Ginecoobstétrico, sustituya los parámetros por los del Antecedente Ginecoobstétrico a crear."
    }
    ]

  curlEjemplo = {
    "title": "cURL",
    "bodyPrueba": "curl --location --request POST 'https://saludtools.qa.carecloud.com.co/integration/sync/event/v1/' --header 'Authorization: Bearer XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX' --header 'Content-Type: application/json' --data-raw" + '\'{ "eventType":"GYNECOOBS_HISTORY", "actionType":"CREATE", "body": '+ JSON.stringify(this.jsonEjemplos[0].body.body)+'}\'',
    "bodyProduccion": "curl --location --request POST 'https://saludtools.carecloud.com.co/integration/sync/event/v1/' --header 'Authorization: Bearer XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX' --header 'Content-Type: application/json' --data-raw" + '\'{ "eventType":"GYNECOOBS_HISTORY", "actionType":"CREATE", "body": '+ JSON.stringify(this.jsonEjemplos[0].body.body)+'}\'',
    "subtitle": "Después de Bearer sustituya  las X por su access-token generado y cambie los parámetros por los datos de su prescripción a crear."
  }

  jsonResultados = [
    {
      "title": "OK 200",
      "body": {
        "id": 349705,
        "code": 200,
        "message": "Se registra el Antecedente Ginecoobstétrico id: 8487",
        "eventId": "128b6f55ff094c9586cd4f3f2ca03cda",
        "body": null
      },
      "subtitle": "La solicitud se realizó con éxito y el Antecedente Ginecoobstétrico ha sido creado.",
      "status": "200"
    },
    {
      "title": "401 Unauthorized",
      "body": 
        "No tiene permisos para acceder al servidor",
      "subtitle": "Coloque un token de acceso válido.",
      "status": "401"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
    "code": 412,
    "message": "No se ha enviado un tipo de evento valido",
    "eventId": "fe645e7a430c40bb946d221fb688351d",
    "body": null
      },
      "subtitle": "Se omitió el parámetro \"eventType\", para crear un Antecedente Ginecoobstétrico es obligatorio el parametro \"eventType\", por favor agrégalo al inicio del body como en el ejemplo.",
      "status": "412"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
        "code": 412,
        "message": "No se ha enviado un tipo de evento valido",
        "eventId": "48c76fbf91cf46a19ebd948c3d2a6d5e",
        "body": null
      },
      "subtitle": "Error en el valor del parámetro \"eventType\". Para crear un Antecedente Ginecoobstétrico el valor permitido del \"eventType\"es \"GYNECOOBS_HISTORY\".",
      "status": "412"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
    "code": 412,
    "message": "No se ha enviado una accion válida",
    "eventId": "dd24b96692b744e4b97b397e5a3c1dd9",
    "body": null
      },
      "subtitle": "Se omitió el parámetro \"actionType\", para crear un Antecedente Ginecoobstétrico es obligatorio el parámetro \"actionType\" que indica la acción de crear, por favor agrégalo al inicio del body como en el ejemplo.",
      "status": "412"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
        "code": 412,
        "message": "No se ha enviado una accion válida",
        "eventId": "a3d1a85dc6b649a2b4cb5ccc10d3e1c3",
        "body": null
      },
      "subtitle": "Error en el valor del parámetro \"actionType\". Para crear un un Antecedente Ginecoobstétrico el valor permitido del parámetro \"actionType\" es \"CREATE\".",
      "status": "412"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
        "code": 412,
        "message": "Request method 'PUT' not supported",
        "eventId": "81cf71d0bf1b41f391ff690352cad890",
        "body": null
      },
      "subtitle": "El método usado no es compatible con el recurso enviado, recuerde que el método que utiliza este servicio es POST.",
      "status": "412"
    }]

  constructor() { }

  ngOnInit(): void {
  }

}
