import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-patient-inability-work-search',
  templateUrl: './patient-inability-work-search.component.html',
  styleUrls: ['./patient-inability-work-search.component.css']
})
export class PatientInabilityWorkSearchComponent implements OnInit {

  title: string = "Buscar Prescripciones de Incapacidad";
  subTitle: string = "Puede filtrar una lista de incapacidades dado una cantidad de parámetros."

  paragraphTitle: string = "¿Cómo filtrar las prescripciones de incapacidad?";
  paragraph: string = "Para buscar una lista de incapacidades, primero debe tener un token de acceso activo. Si su token se venció, o aun no ha generado un token de acceso. genere uno nuevo. Si ya está autenticado debe colocar en el body los parámetros que quiera filtrar. Puede elegir un parámetro o hacer una combinación de los parámetros que desee agregar para filtrar las incapacidades.";

  parameters = [
    {
      "Parameters": "eventType",
      "requerido": true,
      "Type": "String",
      "Description": "Es el tipo de evento que se quiere invocar en este caso debe ser \"INABILITYWORK\"."
    },
    {
      "Parameters": "actionType",
      "requerido": true,
      "Type": "String",
      "Description": "Es la acción que se quiere realizar para buscar las incapacidades de un paciente debe ser \"SEARCH\""
    },
    {
      "Parameters": "documentType",
      "requerido": false,
      "Type": "Long",
      "Description": "Id del tipo de documento de identidad del paciente"
    },
    {
      "Parameters": "documentNumber",
      "requerido": false,
      "Type": "String",
      "Description": "Número del documento de identidad del paciente"
    }
  ]

  jsonEjemplos =
    [{
      "title": "Body",
      "body":
      {
        "eventType": "INABILITYWORK",
        "actionType": "SEARCH",
        "body": {
          "documentType": 1,
          "documentNumber": "123456789",
          "pageable": {
            "page": 5,
            "size": 5
          }
        }
      },
      "subtitle": "Body para filtrar todas las incapacidades que cumplan con esos parámetros."
    }
    ]

  curlEjemplo = {
    "title": "cURL",
    "bodyPrueba": "curl --location --request POST 'https://saludtools.qa.carecloud.com.co/integration/sync/event/v1/' --header 'Authorization: Bearer XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX' --header 'Content-Type: application/json' --data-raw" + '\'{ "eventType":"INABILITYWORK", "actionType":"SEARCH", "body": { "documentType": 1, "documentNumber" : "123456789", "pageable" :{ "page" : 5, "size" : 5 } } }\'',
    "bodyProduccion": "curl --location --request POST 'https://saludtools.carecloud.com.co/integration/sync/event/v1/' --header 'Authorization: Bearer XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX' --header 'Content-Type: application/json' --data-raw" + '\'{ "eventType":"INABILITYWORK", "actionType":"SEARCH", "body": { "documentType": 1, "documentNumber" : "123456789", "pageable" :{ "page" : 5, "size" : 5 } } }\'',
    "subtitle": "Después de Bearer sustituya  las X por su access-token generado y cambie los parámetros por los datos a filtrar."
  }

  jsonResultados = [
    {
      "title": "OK 200",
      "body": {
        "id": null,
        "code": 200,
        "message": "Se filtra informacion de ",
        "eventId": "9c2e389959c54f4f91b0d3b637f4d09f",
        "body": {
            "content": [
                {
                    "id": null,
                    "pageable": null,
                    "documentType": null,
                    "documentNumber": null,
                    "request": null,
                    "response": {
                        "id": 3454,
                        "diagnosticCIE10": "FIEBRE TIFOIDEA",
                        "consultationExternalCause": "Accidente de trabajo",
                        "treatmentAreaOfApplication": "Trabajo Social",
                        "reoccurenceType": "Única",
                        "startInabilityDate": "2022-09-27T00:00:00.000+00:00",
                        "endInabilityDate": "2023-01-28T00:00:00.000+00:00",
                        "comments": "Dolores abdominales..."
                    }
                },
                {
                    "id": null,
                    "pageable": null,
                    "documentType": null,
                    "documentNumber": null,
                    "request": null,
                    "response": {
                        "id": 3455,
                        "diagnosticCIE10": "FIEBRE TIFOIDEA",
                        "consultationExternalCause": "Accidente de trabajo",
                        "treatmentAreaOfApplication": "Trabajo Social",
                        "reoccurenceType": "Única",
                        "startInabilityDate": "2022-09-27T00:00:00.000+00:00",
                        "endInabilityDate": "2023-01-28T00:00:00.000+00:00",
                        "comments": "Dolores abdominales..."
                    }
                },
                {
                    "id": null,
                    "pageable": null,
                    "documentType": null,
                    "documentNumber": null,
                    "request": null,
                    "response": {
                        "id": 3456,
                        "diagnosticCIE10": "FIEBRE TIFOIDEA",
                        "consultationExternalCause": "Accidente de trabajo",
                        "treatmentAreaOfApplication": "Trabajo Social",
                        "reoccurenceType": "Única",
                        "startInabilityDate": "2022-09-27T00:00:00.000+00:00",
                        "endInabilityDate": "2023-01-28T00:00:00.000+00:00",
                        "comments": "Otros..."
                    }
                }
            ],
            "pageable": {
                "sort": {
                    "sorted": false,
                    "unsorted": true,
                    "empty": true
                },
                "offset": 25,
                "pageSize": 5,
                "pageNumber": 5,
                "paged": true,
                "unpaged": false
            },
            "last": true,
            "totalPages": 6,
            "totalElements": 28,
            "size": 5,
            "number": 5,
            "sort": {
                "sorted": false,
                "unsorted": true,
                "empty": true
            },
            "first": false,
            "numberOfElements": 3,
            "empty": false
        }
      },
      "subtitle": "La solicitud se realizó con éxito, se han filtrado todas las citas que cumplen con los parámetros y además podrá observar información adicional como el total de citas que se encontraron  (totalElements) y la cantidad de paginas (totalPages).",
      "status": "200"
    },
    {
      "title": "401 Unauthorized",
      "body":
        "No tiene permisos para acceder al servidor",
      "subtitle": "Coloque un token de acceso válido.",
      "status": "401"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
        "code": 412,
        "message": "No se ha enviado un tipo de evento valido",
        "eventId": "fe645e7a430c40bb946d221fb688351d",
        "body": null
      },
      "subtitle": "Se omitió el parámetro \"eventType\", para buscar las incapacidades de un paciente es obligatorio el parametro \"eventType\", por favor agrégalo al inicio del body como en el ejemplo.",
      "status": "212"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
        "code": 412,
        "message": "No se ha enviado un tipo de evento valido",
        "eventId": "48c76fbf91cf46a19ebd948c3d2a6d5e",
        "body": null
      },
      "subtitle": "Error en el valor del parámetro \"eventType\", para buscar las incapacidades de un paciente el valor permitido del \"eventType\"es \"INABILITYWORK\".",
      "status": "412"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
        "code": 412,
        "message": "No se ha enviado una accion válida",
        "eventId": "dd24b96692b744e4b97b397e5a3c1dd9",
        "body": null
      },
      "subtitle": "Se omitió el parámetro \"actionType\", para buscar las incapacidades de un paciente es obligatorio el parámetro \"actionType\" que indica la accion de filtrar los paciente, por favor agrégalo al inicio del body como en el ejemplo.",
      "status": "412"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
        "code": 412,
        "message": "No se ha enviado una accion válida",
        "eventId": "a3d1a85dc6b649a2b4cb5ccc10d3e1c3",
        "body": null
      },
      "subtitle": "Error en el valor del parámetro \"actionType\", para buscar las incapacidades de un paciente el valor permitido del parámetro \"actionType\" es \"SEARCH\".",
      "status": "412"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
        "code": 412,
        "message": "Request method 'PUT' not supported",
        "eventId": "81cf71d0bf1b41f391ff690352cad890",
        "body": null
      },
      "subtitle": "El método usado no es compatible con el recurso enviado, recuerde que el método que utiliza este servicio es POST.",
      "status": "412"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
        "code": 412,
        "message": "Por favor envie los datos de paginacion de la consulta",
        "eventId": "7bd7685fd780428caa7cc5fb62dfd236",
        "body": null
      },
      "subtitle": "Los datos de paginación son obligatorios. debe colocar la página que desea mostrar y la cantidad de elementos que va contener cada página.",
      "status": "412"
    }]

  constructor() { }

  ngOnInit(): void {
  }

}
