import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationError } from 'src/app/models/authentication-error';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ParametersService } from 'src/app/services/parameters.service';
import { AuthenticationRedirectionComponent } from '../authentication-redirection/authentication-redirection.component';
import { ParametersModalEncounterContentComponent } from '../parameters-modal-encounter-content/parameters-modal-encounter-content.component';
import { ParametersModalContentComponent } from '../parameters-modal/parameters-modal-content/parameters-modal-content.component';

@Component({
  selector: 'app-parameters-modal-encounter-form',
  templateUrl: './parameters-modal-encounter-form.component.html',
  styleUrls: ['./parameters-modal-encounter-form.component.css']
})
export class ParametersModalEncounterFormComponent implements OnInit {

  authenticationForm: FormGroup;
  submitted = false;
  json: any;
  showClass: boolean = false;
  showClass2: boolean = false;
  showClass3: boolean = false;
  showClass4: boolean = false;

  @Input() type:string;
  @Input() title:string;
  @Input() actPrinciple: string = null;



  onSubmit(){
    if (this.authenticationForm.invalid) {
      if (this.form.documentType.errors) {
        this.showClass = true;
      }
      if (this.form.documentNumber.errors) {
        this.showClass4 = true;
      }

      if (this.form.startDate.errors) {
        this.showClass2 = true;
      }
      if (this.form.endDate.errors) {
        this.showClass3 = true;
      }
      return;
    }

    this.submitted = true;

    let startDate = this.authenticationForm.value.startDate;
    let endDate = this.authenticationForm.value.endDate;

      this.parametersService.getEncounterId(this.type, this.authenticationForm.value.documentType,this.authenticationForm.value.documentNumber, startDate, endDate ).subscribe(
        resp => {
        const modalRef = this.modalService.open(ParametersModalEncounterContentComponent);
        modalRef.componentInstance.json = resp;
        modalRef.componentInstance.title = this.title;
        modalRef.componentInstance.type = this.type;
        modalRef.componentInstance.documentType = this.authenticationForm.value.documentType;
        modalRef.componentInstance.documentNumber = this.authenticationForm.value.documentNumber;
        modalRef.componentInstance.startDate = startDate;
        modalRef.componentInstance.endDate = endDate;
        this.activeModal.close();
      },
      error =>{
        if(this.json = error.error.message) {
          this.json

        } else {
        const modalRef = this.modalService.open(AuthenticationRedirectionComponent);
        modalRef.componentInstance.type = this.type;
        modalRef.componentInstance.title = this.title;
        this.activeModal.close();
        }
      },
      );
  }


  constructor(public activeModal: NgbActiveModal, private formBuilder: FormBuilder, private authenticationService: AuthenticationService, private parametersService: ParametersService, private modalService: NgbModal) { }

  ngOnInit(): void {
    this.authenticationForm = this.formBuilder.group(
      {
        documentType: ["", Validators.required],
        documentNumber: ["", Validators.required],
        startDate: ["", Validators.required],
        endDate: ["", Validators.required],
      },

    );
  }

  get form() {
    return this.authenticationForm.controls;
  }

  onReset() {
    this.submitted = false;
    this.authenticationForm.reset();
    this.showClass = false;
    this.showClass2 = false;
    this.showClass3 = false;
  }







}
