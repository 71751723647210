<div class="row">
    <div class="col-md-12">
        <div class="card">
            <div class="card-header" *ngIf="imgCardTitle">
                {{imgCardTitle}}
            </div>
            <div class="card-body-img">
                <p class="card-text img-pie">
                    <img src="{{imgCardhref}}" class="img-fluid" alt="{{imgCardhref}}">
                </p>
                <div>
                    <p class="p-card">{{imgCardSub}}</p>
                </div>
            </div>
        </div>
    </div>
</div>