<div id="layoutSidenav">
    <div id="layoutSidenav_content">
        <br>
        <div class="container">
            <app-main-title [title]="title" [subTitle]="subTitle"></app-main-title>
            <app-main-paragraph [paragraphTitle]="paragraphTitle" [paragraph]="paragraph"></app-main-paragraph>

            <app-img-card [imgCardhref]="imgCardhref" [imgCardSub]="imgCardSub"></app-img-card>
            <hr>

            <h4>Componentes de una ApiKey</h4>
                    <ol>
                        <li>Key: Es el nombre de usuario y está constituido por la palabra
                            "STAKOA" seguido de 24 caracteres.</li>
                        <li>Secret: Es la clave secreta encriptada y segura para su uso.</li>
                    </ol>
                    <hr>
                    <app-footer></app-footer>
        </div>
    </div>
</div>