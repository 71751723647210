import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-appointment-create',
  templateUrl: './appointment-create.component.html',
  styleUrls: ['./appointment-create.component.css']
})
export class AppointmentCreateComponent implements OnInit {

  title: string = "Crear Cita";
  subTitle: string = "Asigne una nueva cita a un paciente ya registrado en Saludtools."

  paragraphTitle: string = "¿Cómo crear una nueva cita?";
  paragraph: string = "Para crear una nueva cita, primero debe tener un Token de acceso activo. Si su Token se venció, o aún no ha generado un Token de acceso. Genere un nuevo Token y consuma el evento para crear una nueva cita.";

  parameters = [
    {
      "Parameters": "eventType",
      "requerido": true,
      "Type": "String",
      "Description": "Es el tipo de evento que se quiere invocar en este caso debe ser \"APPOINTMENT\"."
    },
    {
      "Parameters": "actionType",
      "requerido": true,
      "Type": "String",
      "Description": "Es la acción que se quiere realizar para agendar una cita debe ser \"CREATE\""
    },
    {
      "Parameters": "startAppointment",
      "requerido": true,
      "Type": "ZonedDateTime",
      "Description": "Fecha y hora de inicio de la cita médica en formato yyyy-mm-dd HH:mm"
    },
    {
      "Parameters": "endAppointment",
      "requerido": true,
      "Type": "ZonedDateTime",
      "Description": "Fecha y hora de finalización de la cita médica en formato yyyy-mm-dd HH:mm"
    },
    {
      "Parameters": "patientDocumentType",
      "requerido": true,
      "Type": "Long",
      "Description": "Id del tipo de documento de identidad del paciente"
    },
    {
      "Parameters": "patientDocumentNumber",
      "requerido": true,
      "Type": "String",
      "Description": "Número del documento de identidad del paciente"
    },
    {
      "Parameters": "doctorDocumentType",
      "requerido": true,
      "Type": "Long",
      "Description": "Id del tipo de documento de identidad del doctor"
    },
    {
      "Parameters": "doctorDocumentNumber",
      "requerido": true,
      "Type": "String",
      "Description": "Número del documento de identidad del doctor"
    },
    {
      "Parameters": "modality",
      "requerido": true,
      "Type": "String",
      "Description": "Modalidad de la atención: CONVENTIONAL, TELEMEDICINE o DOMICILIARY"
    },
    {
      "Parameters": "stateAppointment",
      "requerido": true,
      "Type": "String",
      "Description": "Estado de la cita"
    },
    {
      "Parameters": "notificationState",
      "requerido": false,
      "Type": "String",
      "Description": "Estado de confirmación de la cita por parte del paciente"
    },
    {
      "Parameters": "appointmentType",
      "requerido": true,
      "Type": "String",
      "Description": "Tipo de cita"
    },
    {
      "Parameters": "clinic",
      "requerido": true,
      "Type": "Long",
      "Description": "Clínica donde se realizará la cita"
    },
    {
      "Parameters": "comment",
      "requerido": false,
      "Type": "String",
      "Description": "Comentarios de la cita"
    }
  ]

  jsonEjemplos =
    [{
      "title": "Body",
      "body":
      {
        "eventType": "APPOINTMENT",
        "actionType": "CREATE",
        "body": {
          "startAppointment": "2021-04-30 08:00",
          "endAppointment": "2021-04-30 08:30",
          "patientDocumentType": 1,
          "patientDocumentNumber": "177400432",
          "doctorDocumentType": 1,
          "doctorDocumentNumber": "99988877711",
          "modality": "CONVENTIONAL",
          "stateAppointment": "PENDING",
          "notificationState": "ATTEND",
          "appointmentType": "Pruebas Luis",
          "clinic": 8,
          "comment": "Estos son mis comentarios"
        }
      },
      "subtitle": "Body para crear una cita."
    }
    ]

  curlEjemplo = {
    "title": "cURL",
    "bodyPrueba": "curl --location --request POST 'https://saludtools.qa.carecloud.com.co/integration/sync/event/v1/' --header 'Authorization: Bearer XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX' --header 'Content-Type: application/json' --data-raw" + '\'{ "eventType":"APPOINTMENT", "actionType":"CREATE", "body": { "startAppointment":"2021-04-30 08:00", "endAppointment":"2021-04-30 08:30", "patientDocumentType":1, "patientDocumentNumber": "177400432", "doctorDocumentType":1, "doctorDocumentNumber":"99988877711", "modality":"CONVENTIONAL", "stateAppointment":"PENDING", "notificationState": "ATTEND", "appointmentType":"Pruebas CITA", "clinic":8, "comment":"Estos son mis comentarios"}}\'',
    "bodyProduccion": "curl --location --request POST 'https://saludtools.carecloud.com.co/integration/sync/event/v1/' --header 'Authorization: Bearer XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX' --header 'Content-Type: application/json' --data-raw" + '\'{ "eventType":"APPOINTMENT", "actionType":"CREATE", "body": { "startAppointment":"2021-04-30 08:00", "endAppointment":"2021-04-30 08:30", "patientDocumentType":1, "patientDocumentNumber": "177400432", "doctorDocumentType":1, "doctorDocumentNumber":"99988877711", "modality":"CONVENTIONAL", "stateAppointment":"PENDING", "notificationState": "ATTEND", "appointmentType":"Pruebas CITA", "clinic":8, "comment":"Estos son mis comentarios"}}\'',
    "subtitle": "Después de Bearer sustituya  las X por su access-token generado y cambie los parámetros por los datos de su cita a crear."
  }

  jsonResultados = [
    {
      "title": "OK 200",
      "body": {
        "id": 2593842,
        "code": 200,
        "message": "Se registra  id: 2593842",
        "eventId": "347bd40882ac471e9e5c20fc62c57c73",
        "body": null
      },
      "subtitle": "La solicitud se realizó con éxito y la cita ha sido creada.",
      "status": "200"
    },
    {
      "title": "401 Unauthorized",
      "body":
        "No tiene permisos para acceder al servidor",
      "subtitle": "Coloque un Token de acceso válido.",
      "status": "401"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
        "code": 412,
        "message": "No se ha enviado un tipo de evento valido",
        "eventId": "fe645e7a430c40bb946d221fb688351d",
        "body": null
      },
      "subtitle": "Se omitió el parámetro \"eventType\", para crear una cita es obligatorio el parametro \"eventType\", por favor agregalo al inicio del body como en el ejemplo.",
      "status": "412"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
        "code": 412,
        "message": "No se ha enviado un tipo de evento valido",
        "eventId": "81b1de90a8894eeea01ea5ce20fc65a5",
        "body": null
      },
      "subtitle": "Error en el valor del parámetro \"eventType\". Para generar una cita el valor permitido del \"eventType\"es \"APPOINTMENT\".",
      "status": "412"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
        "code": 412,
        "message": "No se ha enviado una accion válida",
        "eventId": "dd24b96692b744e4b97b397e5a3c1dd9",
        "body": null
      },
      "subtitle": "Se omitió el parámetro \"actionType\", para crear una cita es obligatorio el parámetro \"actionType\" que indica la acción que va a realizar el evento, por favor agrégalo al inicio del body como en el ejemplo.",
      "status": "412"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
        "code": 412,
        "message": "No se ha enviado una accion válida",
        "eventId": "df6a792ae41644fea23873e1db4244d9",
        "body": null

      },
      "subtitle": "Error en el valor del parámetro \"actionType\". Para crear una cita el valor permitido del parámetro \"actionType\" es \"CREATE\".",
      "status": "412"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
        "code": 412,
        "message": "Request method 'PUT' not supported",
        "eventId": "81cf71d0bf1b41f391ff690352cad890",
        "body": null
      },
      "subtitle": "El método usado no es compatible con el recurso enviado, recuerde que el método que utiliza este servicio es POST.",
      "status": "412"
    }]

  constructor() { }

  ngOnInit(): void {
  }

}
