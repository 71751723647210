import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-api-key',
  templateUrl: './api-key.component.html',
  styleUrls: ['./api-key.component.css']
})
export class ApiKeyComponent implements OnInit {

  title: string ="ApiKey";
  subTitle: string ="Autenticarse con una ApiKey será el primer paso para utilizar la API de integración con saludtools."

  paragraphTitle: string ="¿Que es una ApiKey?";
  paragraph: string ="Una ApiKey es la llave para acceder e integrarse con saludtools. Esta será la encargada de generar el token de autenticación; Se puede crear una ApiKey en cualquier momento.";

  imgCardhref="assets/img/ApiKey/ApiKey.png";
  imgCardSub="La imagen muestra una ApiKey creada exitosamente.";

  constructor() { }

  ngOnInit(): void {
  }

}
