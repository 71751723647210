import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-token',
  templateUrl: './token.component.html',
  styleUrls: ['./token.component.css']
})
export class TokenComponent implements OnInit {

  title: string = "Token de Acceso";
  subTitle: string = "En Saludtools utilizamos un token de acceso para iniciar sesión; Un Token de acceso es una cadena encriptada y creada con lo necesario para iniciar la sesion de un usuario. Este Token tiene un tiempo de vencimiento y al vencerse se debe generar un Token nuevo."

  paragraphTitle: string = "¿Cómo generar un token de acceso?";
  paragraph: string = "Para generar un Token de acceso, primero debe tener creada su ApiKey. Luego de creada puede generar su token consumiendo el servicio, usando curl o desde una aplicación que consuma servicios REST."

  parameters = [
    {
      "Parameters": "Key",
      "requerido": true,
      "Type": "String",
      "Description": "Es el nombre de usuario y está constituido por la palabra \"STAKOA\" seguido de 24 caracteres.",
    },
    {
      "Parameters": "Secret",
      "requerido": true,
      "Type": "String",
      "Description": "Es la clave secreta encriptada y segura para su uso."
    }
  ];

  jsonEjemplos =
    [{
      "title": "Body",
      "body":
      {
        "key": "STAKOAXXXXXXXXXXXXXXXXXXXXXXXX",
        "secret": "XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX"
      },
      "subtitle": "Sustituya el key y el secret por sus respectivas credenciales otorgadas al crear el apiKey."
    }
    ]

  curlEjemplo = {
    "title": "cURL",
    "bodyPrueba": "curl --location --request POST 'https://saludtools.qa.carecloud.com.co/integration/authenticate/apikey/v1/' --header 'Content-Type: application/json' --data-raw " + '\'{"key":"STAKOAXXXXXXXXXXXXXXXXXXXXXXXX", "secret":"XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX"}\'',
    "bodyProduccion": "curl --location --request POST 'https://saludtools.carecloud.com.co/integration/authenticate/apikey/v1/' --header 'Content-Type: application/json' --data-raw " + '\'{"key":"STAKOAXXXXXXXXXXXXXXXXXXXXXXXX", "secret":"XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX"}\'',
    "subtitle": "Sustituya el key y el secret por sus respectivas credenciales otorgadas al crear el ApiKey."
  }

  jsonResultados = [
    {
      "title": "OK 200",
      "body": {
        "access_token": "eyEbcDfiOaPOIzI1ZiSsIns7cCI6Ikpqwe.eyJFRTi5lsib2F1dGgyLXNlcnZlCFJdLCJ7c2VyX25hbEUiOEJQWasLT0FqRzFDcERFR3RQZFJLVnJiUlVjTzdnRlkiLCJzY78wZSI6WyJyb2xlX2FkbWluIiwicm9szv9zdXBlcmFkbWluIl0sImV4cCI6MTYyMTkwNjY5dewiYXV0aG9yaXRpZXMiOlsiQUNDRVNTX1NZU1RFTV8xIl0sImp0aSI6ImY3NzU0MTlkLTM1ODMtNDkyYS04YmYzLTM2MWRhMDc0OWQzNiIsImNsaWVudF9pZCI6IkNBUkVDre9VRCJ8.Y1HdxR-VQtLwe85xZ8wY0FsTNkjgoLQLxLGsnS3aJQl79JpzLDIiRnraFH6DzsjBsO5Wgxwe-qaZSAWNUkdC9QxP2tvuFPayuECIjmocsCN1teHoL3tRpefEluYJfz6KSb_p8XGBPnoX2CwvWEh-A7PE-ER21Pvd0TfdhIlMK_5TjF57OQj6w6LvojqsZRvGDgoTIPvogRlCd8gcvGsEIr6WT6Dq7xEg6tx81OIOzuhPYu3LAT2jZqzIytC6BMG7cjMrjoKmMFirVP4GmO2XXCTzDOb-gJhDLa-lPvztu6lVhuDQghg1oE0S2TZqUIkJ-3qja9q9OcgHQGgz5EIi-se",
        "token_type": "bearer",
        "refresh_token": "eyFRbGdcOiJSUzI1loIsInR5cCI6IkpXVpl7.eyJhdWQiOlsib2F587gyLXNlcnZlciJdLCJ1c2VyX25hbWUiOiQVBGTPA0FqRzFDcERFR3ÑOLKJLVnJiUlVjTzdnRlkiLCJzY29wZSI6WyJyb2xlX2FkbWluIiwic8akZV9zdXBlcmFkbWluIl0sImF0aSI6ImY3NzU0MTlkLTM1ODMtNDkyYS04YmYzDEM2MWRhÑLc0OWQzNiIsImV4cCI6MTYyMTkwñpY6NCwiYXV0aG9yaXRpZXMiOlsiAPEEQAAAX1NZU1RFTV8xIl0sImp0aSI6IjIwNDgyZDVmLWNmMDAtNDJkOS1hM2RjLWIxNWIzM2JlNWE4MSIsImNsaWVudF9pZCI6IkNBUkVDQD8ERTY5.JVolQw7BsqDnawjZo-B6myxUH0AIla5aMSVrINqWF8FiUFOHmXIVtMPrpyQht-A8h4D0bRX8wBuDpNsQIsxL8f0J5_HfG5_JUTZAsr13_--2YxbTTiS28_5sa3HAiSTv-plxSeorycdqSxeMIFKcI2tCrELQVbOI7lm2JQFITSdYIl1P_8w_PRysQFdV6QdDl9Yr-cWEGFPVZog3aYver-fHjGjyAZnHLmFyMLwG_gWRT1i2rAUNY2ONP_L90cGbjU6ACMOXvqcrn_RKkw7Gf-EDTvmpsJiagYsMgpHNLI0kIuzbUP2nNnht798_AD5hickYXjmP4HWq19v1Do-ERT",
        "expires_in": 43199,
        "scope": "role_admin role_superadmin",
        "jti": "f884415d-2828-789a-8bf3-452da0765d53"
      },
      "subtitle": "La solicitud se realizó con éxito y el Token de acceo ha sido creado.",
      "status": "200"
    },
    {
      "title": "400 Bad Request",
      "body": {
        "timestamp": "2021-05-24T15:23:13.727+00:00",
        "status": 400,
        "error": "Bad Request",
        "message": "",
        "path": "/integration/authenticate/apikey/v1/"
      },
      "subtitle": "La solicitud está mal formada, asegúrese de tener el body con todos los parámetros requeridos correctamente.",
      "status": "400"
    },
    {
      "title": "405 Method Not Allowed",
      "body": {
        "timestamp": "2021-05-24T15:23:13.727+00:00",
        "status": 400,
        "error": "Bad Request",
        "message": "",
        "path": "/integration/authenticate/apikey/v1/"
      },
      "subtitle": "El método usado no es compatible con el recurso enviado, recuerde que el método que utiliza este servicio es POST.",
      "status": "400"
    },
    {
      "title": "500 Internal Server Error",
      "body": {
        "timestamp": "2021-05-24T15:35:58.220+00:00",
    "status": 500,
    "error": "Internal Server Error",
    "message": "",
    "path": "/integration/authenticate/apikey/v1/"
       
      },
      "subtitle": "Key o Secret inválidos, rectifique sus credenciales.",
      "status": "500"
    }
  ]
  constructor() { }

  ngOnInit(): void {
  }

}
