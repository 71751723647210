import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-parameter',
  templateUrl: './parameter.component.html',
  styleUrls: ['./parameter.component.css']
})
export class ParameterComponent implements OnInit {

  title: string = "Parametros";
  subTitle: string = "Esta es la lista de parametros dentro de Saludtools (tipos de documentos, id de las eps, estados de la citas, id de las clínicas y género), que va a necesitar para consumir nuestros servicios"

  paragraphTitle: string = "¿Cómo consultar los parametros?";
  paragraph: string = "Cada parametro tiene un servicio 'GET' y  un EndPoint para consultarlo. Solo debe estar autenticado con su key y el secret para ver la respuesta de los servicios";


  curlEjemplo = {
    "title": "cURL",
    "bodyPrueba": "curl --location --request POST 'https://saludtools.qa.carecloud.com.co/integration/sync/event/v1/' --header 'Authorization: Bearer XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX' --header 'Content-Type: application/json' --data-raw" + '\'{ "eventType":"PATIENT", "actionType":"CREATE", "body": { "firstName":"José", "secondName": "Andres", "firstLastName" : "Gutierrez", "secondLastName" : "Gomez", "birthDate" : "1998-12-24", "gender" : 2, "documentType": 1, "documentNumber" : "123456789", "phone" : "123456789", "cellPhone" : "04258789555", "email" : "email@empresa.com", "eps" : 3 }}\'',
    "bodyProduccion": "curl --location --request POST 'https://saludtools.carecloud.com.co/integration/sync/event/v1/' --header 'Authorization: Bearer XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX' --header 'Content-Type: application/json' --data-raw" + '\'{ "eventType":"PATIENT", "actionType":"CREATE", "body": { "firstName":"José", "secondName": "Andres", "firstLastName" : "Gutierrez", "secondLastName" : "Gomez", "birthDate" : "1998-12-24", "gender" : 2, "documentType": 1, "documentNumber" : "123456789", "phone" : "123456789", "cellPhone" : "04258789555", "email" : "email@empresa.com", "eps" : 3 }}\'',
    "subtitle": "Después de Bearer sustituya  las X por su access-token generado y cambie los parámetros por los datos de su paciente a crear."
  }

  jsonResultados = [
    {
      "title": "OK 200",
      "body": 
        [
          {
              "id": 1,
              "name": "Masculino"
          },
          {
              "id": 2,
              "name": "Femenino"
          },
          {
              "id": 3,
              "name": "Indeterminado"
          }
      ]
      ,
      "subtitle": "La solicitud se realizó con éxito y ahora puede observar los tipos de géneros.",
      "status": "200"
    },
    {
      "title": "401 Unauthorized",
      "body": 
        "No tiene permisos para acceder al servidor",
      "subtitle": "Coloque un token de acceso válido.",
      "status": "401"
    }
    ]

  constructor() { }

  ngOnInit(): void {
  }

}
