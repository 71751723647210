import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-exams-results-read',
  templateUrl: './exams-results-read.component.html',
})
export class ExamsResultsReadComponent implements OnInit {

  title: string = "Generar link de descarga sobre un documento asociado a el examen";
  subTitle: string = "Genere un link único de descarga del documento especificado."

  paragraphTitle: string = "¿Cómo generar el link de descarga del documento?";
  paragraph: string = "Para buscar generar el link de descarga del documento, primero debe tener un token de acceso activo. Si su token se venció, o aun no ha generado un token de acceso. genere uno nuevo. Si ya está autenticado debe colocar en el body los parámetros que quiera filtrar. Puede elegir un parámetro o hacer una combinación de los parámetros que desee agregar para generar el link de descarga del documento.";

  parameters = [
    {
      "Parameters": "eventType",
      "requerido": true,
      "Type": "String",
      "Description": "Es el tipo de evento que se quiere invocar en este caso debe ser \"PATIENT_FILES\"."
    },
    {
      "Parameters": "actionType",
      "requerido": true,
      "Type": "String",
      "Description": "Es la acción que se quiere realizar para generar el link de descarga del documento debe ser \"SEARCH\""
    },
    {
      "Parameters": "id",
      "requerido": true,
      "Type": "Long",
      "Description": "Id del documento"
    },
  ]

  jsonEjemplos =
    [{
      "title": "Body",
      "body": {
        "eventType":"EXAMS_RESULTS",
        "actionType":"READ",
        "body": {
          "id": "30236"
        }
      },
      "subtitle": "Body para filtrar la cantidad de documentos de un paciente que cumpla con esos parámetros."
    },
    ]

  curlEjemplo = {
    "title": "cURL",
    "bodyPrueba": "curl --location --request POST 'https://saludtools.qa.carecloud.com.co/integration/sync/event/v1/' --header 'Authorization: Bearer XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX' --header 'Content-Type: application/json' --data-raw" + '\'{ "eventType":"PATIENT", "actionType":"SEARCH", "body": { "firstName":"Luis", "secondName": "Andres", "firstLastName" : "Gutierrez", "secondLastName" : "Gamez", "birthDate" : "1967-12-05", "gender" : 2, "documentType": 1, "documentNumber" : "177400432", "phone" : "3144780243", "cellPhone" : "3144780243", "email" : "lzarate@carecloud.com.co", "eps" : 3, "habeasData": false, "pageable" :{ "page" : 0, "size" : 20 } } }\'',
    "bodyProduccion": "curl --location --request POST 'https://saludtools.carecloud.com.co/integration/sync/event/v1/' --header 'Authorization: Bearer XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX' --header 'Content-Type: application/json' --data-raw" + '\'{ "eventType":"PATIENT", "actionType":"SEARCH", "body": { "firstName":"Luis", "secondName": "Andres", "firstLastName" : "Gutierrez", "secondLastName" : "Gamez", "birthDate" : "1967-12-05", "gender" : 2, "documentType": 1, "documentNumber" : "177400432", "phone" : "3144780243", "cellPhone" : "3144780243", "email" : "lzarate@carecloud.com.co", "eps" : 3, "habeasData": false, "pageable" :{ "page" : 0, "size" : 20 } } }\'',
    "subtitle": "Después de Bearer sustituya  las X por su access-token generado y cambie los parámetros por los datos a filtrar."
  }

  jsonResultados = [
    {
      "id": null,
      "code": 200,
      "message": "Se consulta la informacion de los documentos del paciente id: null",
      "eventId": "f4715d7d1fbc484a8bd99193b20a93ca",
      "body": {
        "url": "https://saludtools-v2-local.s3.amazonaws.com/local/patients/1/123456/files/b3b5f98a-b935-4d3c-b12a-20e083206a7a/img.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20220919T152755Z&X-Amz-SignedHeaders=host&X-Amz-Expires=1799&X-Amz-Credential=AKIAJJZ7RDWNMR5PQNAA%2F20220919%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Signature=25bba61647d3ba3c949592a2d360385ecd3efa8dd86a73acae53632af215f7c8"
      },
      "subtitle": "El valor de url es el link de descarga generado en base a los parámetros especificados",
    },
    {
      "title": "401 Unauthorized",
      "body":
        "No tiene permisos para acceder al servidor",
      "subtitle": "Coloque un token de acceso válido.",
      "status": "401"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
        "code": 412,
        "message": "No se ha enviado un tipo de evento valido",
        "eventId": "fe645e7a430c40bb946d221fb688351d",
        "body": null
      },
      "subtitle": "Se omitió el parámetro \"eventType\", para generar el link de descarga es obligatorio el parametro \"eventType\", por favor agrégalo al inicio del body como en el ejemplo.",
      "status": "212"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
        "code": 412,
        "message": "No se ha enviado un tipo de evento valido",
        "eventId": "48c76fbf91cf46a19ebd948c3d2a6d5e",
        "body": null
      },
      "subtitle": "Error en el valor del parámetro \"eventType\", para generar el link de descarga el valor permitido del \"eventType\"es \"PATIENT_FILES\".",
      "status": "412"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
        "code": 412,
        "message": "No se ha enviado una accion válida",
        "eventId": "dd24b96692b744e4b97b397e5a3c1dd9",
        "body": null
      },
      "subtitle": "Se omitió el parámetro \"actionType\", para generar el link de descarga es obligatorio el parámetro \"actionType\" que indica la accion de consultar sobre los documentos del paciente, por favor agrégalo al inicio del body como en el ejemplo.",
      "status": "412"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
        "code": 412,
        "message": "No se ha enviado una accion válida",
        "eventId": "a3d1a85dc6b649a2b4cb5ccc10d3e1c3",
        "body": null
      },
      "subtitle": "Error en el valor del parámetro \"actionType\", para buscar un paciente el valor permitido del parámetro \"actionType\" es \"READ\".",
      "status": "412"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
        "code": 412,
        "message": "Request method 'PUT' not supported",
        "eventId": "81cf71d0bf1b41f391ff690352cad890",
        "body": null
      },
      "subtitle": "El método usado no es compatible con el recurso enviado, recuerde que el método que utiliza este servicio es POST.",
      "status": "412"
    }]

  constructor() { }

  ngOnInit(): void {
  }

}
