import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-patient-file-create',
  templateUrl: './patient-file-create.component.html',
})
export class PatientFileCreateComponent implements OnInit {

  title: string = "Cargar Documentos";
  subTitle: string = "Cargue nuevos documentos del paciente en Saludtools."

  paragraphTitle: string = "¿Cómo cargar un nuevo documento?";
  paragraph: string = "Para cargar nuevos documentos, primero debe tener un Token de acceso activo. Si su Token se venció, o aún no ha generado un Token de acceso. Genere un nuevo Token y consuma el evento de cargar documentos.";

  nameEndPoint = "documents/";
  parameters = [
    {
      "Parameters": "eventType",
      "requerido": true,
      "Type": "String",
      "Description": "Es el tipo de evento que se quiere invocar en este caso debe ser \"PATIENT_FILES\"."
    },
    {
      "Parameters": "actionType",
      "requerido": true,
      "Type": "String",
      "Description": "Es la acción que se quiere realizar para cargar documentos debe ser \"CREATE\""
    },
    {
      "Parameters": "files",
      "requerido": true,
      "Type": "File",
      "Description": "Son los documentos del paciente que se quieren cargar, los formatos válidos son: \"PDF, TXT y PNG\"."
    },
    {
      "Parameters": "documentNumber",
      "requerido": true,
      "Type": "String",
      "Description": "Es el número de documento del paciente."
    },
    {
      "Parameters": "documentType",
      "requerido": true,
      "Type": "Number",
      "Description": "Es el tipo de documento del paciente."
    },
  ]

  titleExample = "Body / form-data";

  jsonExample = [
    {
      "key": "KEY",
      "value": "VALUE"
    },
    {
      "key": "eventType",
      "value": "PATIENT_FILES"
    },
    {
      "key": "actionType",
      "value": "CREATE"
    },
    {
      "key": "documentNumber",
      "value": "123456789"
    },
    {
      "key": "documentType",
      "value": "1"
    },
    {
      "key": "files",
      "value": "doc.pdf, doc.txt, img.png"
    },
  ]

  curlEjemplo = {
    "title": "cURL",
    "bodyPrueba": "curl --location --request POST 'https://saludtools.qa.carecloud.com.co/integration/sync/event/documents/v1/' --header 'Authorization: Bearer XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX' --header 'Content-Type: application/json' --data-raw" + '\'{ "eventType":"PATIENT", "actionType":"CREATE", "body": { "firstName":"José", "secondName": "Andres", "firstLastName" : "Gutierrez", "secondLastName" : "Gomez", "birthDate" : "1998-12-24", "gender" : 2, "documentType": 1, "documentNumber" : "123456789", "phone" : "123456789", "cellPhone" : "04258789555", "email" : "email@empresa.com", "eps" : 3, "habeasData": false }}\'',
    "bodyProduccion": "curl --location --request POST 'https://saludtools.carecloud.com.co/integration/sync/event/documents/v1/' --header 'Authorization: Bearer XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX' --header 'Content-Type: application/json' --data-raw" + '\'{ "eventType":"PATIENT", "actionType":"CREATE", "body": { "firstName":"José", "secondName": "Andres", "firstLastName" : "Gutierrez", "secondLastName" : "Gomez", "birthDate" : "1998-12-24", "gender" : 2, "documentType": 1, "documentNumber" : "123456789", "phone" : "123456789", "cellPhone" : "04258789555", "email" : "email@empresa.com", "eps" : 3, "habeasData": false }}\'',
    "subtitle": "Después de Bearer sustituya  las X por su access-token generado y cambie los parámetros por los datos de su paciente a cargar."
  }

  jsonResultados = [
    {
      "title": "OK 200",
      "body": {
          "id": 0,
          "code": 200,
          "message": "Se registran los documentos",
          "eventId": "4bd055b6ceb64815a307d35108938b31",
          "body": null
      },
      "subtitle": "La solicitud se realizó con éxito y los documentos han sido cargados.",
      "status": "200"
    },
    {
      "title": "401 Unauthorized",
      "body": 
        "No tiene permisos para acceder al servidor",
      "subtitle": "Coloque un token de acceso válido.",
      "status": "401"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
        "code": 412,
        "message": "No se ha enviado un tipo de evento valido",
        "eventId": "fe645e7a430c40bb946d221fb688351d",
        "body": null
      },
      "subtitle": "Se omitió el parámetro \"eventType\", para crear un paciente es obligatorio el parametro \"eventType\", por favor agrégalo al inicio del body como en el ejemplo.",
      "status": "412"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
        "code": 412,
        "message": "No se ha enviado un tipo de evento valido",
        "eventId": "48c76fbf91cf46a19ebd948c3d2a6d5e",
        "body": null
      },
      "subtitle": "Error en el valor del parámetro \"eventType\". Para cargar documentos el valor permitido del \"eventType\"es \"PATIENT_FILES\".",
      "status": "412"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
        "code": 412,
        "message": "No se ha enviado una accion válida",
        "eventId": "dd24b96692b744e4b97b397e5a3c1dd9",
        "body": null
      },
      "subtitle": "Se omitió el parámetro \"actionType\", para cargar documentos es obligatorio el parámetro \"actionType\" que indica la acción de crear el paciente, por favor agrégalo al inicio del body como en el ejemplo.",
      "status": "412"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
        "code": 412,
        "message": "No se ha enviado una accion válida",
        "eventId": "a3d1a85dc6b649a2b4cb5ccc10d3e1c3",
        "body": null
      },
      "subtitle": "Error en el valor del parámetro \"actionType\". Para cargar documentos el valor permitido del parámetro \"actionType\" es \"CREATE\".",
      "status": "412"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
        "code": 412,
        "message": "Request method 'PUT' not supported",
        "eventId": "81cf71d0bf1b41f391ff690352cad890",
        "body": null
      },
      "subtitle": "El método usado no es compatible con el recurso enviado, recuerde que el método que utiliza este servicio es POST.",
      "status": "412"
    }]
    


  constructor() { }

  ngOnInit(): void {
  }

}
