<ul class="nav nav-tabs" id="classtab">
    <li class="active"><a class="active" data-toggle="tab" *ngIf="show">Prueba</a></li>
    <li class="active"><a class="active" data-toggle="tab" *ngIf="!show">Producción</a></li>
</ul>
<div class="tab-content" id="classcont">
    <div class="tab-pane fade in active show">
        <p *ngIf="show"><span class="pg-type type-post">POST</span>https://saludtools.qa.carecloud.com.co/integration/sync/event/{{isFormData && endPoint }}v1/</p>
        <p *ngIf="!show"><span class="pg-type type-post">POST</span>https://saludtools.carecloud.com.co/integration/sync/event/{{isFormData && endPoint }}v1/</p>
    </div>
</div>
<br>
