import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-patient-update',
  templateUrl: './patient-update.component.html',
  styleUrls: ['./patient-update.component.css']
})
export class PatientUpdateComponent implements OnInit {

  title: string = "Actualizar Paciente";
  subTitle: string = "Actualice un paciente que ya se encuentra en la base de datos."

  paragraphTitle: string = "¿Cómo actualizar un paciente?";
  paragraph: string = "Para actualizar un nuevo paciente, primero debe tener un Token de acceso activo. Si su Token se venció o aún no ha generado un Token de acceso. Genere un nuevo token y consuma el evento de actualizar paciente.";

  parameters = [
    {
      "Parameters": "eventType",
      "requerido": true,
      "Type": "String",
      "Description": "Es el tipo de evento que se quiere invocar en este caso debe ser \"PATIENT\"."
    },
    {
      "Parameters": "actionType",
      "requerido": true,
      "Type": "String",
      "Description": "Es la acción que se quiere realizar para actualizar un paciente debe ser \"UPDATE\""
    },
    {
      "Parameters": "firstName",
      "requerido": false,
      "Type": "String",
      "Description": "Primer nombre del paciente"
    },
    {
      "Parameters": "secondName",
      "requerido": false,
      "Type": "String",
      "Description": "Segundo nombre del paciente"
    },
    {
      "Parameters": "firstLastName",
      "requerido": false,
      "Type": "String",
      "Description": "Primer apellido del paciente"
    },
    {
      "Parameters": "secondLastName",
      "requerido": false,
      "Type": "String",
      "Description": "Segundo apellido del paciente"
    },
    {
      "Parameters": "birthDate",
      "requerido": false,
      "Type": "LocalDate",
      "Description": "Fecha de nacimiento del paciente Formato yyyy-mm-dd."
    },
    {
      "Parameters": "gender",
      "requerido": false,
      "Type": "Long",
      "Description": "Id de género del paciente"
    },
    {
      "Parameters": "documentType",
      "requerido": true,
      "Type": "Long",
      "Description": "Id del tipo de documento de identidad del paciente"
    },
    {
      "Parameters": "documentNumber",
      "requerido": true,
      "Type": "String",
      "Description": "Número del documento de identidad del paciente"
    },
    {
      "Parameters": "phone",
      "requerido": false,
      "Type": "String",
      "Description": "Número de teléfono del paciente"
    },
    {
      "Parameters": "cellPhone",
      "requerido": false,
      "Type": "String",
      "Description": "Número de celular del paciente"
    },
    {
      "Parameters": "email",
      "requerido": false,
      "Type": "String",
      "Description": "Correo electrónico del paciente"
    },
    {
      "Parameters": "eps",
      "requerido": false,
      "Type": "Long",
      "Description": "Eps registrada del paciente"
    },
    {
      "Parameters": "habeasData",
      "requerido": false,
      "Type": "Boolean",
      "Description": "Confirmación del paciente de recibir comunicación (Habeas Data)"
    }
  ]

  jsonEjemplos =
    [{
      "title": "Body",
      "body":
      {
        "eventType": "PATIENT",
        "actionType": "UPDATE",
        "body": {
          "firstName": "José",
          "secondName": "Andres",
          "firstLastName": "Gutierrez",
          "secondLastName": "Gomez",
          "birthDate": "1998-12-24",
          "gender": 2,
          "documentType": 1,
          "documentNumber": "123456789",
          "phone": "123456789",
          "cellPhone": "04258789555",
          "email": "email@empresa.com",
          "eps": 3,
          "habeasData": false
        }
      },
      "subtitle": "Body para actualizar un paciente. Sustituya los campos para actualizarlos en base de datos."
    }
    ]

  curlEjemplo = {
    "title": "cURL",
    "bodyPrueba": "curl --location --request POST 'https://saludtools.qa.carecloud.com.co/integration/sync/event/v1/' --header 'Authorization: Bearer XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX' --header 'Content-Type: application/json' --data-raw " + '\'{ "eventType":"PATIENT", "actionType":"UPDATE", "body": { "firstName":"José", "secondName": "Andres", "firstLastName" : "Gutierrez", "secondLastName" : "Gomez", "birthDate" : "1998-12-24", "gender" : 2, "documentType": 1, "documentNumber" : "123456789", "phone" : "123456789", "cellPhone" : "04258789555", "email" : "email@empresa.com", "eps" : 3, "habeasData": false }}\'',
    "bodyProduccion": "curl --location --request POST 'https://saludtools.carecloud.com.co/integration/sync/event/v1/' --header 'Authorization: Bearer XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX' --header 'Content-Type: application/json' --data-raw " + '\'{ "eventType":"PATIENT", "actionType":"UPDATE", "body": { "firstName":"José", "secondName": "Andres", "firstLastName" : "Gutierrez", "secondLastName" : "Gomez", "birthDate" : "1998-12-24", "gender" : 2, "documentType": 1, "documentNumber" : "123456789", "phone" : "123456789", "cellPhone" : "04258789555", "email" : "email@empresa.com", "eps" : 3, "habeasData": false }}\'',
    "subtitle": "Después de Bearer sustituya  las X por su access-token generado y cambie los parámetros por los datos de su paciente para actualizarlos."
  }

  jsonResultados = [
    {
      "title": "OK 200",
      "body": {
        "id": 215178,
        "code": 200,
        "message": "Se actualiza el paciente id: 215178",
        "eventId": "20c4d7d311004c2fb76b0d99bcc081c9",
        "body": null
      },
      "subtitle": "La solicitud se realizó con éxito y el paciente ha sido actualizado.",
      "status": "200"
    },
    {
      "title": "401 Unauthorized",
      "body":
        "No tiene permisos para acceder al servidor",
      "subtitle": "Coloque un Token de acceso válido.",
      "status": "401"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
        "code": 412,
        "message": "No se ha enviado un tipo de evento valido",
        "eventId": "fe645e7a430c40bb946d221fb688351d",
        "body": null
      },
      "subtitle": "Se omitió el parámetro \"eventType\", para actualizar un paciente es obligatorio el parametro \"eventType\", por favor agrégalo al inicio del body como en el ejemplo.",
      "status": "212"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
        "code": 412,
        "message": "No se ha enviado un tipo de evento valido",
        "eventId": "48c76fbf91cf46a19ebd948c3d2a6d5e",
        "body": null
      },
      "subtitle": "Error en el valor del parámetro \"eventType\". Para actualizar un paciente el valor permitido del \"eventType\"es \"PATIENT\".",
      "status": "412"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
        "code": 412,
        "message": "No se ha enviado una accion válida",
        "eventId": "dd24b96692b744e4b97b397e5a3c1dd9",
        "body": null
      },
      "subtitle": "Se omitió el parámetro \"actionType\", para actualizar un paciente es obligatorio el parámetro \"actionType\" que indica la acción de actualizar el paciente, por favor agrégalo al inicio del body como en el ejemplo.",
      "status": "412"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
        "code": 412,
        "message": "No se ha enviado una accion válida",
        "eventId": "c404b4e955e24985b7031cb95dc849b9",
        "body": null
      },
      "subtitle": "Error en el valor del parámetro \"actionType\". Para actualizar un paciente el valor permitido del parámetro \"actionType\" es \"UPDATE\".",
      "status": "412"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
        "code": 412,
        "message": "Request method 'PUT' not supported",
        "eventId": "81cf71d0bf1b41f391ff690352cad890",
        "body": null
      },
      "subtitle": "El método usado no es compatible con el recurso enviado, recuerde que el método que utiliza este servicio es POST.",
      "status": "412"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
        "code": 412,
        "message": "Para los datos enviados, no se ha encontrado un paciente en nuestra base de datos.",
        "eventId": "dd514fa470de46d9957bb191d216fe56",
        "body": null
      },
      "subtitle": "El usuario no existe en la base de datos o hay un error en los datos suministrados.",
      "status": "412"
    }]

  constructor() { }

  ngOnInit(): void {
  }

}
