<div id="layoutSidenav">
    <div id="layoutSidenav_content">
        <br>
        <div class="container">
            <app-main-title [title]="title" [subTitle]="subTitle"></app-main-title>
            <app-main-paragraph [paragraphTitle]="paragraphTitle" [paragraph]="paragraph"></app-main-paragraph>
         
            <h4>Los Antecedentes Personales del paciente contienen los siguientes atributos</h4>
            <table class="table table-bordered">
                <thead class="thead-dark">
                    <tr style="padding: 0px !important;">
                        <th scope="col">Nombre</th>
                        <th scope="col">Tipo</th>
                        <th scope="col">Descripci&oacute;n</th>
                        <th scope="col">Ejemplo</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th scope="row">documentType</th>
                        <td>Long</td>
                        <td>
                            Tipo de documento del Paciente:  
                                <ul>
                                    <li>1 = Cédula ciudadanía</li>
                                    <li>2 = Cédula de extranjería</li>
                                    <li>3 = Carné diplomático</li>
                                    <app-parameters-modal-button [type]="'documents'"></app-parameters-modal-button>
                                </ul>
                        </td>
                        <td>2</td> 
                    </tr>
                    <tr>
                        <th scope="row">documentNumber</th>
                        <td>String</td>
                        <td>Número de documento del paciente</td>
                        <td>"123456789"</td>     
                    </tr>
                    <tr>
                        <th scope="row">encounterCommonInfo</th>
                        <td>Long</td>
                        <td>id del encuentro común si el antecedente está relacionado a una atención</td>
                        <td>1</td>
                    </tr>
                    <tr>
                        <th scope="row">friendlyNameId</th>
                        <td>Long</td>
                        <td>id del nombre amigable del antecedente (solo para antecedentes personales dentro de una atención)</td>
                        <td>1</td>
                    </tr> 
                    <tr>
                        <th scope="row">othersDiagnosticText</th>
                        <td>String</td>
                        <td>texto de otros global al grupo de antecedente (solo para antecedentes personales dentro de una atención)</td>
                        <td>"Esto es un antecedente"</td>
                    </tr> 
                    <tr>
                        <th scope="row">othersDiagnosticTextGroupId</th>
                        <td>Long</td>
                        <td>id grupo de antecedentes necesario si se va a registrar othersDiagnosticText (solo para antecedentes personales dentro de una atención)</td>
                        <td>1</td>
                    </tr> 
                    <tr>
                        <th scope="row">diagnosticType</th>
                        <td>String</td>
                        <td>Código del CIE10</td>
                        <td>"A000"</td> 
                    </tr> 
                    <tr>
                        <th scope="row">diagnosticText</th>
                        <td>String</td>
                        <td>Texto cuando el antecedente es genérico y se crea fuera de una atención</td>
                        <td>"esto es un diagnóstico"</td>
                    </tr> 
                    <tr>
                        <th scope="row">diagnosisDate</th>
                        <td>LocalDate</td>
                        <td>Fecha del diagnóstico ("yyyy-mm-dd")</td>
                        <td>"2021-05-22"</td>
                    </tr>
                    <tr>
                        <th scope="row">antecedentStateType</th>
                        <td>Long</td>
                        <td>id de la Clasificación del diagnóstico</td>
                        <td>1</td>
                    </tr>
                    <tr>
                        <th scope="row">antecedentState</th>
                        <td>Long</td>
                        <td>id del tipo de diagnóstico</td>
                        <td>1</td>
                    </tr>
                    <tr>
                        <th scope="row">comments</th>
                        <td>String</td>
                        <td>comentario de un antecedente personal</td>
                        <td>"esto es un comentario"</td>
                    </tr>
                </tbody>
            </table>
            <hr>
            <app-footer></app-footer>
        </div>
    </div>
</div>
