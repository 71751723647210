import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-patient-inability-work-read',
  templateUrl: './patient-inability-work-read.component.html',
  styleUrls: ['./patient-inability-work-read.component.css']
})
export class PatientInabilityWorkReadComponent implements OnInit {

  title: string = "Leer Prescripción de incapacidad del paciente";
  subTitle: string = "Lea los datos de la prescripción de incapacidad de un paciente indicado."

  paragraphTitle: string = "¿Cómo leer la prescripción de incapacidad del paciente?";
  paragraph: string = "Para leer una nueva prescripción de incapacidad, primero debe tener un Token de acceso activo. Si su Token se venció o aún no ha generado un Token de acceso. Genere un nuevo Token y consuma el evento de leer prescripción de incapacidad.";

  parameters = [
    {
      "Parameters": "eventType",
      "requerido": true,
      "Type": "String",
      "Description": "Es el tipo de evento que se quiere invocar en este caso debe ser \"INABILITYWORK\"."
    },
    {
      "Parameters": "actionType",
      "requerido": true,
      "Type": "String",
      "Description": "Es la acción que se quiere realizar para leer un paciente debe ser \"READ\""
    },
    {
      "Parameters": "id",
      "requerido": true,
      "Type": "Long",
      "Description": "Id de la incapacidad"
    }
  ]

  jsonEjemplos =
    [{
      "title": "Body",
      "body":
      {
        "eventType": "INABILITYWORK",
        "actionType": "READ",
        "body": {
          "id": 3447
        }
      },
      "subtitle": "Sustituya el parámetro \"id\" por el dato de la incapacidad a leer."
    }
    ]

  curlEjemplo = {
    "title": "cURL",
    "bodyPrueba": "curl --location --request POST 'https://saludtools.qa.carecloud.com.co/integration/sync/event/v1/' --header 'Authorization: Bearer ' --header 'Content-Type: application/json' --data-raw " + '\'{ "eventType":"INABILITYWORK", "actionType":"READ", "body": { "documentType": 1, "documentNumber" : "123456789" } }\'',
    "bodyProduccion": "curl --location --request POST 'https://saludtools.carecloud.com.co/integration/sync/event/v1/' --header 'Authorization: Bearer ' --header 'Content-Type: application/json' --data-raw " + '\'{ "eventType":"INABILITYWORK", "actionType":"READ", "body": { "documentType": 1, "documentNumber" : "123456789" } }\'',
    "subtitle": "Después de Bearer sustituya  las X por su access-token generado y cambie los parámetros por los datos del paciente que se va a leer."
  }

  jsonResultados = [
    {
      "title": "OK 200",
      "body": {
        "id": null,
        "code": 200,
        "message": "Se consulta la informacion de  id: null",
        "eventId": "127a4e272e894610ba2e3ee453be1d64",
        "body": {
          "id": null,
          "pageable": null,
          "documentType": null,
          "documentNumber": null,
          "request": null,
          "response": {
              "id": 3440,
              "diagnosticCIE10": "COLERA DEBIDO A VIBRIO CHOLERAE O1, BIOTIPO CHOLERAE",
              "consultationExternalCause": "Accidente de trabajo",
              "treatmentAreaOfApplication": "Trabajo Social",
              "reoccurenceType": "Única",
              "startInabilityDate": "2022-09-27T00:00:00.000+00:00",
              "endInabilityDate": "2022-09-27T00:00:00.000+00:00",
              "comments": "Dolores abdominales..."
          }
      }
      },
      "subtitle": "La solicitud se realizó con éxito y se ha leído la incapacidad exitosamente.",
      "status": "200"
    },
    {
      "title": "401 Unauthorized",
      "body":
        "No tiene permisos para acceder al servidor",
      "subtitle": "Coloque un token de acceso válido.",
      "status": "401"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
        "code": 412,
        "message": "No se ha enviado un tipo de evento valido",
        "eventId": "fe645e7a430c40bb946d221fb688351d",
        "body": null
      },
      "subtitle": "Se omitió el parámetro \"eventType\", para leer un paciente es obligatorio el parametro \"eventType\", por favor agrégalo al inicio del body como en el ejemplo.",
      "status": "412"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
        "code": 412,
        "message": "No se ha enviado un tipo de evento valido",
        "eventId": "ea23adb2dc3b48ac8ebf6c530c436458",
        "body": null

      },
      "subtitle": "Error en el valor del parámetro \"eventType\". Para leer un paciente el valor permitido del \"eventType\"es \"INABILITYWORK\".",
      "status": "412"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
        "code": 412,
        "message": "No se ha enviado una accion válida",
        "eventId": "dd24b96692b744e4b97b397e5a3c1dd9",
        "body": null
      },
      "subtitle": "Se omitió el parámetro \"actionType\", para leer un paciente es obligatorio el parámetro \"actionType\" que indica la acción de leer el paciente, por favor agrégalo al inicio del body como en el ejemplo.",
      "status": "412"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
        "code": 412,
        "message": "No se ha enviado una accion válida",
        "eventId": "5ad3febd75bc425399044a8a8df366be",
        "body": null
      },
      "subtitle": "Error en el valor del parámetro \"actionType\". Para leer un paciente el valor permitido del parámetro \"actionType\" es \"READ\".",
      "status": "412"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
        "code": 412,
        "message": "Request method 'PUT' not supported",
        "eventId": "81cf71d0bf1b41f391ff690352cad890",
        "body": null
      },
      "subtitle": "El método usado no es compatible con el recurso enviado, recuerde que el método que utiliza este servicio es POST.",
      "status": "412"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
        "code": 412,
        "message": "Para los datos enviados como filtros, no se ha encontrado un paciente en nuestra base de datos, para consultar un paciente envie el identificador o el tipo y numero de documento",
        "eventId": "662878c34dea4b9cada70f105479de07",
        "body": null
      },
      "subtitle": "El usuario no existe en la base de datos o hay un error en los datos suministrados.",
      "status": "412"
    }]

  constructor() { }

  ngOnInit(): void {
  }

}
