import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-medicine-create',
  templateUrl: './medicine-create.component.html',
  styleUrls: ['./medicine-create.component.css']
})
export class MedicineCreateComponent implements OnInit {

  title: string = "Crear Prescripción de Medicamentos";
  subTitle: string = "Cree una nueva prescripción y asignela a un paciente."

  paragraphTitle: string = "¿Cómo crear una nueva prescripción de medicamentos?";
  paragraph: string = "Para crear una nueva prescripción de medicamentos, primero debe tener un Token de acceso activo. Si su Token se venció, o aún no ha generado un Token de acceso. Genere un nuevo Token y consuma el evento de crear prescripción médica.";

  parameters = [
    {
      "Parameters": "eventType",
      "requerido": true,
      "Type": "String",
      "Description": "Es el tipo de evento que se quiere invocar en este caso debe ser \"MEDICINE\"."
    },
    {
      "Parameters": "actionType",
      "requerido": true,
      "Type": "String",
      "Description": "Es la acción que se quiere realizar para crear un paciente debe ser \"CREATE\""
    },
    {
      "Parameters": "doctorDocumentType",
      "requerido": true,
      "Type": "Long",
      "Description": "Tipo de documento del doctor quien hace la prescripción"
    },
    {
      "Parameters": "doctorDocumentNumber",
      "requerido": true,
      "Type": "Long",
      "Description": "Número de documento del doctor quien hace la prescripción"
    },
    {
      "Parameters": "encounterId",
      "requerido": false,
      "Type": "Long",
      "Description": "Id de la atención si la prescripcion esta vinculada a una atención médica"
    },
    {
      "Parameters": "patientId",
      "requerido": true,
      "Type": "Long",
      "Description": "Id del paciente"
    },
    {
      "Parameters": "improved",
      "requerido": false,
      "Type": "Boolean",
      "Description": ""
    },
    {
      "Parameters": "prescriptedMedicine",
      "requerido": true,
      "Type": "List<PatientMedicinePrescriptionBasicResponseDTO>",
      "Description": "Lista de medicamentos recetados en la prescripción"
    },
    {
      "Parameters": "medicinePrescriptionType",
      "requerido": true,
      "Type": "String",
      "Description": "Tipo de medicamento a prescribir"
    },
    {
      "Parameters": "comercialProductName",
      "requerido": false,
      "Type": "String",
      "Description": "Nombre comercial del medicamento."
    },
    {
      "Parameters": "comercialProductId",
      "requerido": true,
      "Type": "Long",
      "Description": "Id del nombre comercial del medicamento. (REQUERIDO si es de tipo COMMERCIAL_PRESENTATION)"
    },
    {
      "Parameters": "magistralPreparationFormula",
      "requerido": true,
      "Type": "String",
      "Description": "Nombre de la fórmula magistral (REQUERIDO si es de tipo MASTER_FORMULA)"
    },
    {
      "Parameters": "principleActiveType",
      "requerido": true,
      "Type": "Long",
      "Description": "Id del principio Activo del medicamento a recetar (REQUERIDO si es de tipo ACTIVE_PRINCIPLE)"
    },
    {
      "Parameters": "atcConcentrationId",
      "requerido": false,
      "Type": "Long",
      "Description": "Id de la concentracion del principio Activo del medicamento a recetar (si es de tipo ACTIVE_PRINCIPLE)"
    },
    {
      "Parameters": "intakemethod",
      "requerido": true,
      "Type": "Long",
      "Description": "Id del método de administración del medicamento"
    },
    {
      "Parameters": "quantityImproved",
      "requerido": false,
      "Type": "Double",
      "Description": "Dosis a administrar"
    },
    {
      "Parameters": "quantityUnit",
      "requerido": false,
      "Type": "String",
      "Description": "Unidad de la Dosis a administrar"
    },
    {
      "Parameters": "frecuencyImproved",
      "requerido": false,
      "Type": "Double",
      "Description": "Frecuencia de la dosis a administrar"
    },
    {
      "Parameters": "frequencyUnit",
      "requerido": false,
      "Type": "Long",
      "Description": "Id de la unidad de la frecuencia"
    },
    {
      "Parameters": "durationImproved",
      "requerido": false,
      "Type": "Double",
      "Description": "Duración del tratamiento"
    },
    {
      "Parameters": "durationUnit",
      "requerido": false,
      "Type": "Long",
      "Description": "Id de la unidad de duración del tratamiento"
    },
    {
      "Parameters": "totalQuantity",
      "requerido": false,
      "Type": "String",
      "Description": "Es la cantidad de despacho del medicamento (si se deja en blanco y todas las unidades estan llenas se calcula automaticamente)"
    },
    {
      "Parameters": "indicationsTaking",
      "requerido": false,
      "Type": "String",
      "Description": "Es la indicación de administración (si se deja en blanco se calcula automaticamente)"
    },
    {
      "Parameters": "comments",
      "requerido": false,
      "Type": "String",
      "Description": "Comentarios del doctor"
    },
    {
      "Parameters": "pharmaForm",
      "requerido": false,
      "Type": "String",
      "Description": "Forma farmaceútica"
    }
  ]

  jsonEjemplos =
    [{
      "title": "Body",
      "body":
      {
        "eventType":"MEDICINE",
        "actionType":"CREATE",
        "body": {
            "doctorDocumentType": 1,
            "doctorDocumentNumber": "11111" ,
            "encounterId": "",
            "patientId" : "215161",
            "improved" : "true",
            "prescriptedMedicine" : [{
                "medicinePrescriptionType": "MASTER_FORMULA",
                "comercialProductName": "COZA",
                "comercialProductId": 81725,
                "magistralPreparationFormula": "pastillitas3 magistral",
                "principleActiveType": "347",
                "atcConcentrationId": "13250",
                "intakemethod": "5",
                "quantityImproved": "1",
                "quantityUnit": "pastillitas1",
                "frecuencyImproved": "12",
                "frequencyUnit": "1",
                "durationImproved": "1",
                "durationUnit": "2",
                "totalQuantity": "",
                "indicationsTaking": "",
                "comments": "con observaciones1",
                "pharmaForm": "con forma1"
            },
            {
                "medicinePrescriptionType": "ACTIVE_PRINCIPLE",
                "comercialProductName": "COZAAR XQ 50 MG/5MG TABLETAS RECUBIERTAS / 5 mg - TABLETA",
                "comercialProductId": 81725,
                "magistralPreparationFormula": "pastillitas3 magistral",
                "principleActiveType": "347",
                "atcConcentrationId": "13250",
                "intakemethod": "5",
                "quantityImproved": "1",
                "quantityUnit": "pastillitas2",
                "frecuencyImproved": "12",
                "frequencyUnit": "1",
                "durationImproved": "1",
                "durationUnit": "2",
                "totalQuantity": "",
                "indicationsTaking": "",
                "comments": "con observaciones2",
                "pharmaForm": "con forma2"
            },
             {
                "medicinePrescriptionType": "COMMERCIAL_PRESENTATION",
                "comercialProductName": "CO",
                "comercialProductId": 81725,
                "magistralPreparationFormula": "pastillitas3 magistral",
                "principleActiveType": "347",
                "atcConcentrationId": "13250",
                "intakemethod": "5",
                "quantityImproved": "1",
                "quantityUnit": "pastillitas3",
                "frecuencyImproved": "12",
                "frequencyUnit": "1",
                "durationImproved": "1",
                "durationUnit": "2",
                "totalQuantity": "",
                "indicationsTaking": "",
                "comments": "con observaciones3",
                "pharmaForm": "con forma3"
            }]
          
        }},
      "subtitle": "Body de una prescripción de medicamentos, con tres medicamentos recetados"
    }
    ]

  curlEjemplo = {
    "title": "cURL",
    "bodyPrueba": "curl --location --request POST 'https://saludtools.qa.carecloud.com.co/integration/sync/event/v1/' --header 'Authorization: Bearer XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX' --header 'Content-Type: application/json' --data-raw" + '\'{ "eventType":"MEDICINE", "actionType":"CREATE", "body": { "doctorDocumentType": 1, "doctorDocumentNumber": "11111", "encounterId": "", "patientId" : "215161", "improved" : "true" "prescriptedMedicine" : [{ "medicinePrescriptionType": "MASTER_FORMULA", "comercialProductName": "", "comercialProductId": 81725, "magistralPreparationFormula": "escribir formula magistral", "principleActiveType": "", "atcConcentrationId": "1", "intakemethod": "5", "quantityImproved": "1", "quantityUnit": "Gotas", "frecuencyImproved": "12", "frequencyUnit": "1", "durationImproved": "1", "durationUnit": "2", "totalQuantity": "", "indicationsTaking": "", "comments": "observaciones del médico", "pharmaForm": "forma farmaceútica" }]}}\'',
    "bodyProduccion": "curl --location --request POST 'https://saludtools.carecloud.com.co/integration/sync/event/v1/' --header 'Authorization: Bearer XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX' --header 'Content-Type: application/json' --data-raw" + '\'{"eventType":"MEDICINE", "actionType":"CREATE", "body": { "doctorDocumentType": 1, "doctorDocumentNumber": "11111", "encounterId": "", "patientId" : "215161", "improved" : "true" "prescriptedMedicine" : [{ "medicinePrescriptionType": "MASTER_FORMULA", "comercialProductName": "", "comercialProductId": 81725, "magistralPreparationFormula": "escribir formula magistral", "principleActiveType": "", "atcConcentrationId": "1", "intakemethod": "5", "quantityImproved": "1", "quantityUnit": "Gotas", "frecuencyImproved": "12", "frequencyUnit": "1", "durationImproved": "1", "durationUnit": "2", "totalQuantity": "", "indicationsTaking": "", "comments": "observaciones del médico", "pharmaForm": "forma farmaceútica" }]}}\'',
    "subtitle": "Después de Bearer sustituya  las X por su access-token generado y cambie los parámetros por los datos de su prescripción a crear."
  }

  jsonResultados = [
    {
      "title": "OK 200",
      "body": {
        "id": 81620,
        "code": 200,
        "message": "Se registra  id: 81620",
        "eventId": "c9e3feb7bc574e8da39e202eedbdae9c",
        "body": null
      },
      "subtitle": "La solicitud se realizó con éxito y la prescripción de medicamentos ha sido creada.",
      "status": "200"
    },
    {
      "title": "401 Unauthorized",
      "body": 
        "No tiene permisos para acceder al servidor",
      "subtitle": "Coloque un token de acceso válido.",
      "status": "401"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
    "code": 412,
    "message": "No se ha enviado un tipo de evento valido",
    "eventId": "fe645e7a430c40bb946d221fb688351d",
    "body": null
      },
      "subtitle": "Se omitió el parámetro \"eventType\", para crear una prescripción es obligatorio el parametro \"eventType\", por favor agrégalo al inicio del body como en el ejemplo.",
      "status": "212"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
        "code": 400,
        "message": "JSON parse error: Cannot deserialize value of type `com.saludtools.integration.enumeration.EventTypeEnum` from String \"MEDICINEa\": not one of the values accepted for Enum class: [MEDICINE, PATIENT, APPOINTMENT]; nested exception is com.fasterxml.jackson.databind.exc.InvalidFormatException: Cannot deserialize value of type `com.saludtools.integration.enumeration.EventTypeEnum` from String \"PATIENTa\": not one of the values accepted for Enum class: [MEDICINE, PATIENT, APPOINTMENT]\n at [Source: (PushbackInputStream); line: 2, column: 17] (through reference chain: com.saludtools.integration.entities.EventIn[\"eventType\"])",
        "eventId": "48c76fbf91cf46a19ebd948c3d2a6d5e",
        "body": null
      },
      "subtitle": "Error en el valor del parámetro \"eventType\". Para crear una prescripción el valor permitido del \"eventType\"es \"MEDICINE\".",
      "status": "400"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
    "code": 400,
    "message": "No se ha enviado una accion vÃ¡lida",
    "eventId": "dd24b96692b744e4b97b397e5a3c1dd9",
    "body": null
      },
      "subtitle": "Se omitió el parámetro \"actionType\", para crear una prescripción es obligatorio el parámetro \"actionType\" que indica la acción de crear la prescripción de medicamentos, por favor agrégalo al inicio del body como en el ejemplo.",
      "status": "400"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
        "code": 400,
        "message": "JSON parse error: Cannot deserialize value of type `com.saludtools.integration.enumeration.ActionTypeEnum` from String \"CREATEA\": not one of the values accepted for Enum class: [SEARCH, UPDATE, DELETE, CREATE, READ]; nested exception is com.fasterxml.jackson.databind.exc.InvalidFormatException: Cannot deserialize value of type `com.saludtools.integration.enumeration.ActionTypeEnum` from String \"CREATEA\": not one of the values accepted for Enum class: [SEARCH, UPDATE, DELETE, CREATE, READ]\n at [Source: (PushbackInputStream); line: 3, column: 18] (through reference chain: com.saludtools.integration.entities.EventIn[\"actionType\"])",
        "eventId": "a3d1a85dc6b649a2b4cb5ccc10d3e1c3",
        "body": null
      },
      "subtitle": "Error en el valor del parámetro \"actionType\". Para crear una prescripción de medicamentos el valor permitido del parámetro \"actionType\" es \"CREATE\".",
      "status": "400"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
    "code": 400,
    "message": "Request method 'PUT' not supported",
    "eventId": "81cf71d0bf1b41f391ff690352cad890",
    "body": null
      },
      "subtitle": "El método usado no es compatible con el recurso enviado, recuerde que el método que utiliza este servicio es POST.",
      "status": "400"
    }]

  constructor() { }

  ngOnInit(): void {
  }

}
