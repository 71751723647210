<div id="layoutSidenav">
    <div id="layoutSidenav_content">
        <br>
        <div class="container">
            <app-main-title [title]="title" [subTitle]="subTitle"></app-main-title>
            <app-main-paragraph [paragraphTitle]="paragraphTitle" [paragraph]="paragraph"></app-main-paragraph>
            <div *ngFor="let imgCard of imgCard;">
                <app-img-card [imgCardTitle]="imgCard.imgCardTitle" [imgCardhref]="imgCard.imgCardhref"
                    [imgCardSub]="imgCard.imgCardSub"></app-img-card>
                <br>
            </div>
            <hr>
            <app-footer></app-footer>
        </div>
    </div>
</div>