import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-appointment-delete',
  templateUrl: './appointment-delete.component.html',
  styleUrls: ['./appointment-delete.component.css']
})
export class AppointmentDeleteComponent implements OnInit {

  title: string = "Borrar una Cita";
  subTitle: string = "Borre los datos de una cita específica."

  paragraphTitle: string = "¿Cómo borrar una cita?";
  paragraph: string = "Para borrar una cita ya registrada en la base de datos de saludtools, primero debe tener un token de acceso activo. Si su token se venció, o aún no ha generado un token de acceso. genere un token nuevo. Si ya tiene el token de acceso solo tiene que saber el Id de la cita para borrarla.";

  parameters = [
    {
      "Parameters": "eventType",
      "requerido": true,
      "Type": "String",
      "Description": "Es el tipo de evento que se quiere invocar en este caso debe ser \"APPOINTMENT\"."
    },
    {
      "Parameters": "actionType",
      "requerido": true,
      "Type": "String",
      "Description": "Es la acción que se quiere realizar para borrar una cita debe ser \"DELETE\""
    },
    {
      "Parameters": "id",
      "requerido": true,
      "Type": "String",
      "Description": "Id de la cita a buscar"
    }

  ]

  jsonEjemplos =
    [{
      "title": "Body",
      "body":
      {
        "eventType": "APPOINTMENT",
        "actionType": "DELETE",
        "body": {
          "id": "3295180"
        }
      },
      "subtitle": "Body para borrar una cita."
    }
    ]

  curlEjemplo = {
    "title": "cURL",
    "bodyPrueba": "curl --location --request POST 'https://saludtools.qa.carecloud.com.co/integration/sync/event/v1/' --header 'Authorization: Bearer XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX' --header 'Content-Type: application/json' --data-raw" + '\'{ "eventType":"APPOINTMENT", "actionType":"DELETE", "body": { "id":"3295180" } }\'',
    "bodyProduccion": "curl --location --request POST 'https://saludtools.carecloud.com.co/integration/sync/event/v1/' --header 'Authorization: Bearer XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX' --header 'Content-Type: application/json' --data-raw" + '\'{ "eventType":"APPOINTMENT", "actionType":"DELETE", "body": { "id":"3295180" } }\'',
    "subtitle": "Después de Bearer Sustituya  las X por su access-token generado y cambie los parámetros por los datos de la cita que desea borrar."
  }

  jsonResultados = [
    {
      "title": "OK 200",
      "body": {
        "id": null,
        "code": 200,
        "message": "Se elimina  id: 2593844",
        "eventId": "245dc126d78a4fa7bacbd70bc4ba7bc4",
        "body": null
      },
      "subtitle": "La solicitud se realizó con éxito y la cita ha sido eliminada.",
      "status": "200"
    },
    {
      "title": "401 Unauthorized",
      "body":
        "No tiene permisos para acceder al servidor",
      "subtitle": "Coloque un token de acceso válido.",
      "status": "401"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
        "code": 412,
        "message": "No se ha enviado un tipo de evento valido",
        "eventId": "fe645e7a430c40bb946d221fb688351d",
        "body": null
      },
      "subtitle": "Se omitió el parámetro \"eventType\", Para borrar una cita es obligatorio el parametro \"eventType\", por favor  al inicio del body como en el ejemplo.",
      "status": "412"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
        "code": 412,
        "message": "No se ha enviado un tipo de evento valido",
        "eventId": "81b1de90a8894eeea01ea5ce20fc65a5",
        "body": null
      },
      "subtitle": "Error en el valor del parámetro \"eventType\". Para borrar una cita el valor permitido del \"eventType\"es \"APPOINTMENT\".",
      "status": "412"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
        "code": 412,
        "message": "No se ha enviado una accion válida",
        "eventId": "dd24b96692b744e4b97b397e5a3c1dd9",
        "body": null
      },
      "subtitle": "Se omitió el parámetro \"actionType\", Para borrar una cita es obligatorio el parámetro \"actionType\" que indica la accion que va a realizar el evento, por favor agrégalo al inicio del body como en el ejemplo.",
      "status": "412"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
        "code": 412,
        "message": "No se ha enviado una accion válida",
        "eventId": "3862429d69c54e65ae3d0c046b202f13",
        "body": null

      },
      "subtitle": "Error en el valor del parámetro \"actionType\". Para borrar una cita el valor permitido del parámetro \"actionType\" es \"DELETE\".",
      "status": "412"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
        "code": 412,
        "message": "Request method 'PUT' not supported",
        "eventId": "81cf71d0bf1b41f391ff690352cad890",
        "body": null
      },
      "subtitle": "El método usado no es compatible con el recurso enviado, recuerde que el método que utiliza este servicio es POST.",
      "status": "412"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
        "code": 412,
        "message": "Con los datos enviados no se ha encontrado cita para eliminar",
        "eventId": "0753a7138583479b9b607c0250bf908f",
        "body": null
      },
      "subtitle": "El id del usuario no se encuentra en la base de datos, rectifique el Id.",
      "status": "412"
    }]

  constructor() { }

  ngOnInit(): void {
  }

}
