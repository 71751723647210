import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  title: string ="Bienvenidos a la documentación de la API de saludtools";
  subTitle: string ="Esta página te ayudará a comenzar con la API de saludtools.";

  respuestasHTTPTitle = "Códigos de respuestas HTTP"
  respuestasHTTP =   [
    {
      "Código": "Código",
      "Descripción": "Descripción"
    },
    {
      "Código": "200 OK",
      "Descripción": "Este código de respuesta indica que la solicitud se ha realizado correctamente."
    },
    {
      "Código": "400 Bad Request",
      "Descripción": "La solicitud está mal formada."
      },
    {
      "Código": "401 Unauthorized",
      "Descripción": "Este error indica que debe realizar la autenticación antes de acceder al recurso."
      },
    {
      "Código": "404 Not Found",
      "Descripción": "Esta respuesta indica que no se pudo encontrar el recurso necesario. Esto generalmente se debe a que está mal escrito el endpoint."
      },
      {
      "Código": "405 Method Not Allowed",
      "Descripción": "El método HTTP utilizado no es compatible con el de este recurso."
        },
      {
      "Código": "500 Internal Server Error",
      "Descripción": "Ocurrió un error en la aplicación."
        }
]

eventType =   [
  {
    "Código": "eventType",
    "Descripción": "Descripción"
  },
  {
    "Código": "PATIENT",
    "Descripción": "Este tipo de evento maneja todo lo relacionado con pacientes."
  },
  {
    "Código": "APPOINTMENT",
    "Descripción": "Este evento maneja todo lo relacionado con citas."
    },
    {
      "Código": "MEDICINE",
      "Descripción": "Este evento maneja todo lo relacionado con preescripción de medicamentos."
      }
  ]

  actionType =   [
    {
      "Código": "actionType",
      "Descripción": "Descripción"
    },
    {
      "Código": "CREATE",
      "Descripción": "Esta acción crea un tipo de evento (paciente o cita)"
    },
    {
      "Código": "READ",
      "Descripción": "Esta acción lee un tipo de evento"
    },
    {
      "Código": "UPDATE",
      "Descripción": "Esta acción actualiza un tipo de evento"
    },
    {
      "Código": "SEARCH",
      "Descripción": "Esta acción filtra una lista de eventos"
    },
    {
      "Código": "DELETE",
      "Descripción": "Esta acción borra una lista de eventos"
      }
    ];

  constructor() { }

  ngOnInit(): void {
  }

}
