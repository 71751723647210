import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationError } from 'src/app/models/authentication-error';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ParametersService } from 'src/app/services/parameters.service';
import { AuthenticationRedirectionComponent } from '../authentication-redirection/authentication-redirection.component';
import { ParametersModalContentComponent } from '../parameters-modal/parameters-modal-content/parameters-modal-content.component';

@Component({
  selector: 'app-parameters-modal-form',
  templateUrl: './parameters-modal-form.component.html',
  styleUrls: ['./parameters-modal-form.component.css']
})
export class ParametersModalFormComponent implements OnInit {

  authenticationForm: FormGroup;
  submitted = false;
  json: any;
  showClass: boolean = false;

  @Input() type:string;
  @Input() title:string;
  



  onSubmit(){
    if (this.authenticationForm.invalid) {
      if (this.form.principleactive.errors) {
        this.showClass = true;
      }
      return;
    }

    this.parametersService.getParameters(this.type , this.authenticationForm.value.principleactive, "").subscribe(
      resp => {
      const modalRef = this.modalService.open(ParametersModalContentComponent);
      modalRef.componentInstance.json = resp;
      modalRef.componentInstance.title = this.title;
      this.activeModal.close();

    },
    error =>{
      const modalRef = this.modalService.open(AuthenticationRedirectionComponent);
      modalRef.componentInstance.type = this.type;
      modalRef.componentInstance.title = this.title;
      modalRef.componentInstance.actPrinciple = this.authenticationForm.value.principleactive;
      
      
    },
    );







    this.submitted = true;
  }

  constructor(public activeModal: NgbActiveModal, private formBuilder: FormBuilder, private authenticationService: AuthenticationService, private parametersService: ParametersService, private modalService: NgbModal) { }

  ngOnInit(): void {
    this.authenticationForm = this.formBuilder.group(
      {
        principleactive: ["", Validators.required]
      },

    );
  }

  get form() {
    return this.authenticationForm.controls;
  }

  onReset() {
    this.submitted = false;
    this.authenticationForm.reset();
    this.showClass = false;
  }

}
