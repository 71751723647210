<div class="row">
    <div class="col-md-12">
        <div class="body-param">
            <form (ngSubmit)="onSubmit()" [formGroup]="authenticationForm">
                <h3>Generar Token</h3>
                <div class="input-whith-icon">
                <input type="text" formControlName="key" class="form-control" placeholder="key"
                    [class.is-invalid]="(authenticationForm.get('key').touched && authenticationForm.get('key').invalid) || showClass" />
                    <i class="fa fa-user"></i>
                <div *ngIf="form.key.errors" class="invalid-feedback">
                    <div *ngIf="form.key.errors.required">El Key es obligatorio</div>
                </div>
            </div>
                <br>
                <div class="input-whith-icon">
                <input type="text" formControlName="secret" class="form-control" placeholder="secret"
                    [class.is-invalid]="(authenticationForm.get('secret').touched && authenticationForm.get('secret').invalid) || showClass2" />
                    <i class="fa fa-lock"></i>
                <div *ngIf="form.secret.errors" class="invalid-feedback">
                    <div *ngIf="form.secret.errors.required">El email es obligatorio</div>
                </div>
            </div>
                <br>
                <div *ngIf="submitted">
                    <h4 class="body-param-title">RESPUESTA</h4>
                    <pre class="pre-code">
                    <code [highlight]="json | json">
                    </code>
                    </pre>
                </div>
                <div class="col-md-12 text-right">
                    <button class="stlinkbutton" type="reset" (click)="onReset()">Reset</button>
                    <button class="stbutton">Enviar</button>
                </div>
            </form>
        </div>
    </div>
</div>