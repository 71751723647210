import { Component } from '@angular/core';
import { NavbarShowService } from './services/navbar-show.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'SaludToolsDoc';

  constructor(public navbarShow: NavbarShowService) { }
}
