<div class="sb-nav-fixed">
    <nav class="sb-topnav navbar navbar-expand navbar-dark ">
        <a class="navbar-brand" routerLink="home"><img src="assets/img/saludtools.png"></a>
        <button class="btn btn-link btn-sm order-1 order-lg-0" id="sidebarToggle" (click)="onChangeClass()"><i
                class="fas fa-bars"></i></button>
        <div class="d-none d-md-inline-block form-inline ml-auto mr-0 mr-md-3 my-2 my-md-0" id="navbarNav">
            <ul class="navbar-nav">
                    <app-dropdown></app-dropdown>
                <li class="nav-item">
                    <a class="nav-link" target="_blank" routerLinkActive="active"   href="https://saludtools.com/">Saludtools</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" target="_blank" routerLinkActive="active"   href="https://saludtools.com/contacto/">Contáctenos</a>
                </li>
            </ul>
        </div>
    </nav>

    <div class="sb-nav-fixed" [ngClass]="show ? '' : 'sb-sidenav-toggled'">
        <div id="layoutSidenav">
            <div id="layoutSidenav_nav">
                <nav class="sb-sidenav accordion sb-sidenav-dark" id="sidenavAccordion">
                    <div class="sb-sidenav-menu">
                        <div class="nav">
                            <div class="sb-sidenav-menu-heading">Documentación</div>
                            <a class="nav-link" routerLinkActive="active" routerLink="home">
                                <div class="sb-nav-link-icon"><i class="fas fa-home"></i></div>
                                Inicio
                            </a>
                            <div class="sb-sidenav-menu-heading">Autenticación</div>
                            <a class="nav-link collapsed" routerLinkActive="active" routerLink="apikey" data-toggle="collapse" data-target="#collapseApiKey"
                                aria-expanded="true" aria-controls="collapseApiKey">
                                <div class="sb-nav-link-icon"><i class="fas fa-key"></i></div>
                                ApiKey
                                <div class="sb-sidenav-collapse-arrow"><i class="fas fa-angle-down"></i></div>
                            </a>
                            <div class="collapse" id="collapseApiKey" aria-labelledby="headingOne"
                                data-parent="#sidenavAccordion">
                                <nav class="sb-sidenav-menu-nested nav">
                                    <a class="nav-link" routerLinkActive="active" routerLink="apikeycreate"> Crear ApiKey</a>
                                    <a class="nav-link" routerLinkActive="active" routerLink="apikeydelete">Borrar ApiKey</a>
                                    <a class="nav-link" routerLinkActive="active" routerLink="token">Token de Autorización</a>
                                </nav>
                            </div>
                            <div class="sb-sidenav-menu-heading">Integración con SaludTools</div>
                            <a class="nav-link collapsed" routerLinkActive="active" routerLink="patient" data-toggle="collapse" data-target="#collapsePatient"
                                aria-expanded="false" aria-controls="collapsePatient">
                                <div class="sb-nav-link-icon"><i class="fas fa-user"></i></div>
                                Pacientes
                                <div class="sb-sidenav-collapse-arrow"><i class="fas fa-angle-down"></i></div>
                            </a>
                            <div class="collapse" id="collapsePatient" aria-labelledby="headingOne"
                                data-parent="#sidenavAccordion">
                                <nav class="sb-sidenav-menu-nested nav">
                                    <a class="nav-link" routerLinkActive="active" routerLink="patientcreate">Crear Paciente</a>
                                    <a class="nav-link" routerLinkActive="active" routerLink="patientread">Leer Paciente</a>
                                    <a class="nav-link" routerLinkActive="active" routerLink="patientupdate">Actualizar Paciente</a>
                                    <a class="nav-link" routerLinkActive="active" routerLink="patientsearch">Buscar Paciente</a>
                                    <a class="nav-link" routerLinkActive="active" routerLink="patientdelete">Borrar Paciente</a>
                                </nav>
                            </div>
                            <a class="nav-link collapsed" routerLinkActive="active" routerLink="appointment" data-toggle="collapse" data-target="#collapseCita"
                                aria-expanded="false" aria-controls="collapseCita">
                                <div class="sb-nav-link-icon"><i class="fas fa-book-open"></i></div>
                                Citas
                                <div class="sb-sidenav-collapse-arrow"><i class="fas fa-angle-down"></i></div>
                            </a>
                            <div class="collapse" id="collapseCita" aria-labelledby="headingOne"
                                data-parent="#sidenavAccordion">
                                <nav class="sb-sidenav-menu-nested nav">
                                    <a class="nav-link" routerLinkActive="active" routerLink="appointmentcreate">Crear Cita</a>
                                    <a class="nav-link" routerLinkActive="active" routerLink="appointmentread">Leer Cita</a>
                                    <a class="nav-link" routerLinkActive="active" routerLink="appointmentupdate">Actualizar Cita</a>
                                    <a class="nav-link" routerLinkActive="active" routerLink="appointmentsearch">Buscar Cita</a>
                                    <a class="nav-link" routerLinkActive="active" routerLink="appointmentdelete">Borrar Cita</a>
                                </nav>
                            </div>
                            <a class="nav-link collapsed" routerLinkActive="active" routerLink="medicine" data-toggle="collapse" data-target="#collapseMedicine"
                                aria-expanded="false" aria-controls="collapseMedicine">
                                <div class="sb-nav-link-icon"><i class="fas fa-prescription-bottle-alt"></i></div>
                                Medicamentos
                                <div class="sb-sidenav-collapse-arrow"><i class="fas fa-angle-down"></i></div>
                            </a>
                            <div class="collapse" id="collapseMedicine" aria-labelledby="headingOne"
                                data-parent="#sidenavAccordion">
                                <nav class="sb-sidenav-menu-nested nav">
                                    <a class="nav-link" routerLinkActive="active" routerLink="medicinecreate">Crear Prescripción de Medicamentos</a>
                                    <a class="nav-link" routerLinkActive="active" routerLink="medicineread">Leer Prescripción de Medicamentos</a>
                                    <a class="nav-link" routerLinkActive="active" routerLink="medicinereadlast">Leer últimos Medicamentos recetados</a>
                                    <a class="nav-link" routerLinkActive="active" routerLink="medicineupdate">Actualizar Prescripción de Medicamentos</a>
                                   <!--<a class="nav-link" routerLinkActive="active" routerLink="medicinesearch">Buscar Cita</a> -->
                                    <a class="nav-link" routerLinkActive="active" routerLink="medicinedelete">Borrar Prescripción de Medicamentos</a>
                                </nav>
                            </div>
                            <a class="nav-link collapsed" routerLinkActive="active" routerLink="clinicHistory" data-toggle="collapse" data-target="#collapseClinicHistory"
                                aria-expanded="false" aria-controls="collapseClinicHistory">
                                <div class="sb-nav-link-icon"><i class="fas fa-address-book"></i></div>
                                Historia Clinica
                                <div class="sb-sidenav-collapse-arrow"><i class="fas fa-angle-down"></i></div>
                            </a>
                            <div class="collapse" id="collapseClinicHistory" aria-labelledby="headingOne"
                                data-parent="#sidenavAccordion">
                                <nav class="sb-sidenav-menu-nested nav">
                                    <a class="nav-link" routerLinkActive="active" routerLink="clinicHistoryCreate">Crear Atención</a>
                                    <a class="nav-link" routerLinkActive="active" routerLink="clinicHistoryRead">Leer Atención</a>
                                </nav>
                            </div>
                            <a class="nav-link collapsed" routerLinkActive="active" routerLink="clinicalLaboratories" data-toggle="collapse" data-target="#collapseClinicalLaboratories"
                                aria-expanded="false" aria-controls="collapseClinicalLaboratories">
                                <div class="sb-nav-link-icon"><i class="fas fa-flask"></i></div>
                                Laboratorios clínicos
                                <div class="sb-sidenav-collapse-arrow"><i class="fas fa-angle-down"></i></div>
                            </a>
                            <div class="collapse" id="collapseClinicalLaboratories" aria-labelledby="headingOne"
                                data-parent="#sidenavAccordion">
                                <nav class="sb-sidenav-menu-nested nav">
                                    <a class="nav-link" routerLinkActive="active" routerLink="clinicalLaboratoriesCreate">Crear laboratorios clínicos</a>
                                    <a class="nav-link" routerLinkActive="active" routerLink="clinicalLaboratoriesRead">Consultar laboratorios clínicos</a>
                                </nav>
                            </div>
                            <a class="nav-link collapsed" routerLinkActive="active" routerLink="documents" data-toggle="collapse" data-target="#collapseDocuments"
                                aria-expanded="false" aria-controls="collapseDocuments">
                                <div class="sb-nav-link-icon"><i class="fas fa-file"></i></div>
                                Documentos
                                <div class="sb-sidenav-collapse-arrow"><i class="fas fa-angle-down"></i></div>
                            </a>
                            <div class="collapse" id="collapseDocuments" aria-labelledby="headingOne"
                                data-parent="#sidenavAccordion">
                                <nav class="sb-sidenav-menu-nested nav">
                                    <a class="nav-link" routerLinkActive="active" routerLink="patientfileupload">Subir Documentos</a>
                                    <a class="nav-link" routerLinkActive="active" routerLink="patientfilesearch">Buscar Documentos</a>
                                    <a class="nav-link" routerLinkActive="active" routerLink="patientfileread">Generar Link de Descarga</a>
                                </nav>
                            </div>
                            <a class="nav-link collapsed" routerLinkActive="active" routerLink="inabilitywork" data-toggle="collapse" data-target="#collapseInabilityWork"
                                aria-expanded="false" aria-controls="collapseInabilityWork">
                                <div class="sb-nav-link-icon"><i class="fas fa-file-medical"></i></div>
                                Prescripción de Incapacidades
                                <div class="sb-sidenav-collapse-arrow"><i class="fas fa-angle-down"></i></div>
                            </a>
                            <div class="collapse" id="collapseInabilityWork" aria-labelledby="headingOne"
                                data-parent="#sidenavAccordion">
                                <nav class="sb-sidenav-menu-nested nav">
                                    <a class="nav-link" routerLinkActive="active" routerLink="inabilityworkcreate">Crear Prescripción de Incapacidades</a>
                                    <a class="nav-link" routerLinkActive="active" routerLink="inabilityworksearch">Buscar Incapacidades</a>
                                    <a class="nav-link" routerLinkActive="active" routerLink="inabilityworkread">Leer Incapacidad</a>
                                </nav>
                            </div>
                            <a class="nav-link collapsed" routerLinkActive="active" routerLink="examsprescription" data-toggle="collapse" data-target="#collapseExamsPrescription"
                                aria-expanded="false" aria-controls="collapseCita">
                                <div class="sb-nav-link-icon"><i class="fas fa-file-medical"></i></div>
                                    Prescripción de exámenes
                                <div class="sb-sidenav-collapse-arrow"><i class="fas fa-angle-down"></i></div>
                            </a>
                            <div class="collapse" id="collapseExamsPrescription" aria-labelledby="headingOne"
                                data-parent="#sidenavAccordion">
                                <nav class="sb-sidenav-menu-nested nav">
                                    <a class="nav-link" routerLinkActive="active" routerLink="examsprescriptioncreate">Crear Prescripción de exámenes</a>
                                    <a class="nav-link" routerLinkActive="active" routerLink="examsprescriptionread">Leer Prescripción de exámenes</a>
                                </nav>
                            </div>
                        <a class="nav-link collapsed" routerLinkActive="active" routerLink="examsResults" data-toggle="collapse" data-target="#collapseExamsResults"
                            aria-expanded="false" aria-controls="collapseDocuments">
                            <div class="sb-nav-link-icon"><i class="fas fa-envelope"></i></div>
                            Resultados de examenes
                            <div class="sb-sidenav-collapse-arrow"><i class="fas fa-angle-down"></i></div>
                        </a>
                        <div class="collapse" id="collapseExamsResults" aria-labelledby="headingOne"
                            data-parent="#sidenavAccordion">
                            <nav class="sb-sidenav-menu-nested nav">
                                <a class="nav-link" routerLinkActive="active" routerLink="examsResultsCreate">Crear resultado de examen</a>
                                <a class="nav-link" routerLinkActive="active" routerLink="examsResultsSearch">Buscar Documentos</a>
                                <a class="nav-link" routerLinkActive="active" routerLink="examsResultsRead">Generar Link de Descarga</a>
                            </nav>
                        </div>
                        <a class="nav-link collapsed" routerLinkActive="active" routerLink="patientGinecoInformation" data-toggle="collapse" data-target="#collapsePatientGinecoInformation"
                            aria-expanded="false" aria-controls="collapseDocuments">
                            <div class="sb-nav-link-icon"><i class="fas fa-envelope"></i></div>
                            Antecedentes Ginecoobstétricos
                            <div class="sb-sidenav-collapse-arrow"><i class="fas fa-angle-down"></i></div>
                        </a>
                        <div class="collapse" id="collapsePatientGinecoInformation" aria-labelledby="headingOne"
                            data-parent="#sidenavAccordion">
                            <nav class="sb-sidenav-menu-nested nav">
                                <a class="nav-link" routerLinkActive="active" routerLink="patientGinecoInformationCreate">Crear  Antecedente Ginecoobstétrico</a>
                                <a class="nav-link" routerLinkActive="active" routerLink="patientGinecoInformationRead">Leer  Antecedente Ginecoobstétrico</a>
                            </nav>
                        </div>
                        <a class="nav-link collapsed" routerLinkActive="active" routerLink="antecedentPersonal" data-toggle="collapse" data-target="#collapseAntecedentPersonal"
                            aria-expanded="false" aria-controls="collapseDocuments">
                            <div class="sb-nav-link-icon"><i class="fas fa-envelope"></i></div>
                            Antecedentes Personales
                            <div class="sb-sidenav-collapse-arrow"><i class="fas fa-angle-down"></i></div>
                        </a>
                        <div class="collapse" id="collapseAntecedentPersonal" aria-labelledby="headingOne"
                            data-parent="#sidenavAccordion">
                            <nav class="sb-sidenav-menu-nested nav">
                                <a class="nav-link" routerLinkActive="active" routerLink="antecedentPersonalCreate">Crear  Antecedente Personal</a>
                                <a class="nav-link" routerLinkActive="active" routerLink="antecedentPersonalRead">Leer  Antecedente Personal</a>
                            </nav>
                        </div>
                        <a class="nav-link collapsed" routerLinkActive="active" routerLink="patientFamiliarAntecedent" data-toggle="collapse" data-target="#collapsePatientFamiliarAntecedent"
                        aria-expanded="false" aria-controls="collapseDocuments">
                            <div class="sb-nav-link-icon"><i class="fas fa-envelope"></i></div>
                            Antecedentes Familiares
                            <div class="sb-sidenav-collapse-arrow"><i class="fas fa-angle-down"></i></div>
                        </a>
                        <div class="collapse" id="collapsePatientFamiliarAntecedent" aria-labelledby="headingOne"
                        data-parent="#sidenavAccordion">
                            <nav class="sb-sidenav-menu-nested nav">
                                <a class="nav-link" routerLinkActive="active" routerLink="patientFamiliarAntecedentCreate">Crear  Antecedente Familiar</a>
                                <a class="nav-link" routerLinkActive="active" routerLink="patientFamiliarAntecedentRead">Leer  Antecedente Familiar</a>
                                <a class="nav-link" routerLinkActive="active" routerLink="patientFamiliarAntecedentSearch">Buscar Antecedentes Familiares</a>
                            </nav>
                        </div>

                            <a class="nav-link" routerLinkActive="active" routerLink="webhook">
                                <div class="sb-nav-link-icon"><i class="fas fa-share-alt"></i></div>
                                WebHooks
                            </a>
                            <a class="nav-link" routerLinkActive="active" routerLink="parameter">
                                <div class="sb-nav-link-icon"><i class="fas fa-sliders-h"></i></div>
                                Parametros
                            </a>
                        </div>
                    </div>
                </nav>
            </div>
        </div>
    </div>
</div>