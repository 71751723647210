import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-patient-familiar-antecedent-read',
  templateUrl: './patient-familiar-antecedent-read.component.html',
  styleUrls: ['./patient-familiar-antecedent-read.component.css']
})
export class PatientFamiliarAntecedentReadComponent implements OnInit {

  title: string = "Leer Antecedentes Familiares";
  subTitle: string = "Lee un Antecedente Familiar"

  paragraphTitle: string = "¿Cómo leer un  Antecedente Familiar?";
  paragraph: string = "Para leer un Antecedente Familiar, primero debe tener un Token de acceso activo. Si su Token se venció, o aún no ha generado un Token de acceso. Genere un nuevo Token y consuma el evento de leer un  Antecedente Familiar.";

  parameters = [
    {
      "Parameters": "eventType",
      "requerido": true,
      "Type": "String",
      "Description": "Es el tipo de evento que se quiere invocar en este caso debe ser \"FAMILY_HISTORY\"."
    },
    {
      "Parameters": "actionType",
      "requerido": true,
      "Type": "String",
      "Description": "La acción que se quiere realizar para leer un antecedente familiar debe ser \"READ\""
    },
    {
      "Parameters": "documentType",
      "requerido": true,
      "Type": "Long",
      "Description": "Id del tipo de documento de identidad del paciente"
    },
    {
      "Parameters": "documentNumber",
      "requerido": true,
      "Type": "String",
      "Description": "Número del documento de identidad del paciente"
    },
    {
      "Parameters": "id",
      "requerido": false,
      "Type": "Long",
      "Description": "Id del antecedente Familiar"
    },
  ]

  jsonEjemplos =
    [{
      "title": "Body",
      "body":{
        "eventType":"FAMILY_HISTORY",
        "actionType":"READ",
        "body": {
          "documentType": 1,
          "documentNumber": "177400432",
          "id": 1234
          }
    },
      "subtitle": "Body para leer un Antecedente Familiar, Sustituya los parámetros documentType y documentNumber por los datos del paciente para leer su Antecedente Familiar."
    }
    ]

    curlEjemplo = {
      "title": "cURL",
      "bodyPrueba": "curl --location --request POST 'https://saludtools.qa.carecloud.com.co/integration/sync/event/v1/' --header 'Authorization: Bearer XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX' --header 'Content-Type: application/json' --data-raw" + '\'{ "eventType":"FAMILY_HISTORY", "actionType":"CREATE", "body": '+ JSON.stringify(this.jsonEjemplos[0].body.body)+'}\'',
      "bodyProduccion": "curl --location --request POST 'https://saludtools.carecloud.com.co/integration/sync/event/v1/' --header 'Authorization: Bearer XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX' --header 'Content-Type: application/json' --data-raw" + '\'{ "eventType":"FAMILY_HISTORY", "actionType":"CREATE", "body": '+ JSON.stringify(this.jsonEjemplos[0].body.body)+'}\'',
      "subtitle": "Después de Bearer sustituya  las X por su access-token generado y cambie los parámetros por los datos de su antecedente familiar a leer."
    }

  jsonResultados = [
    {
      "title": "OK 200",
      "body": {
        "id": null,
        "code": 200,
        "message": "Se consulta la información del antecedente Familiar id: 1234",
        "eventId": "7d972fc0d40447e1a22bb68f502699bf",
        "body": {
          "id": 1234,
          "documentType": 1,
          "documentNumber": "177400432",
          "diagnosticText": "diagnostico",
          "diagnosisDate": "2022-11-04",
          "comments": "comentarios hijo",
          "familiarRelationshipType": 1
        }
      },
      "subtitle": "La solicitud se realizó con éxito y se ha leído el  antecedente Familiar del paciente exitosamente.",
      "status": "200"
    },
    {
      "title": "401 Unauthorized",
      "body": 
        "No tiene permisos para acceder al servidor",
      "subtitle": "Coloque un token de acceso válido.",
      "status": "401"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
    "code": 412,
    "message": "No se ha enviado un tipo de evento valido",
    "eventId": "fe645e7a430c40bb946d221fb688351d",
    "body": null
      },
      "subtitle": "Se omitió el parámetro \"eventType\", para leer un antecedente Familiar es obligatorio el parametro \"eventType\", por favor agrégalo al inicio del body como en el ejemplo.",
      "status": "412"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
        "code": 412,
        "message": "No se ha enviado un tipo de evento valido",
        "eventId": "48c76fbf91cf46a19ebd948c3d2a6d5e",
        "body": null
      },
      "subtitle": "Error en el valor del parámetro \"eventType\". Para leer un antecedente Familiar el valor permitido del \"eventType\"es \"FAMILY_HISTORY\".",
      "status": "412"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
    "code": 412,
    "message": "No se ha enviado una accion válida",
    "eventId": "dd24b96692b744e4b97b397e5a3c1dd9",
    "body": null
      },
      "subtitle": "Se omitió el parámetro \"actionType\", para leer un antecedente Familiar es obligatorio el parámetro \"actionType\" que indica la acción de leer, por favor agrégalo al inicio del body como en el ejemplo.",
      "status": "412"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
        "code": 412,
        "message": "No se ha enviado una accion válida",
        "eventId": "a3d1a85dc6b649a2b4cb5ccc10d3e1c3",
        "body": null
      },
      "subtitle": "Error en el valor del parámetro \"actionType\". Para leer un antecedente Familiar el valor permitido del parámetro \"actionType\" es \"READ\".",
      "status": "412"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
        "code": 412,
        "message": "Request method 'PUT' not supported",
        "eventId": "81cf71d0bf1b41f391ff690352cad890",
        "body": null
      },
      "subtitle": "El método usado no es compatible con el recurso enviado, recuerde que el método que utiliza este servicio es POST.",
      "status": "412"
    }]

  constructor() { }

  ngOnInit(): void {
  }

}
