import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-appointment',
  templateUrl: './appointment.component.html',
  styleUrls: ['./appointment.component.css']
})
export class AppointmentComponent implements OnInit {


  title: string ="Citas";
  subTitle: string ="Consumiendo los servicios especificados desde la API de Saludtools usted puede crear, leer, actualizar, buscar y borrar citas, permitiendo al usuario una integración más sencilla."

  constructor() { }

  ngOnInit(): void {
  }

}
