<div id="layoutSidenav">
    <div id="layoutSidenav_content">
        <br>
        <div class="container">
            <app-main-title [title]="title" [subTitle]="subTitle"></app-main-title>
            <app-main-paragraph [paragraphTitle]="paragraphTitle" [paragraph]="paragraph"></app-main-paragraph>
         
            <h4>Los documentos del paciente contiene los siguientes atributos</h4>
            <table class="table table-bordered">
                <thead class="thead-dark">
                    <tr style="padding: 0px !important;">
                        <th scope="col">Nombre</th>
                        <th scope="col">Tipo</th>
                        <th scope="col">Descripción</th>
                        <th scope="col">Ejemplo</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th scope="row">documentType</th>
                        <td>Long</td>
                        <td>
                            Tipo de documento del Paciente:  
                                <ul>
                                    <li>1 = Cédula ciudadanía</li>
                                    <li>2 = Cédula de extranjería</li>
                                    <li>3 = Carné diplomático</li>
                                    <app-parameters-modal-button [type]="'documents'"></app-parameters-modal-button>
                                </ul>
                        </td>
                        <td>1</td> 
                    </tr>
                    <tr>
                        <th scope="row">documentNumber</th>
                        <td>String</td>
                        <td>Número de documento del paciente</td>
                        <td>"123456789"</td>     
                    </tr>
                    <tr>
                        <th scope="row">files</th>
                        <td>File</td>
                        <td>Documentos del paciente</td>
                        <td>"doc.pdf, doc.txt, doc.png"</td>     
                    </tr>
                </tbody>
            </table>
            <h4>PatientFileBasicResponseDTO contiene los siguientes atributos</h4>
            <table class="table  table-bordered">
                <thead class="thead-dark">
                    <tr style="padding: 0px !important;">
                        <th scope="col">Nombre</th>
                        <th scope="col">Tipo</th>
                        <th scope="col">Descripción</th>
                        <th scope="col">Ejemplo</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th scope="row">id</th>
                        <td>Long</td>
                        <td>Identificador único autogenerado al cargar cada documento</td>
                        <td>30303</td>
                    </tr>
                    <tr>
                        <th scope="row">fileName</th>
                        <td>String</td>
                        <td>Nombre del documento</td>
                        <td>img.png</td>
                    </tr>
                    <tr>
                        <th scope="row">typeFile</th>
                        <td>String</td>
                        <td>Tipo de documento (archivo)</td>
                        <td>image/png</td>
                    </tr>
                    <tr>
                        <th scope="row">byteSize</th>
                        <td>Long</td>
                        <td>Tamaño del documento en bytes</td>
                        <td>57198</td>
                    </tr>
                    <tr>
                        <th scope="row">pathLocation</th>
                        <td>String</td>
                        <td>Ubicación del documento</td>
                        <td>patients/1/11111/files/b3b5f98a-b935-4d3c-b12a-20e083206a7a/</td>
                    </tr>
                    <tr>
                        <th scope="row">uuid</th>
                        <td>String</td>
                        <td>Hash único autogenerado al cargar cada documento</td>
                        <td>b3b5f98a-b935-4d3c-b12a-20e083206a7a</td>
                    </tr>
                    <tr>
                        <th scope="row">insertDate</th>
                        <td>Date</td>
                        <td>Fecha de inserción del documento</td>
                        <td>2022-09-08T15:32:17.000+00:00</td>
                    </tr>
                    <tr>
                        <th scope="row">encounterId</th>
                        <td>Long</td>
                        <td>Identificador único de la atención</td>
                        <td>12345</td>
                    </tr>
                    <tr>
                        <th scope="row">diaryId</th>
                        <td>Long</td>
                        <td>Identificador único del agendamiento de la cita</td>
                        <td>67890</td>
                    </tr>
                    <tr>
                        <th scope="row">patient</th>
                        <td>Long</td>
                        <td>Identificador único del paciente</td>
                        <td>11111</td>
                    </tr>
                    <tr>
                        <th scope="row">idExam</th>
                        <td>Long</td>
                        <td>Identificador único del examen</td>
                        <td>13579</td>
                    </tr>
                </tbody>
            </table>
            <hr>
            <app-footer></app-footer>
        </div>
    </div>
</div>
