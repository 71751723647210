import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-exams-results-search',
  templateUrl: './exams-results-search.component.html',
})
export class ExamsResultsSearchComponent implements OnInit {

  title: string = "Buscar resultados de examenes del paciente";
  subTitle: string = "Puede filtrar una lista de examenes de un paciente dado una cantidad de parámetros."

  paragraphTitle: string = "¿Cómo filtrar los examenes de un paciente?";
  paragraph: string = "Para buscar una lista de examenes, primero debe tener un token de acceso activo. Si su token se venció, o aun no ha generado un token de acceso. genere uno nuevo. Si ya está autenticado debe colocar en el body los parámetros que quiera filtrar. Puede elegir un parámetro o hacer una combinación de los parámetros que desee agregar para filtrar los pacientes.";

  parameters = [
    {
      "Parameters": "eventType",
      "requerido": true,
      "Type": "String",
      "Description": "Es el tipo de evento que se quiere invocar en este caso debe ser \"EXAMS_RESULTS\"."
    },
    {
      "Parameters": "actionType",
      "requerido": true,
      "Type": "String",
      "Description": "Es la acción que se quiere realizar para buscar pacientea debe ser \"SEARCH\""
    },
    {
      "Parameters": "documentType",
      "requerido": true,
      "Type": "Long",
      "Description": "Id del tipo de documento de identidad del paciente"
    },
    {
      "Parameters": "documentNumber",
      "requerido": true,
      "Type": "String",
      "Des,cription": "Número del documento de identidad del paciente"
    },
    {
      "Parameters": "encounterId",
      "requerido": false,
      "Type": "String",
      "Description": "Número de la atención si se requiere"
    }
  ]

  jsonEjemplos =
    [{
      "title": "Body",
      "body": {
          "eventType":"EXAMS_RESULTS",
          "actionType":"SEARCH",
          "body": {
              "documentType": 1,
              "documentNumber" : "123456789",
              "encounterId":null,
              "pageable" :{
                  "page" : 0,
                  "size" : 10
              }
          }
      },
      "subtitle": "Body para filtrar la cantidad de resultados de un paciente que cumpla con esos parámetros."
    },
    ]

  curlEjemplo = {
    "title": "cURL",
    "bodyPrueba": "curl --location --request POST 'https://saludtools.qa.carecloud.com.co/integration/sync/event/v1/' --header 'Authorization: Bearer XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX' --header 'Content-Type: application/json' --data-raw" + '\'{ "eventType":"PATIENT", "actionType":"SEARCH", "body": { "firstName":"Luis", "secondName": "Andres", "firstLastName" : "Gutierrez", "secondLastName" : "Gamez", "birthDate" : "1967-12-05", "gender" : 2, "documentType": 1, "documentNumber" : "177400432", "phone" : "3144780243", "cellPhone" : "3144780243", "email" : "lzarate@carecloud.com.co", "eps" : 3, "habeasData": false, "pageable" :{ "page" : 0, "size" : 20 } } }\'',
    "bodyProduccion": "curl --location --request POST 'https://saludtools.carecloud.com.co/integration/sync/event/v1/' --header 'Authorization: Bearer XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX' --header 'Content-Type: application/json' --data-raw" + '\'{ "eventType":"PATIENT", "actionType":"SEARCH", "body": { "firstName":"Luis", "secondName": "Andres", "firstLastName" : "Gutierrez", "secondLastName" : "Gamez", "birthDate" : "1967-12-05", "gender" : 2, "documentType": 1, "documentNumber" : "177400432", "phone" : "3144780243", "cellPhone" : "3144780243", "email" : "lzarate@carecloud.com.co", "eps" : 3, "habeasData": false, "pageable" :{ "page" : 0, "size" : 20 } } }\'',
    "subtitle": "Después de Bearer sustituya  las X por su access-token generado y cambie los parámetros por los datos a filtrar."
  }

  jsonResultados = [
    {
      "id": null,
      "code": 200,
      "message": "Se filtra informacion de ",
      "eventId": "54622c602b2f498399ac5790bb29b0ba",
      "body": {
          "content": [
              {
                  "exam": {
                      "id": 11234,
                      "companyOwnerId": 3,
                      "medicalExamType": 1,
                      "classificationType": 1,
                      "examDate": "2022-09-29T10:00:00.000+00:00",
                      "comments": "",
                      "patient": 215157,
                      "documents": []
                  }
              },
  
              {
                  "exam": {
                      "id": 11241,
                      "companyOwnerId": 3,
                      "medicalExamType": 1,
                      "classificationType": 1,
                      "examDate": "2022-09-29T10:00:00.000+00:00",
                      "comments": "",
                      "patient": 215157,
                      "documents": [
                          {
                              "createdBy": null,
                              "createdAt": null,
                              "modifiedBy": null,
                              "modifiedAt": null,
                              "id": 30236,
                              "fileName": "image (5).png",
                              "typeFile": "image/png",
                              "byteSize": 81805,
                              "pathLocation": "patients/3/215157/files/6e47676c-8ee2-458b-a2f5-28c11eb62aed/",
                              "uuid": "6e47676c-8ee2-458b-a2f5-28c11eb62aed",
                              "insertDate": "2022-09-29T19:20:48.000+00:00",
                              "encounterId": null,
                              "diaryId": null,
                              "patient": 215157,
                              "idExam": 11241
                          }
                      ]
                  }
              },
           
          ],
          "pageable": {
              "sort": {
                  "unsorted": true,
                  "sorted": false,
                  "empty": true
              },
              "offset": 0,
              "pageNumber": 0,
              "pageSize": 10,
              "paged": true,
              "unpaged": false
          },
          "totalElements": 10,
          "totalPages": 1,
          "last": true,
          "size": 10,
          "number": 0,
          "sort": {
              "unsorted": true,
              "sorted": false,
              "empty": true
          },
          "first": true,
          "numberOfElements": 10,
          "empty": false
      }
  },
    {
      "title": "401 Unauthorized",
      "body":
        "No tiene permisos para acceder al servidor",
      "subtitle": "Coloque un token de acceso válido.",
      "status": "401"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
        "code": 412,
        "message": "No se ha enviado un tipo de evento valido",
        "eventId": "fe645e7a430c40bb946d221fb688351d",
        "body": null
      },
      "subtitle": "Se omitió el parámetro \"eventType\", para buscar un paciente es obligatorio el parametro \"eventType\", por favor agrégalo al inicio del body como en el ejemplo.",
      "status": "212"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
        "code": 412,
        "message": "No se ha enviado un tipo de evento valido",
        "eventId": "48c76fbf91cf46a19ebd948c3d2a6d5e",
        "body": null
      },
      "subtitle": "Error en el valor del parámetro \"eventType\", para buscar un paciente el valor permitido del \"eventType\"es \"PATIENT_FILES\".",
      "status": "412"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
        "code": 412,
        "message": "No se ha enviado una accion válida",
        "eventId": "dd24b96692b744e4b97b397e5a3c1dd9",
        "body": null
      },
      "subtitle": "Se omitió el parámetro \"actionType\", para buscar un paciente es obligatorio el parámetro \"actionType\" que indica la accion de filtrar sobre los documentos del paciente, por favor agrégalo al inicio del body como en el ejemplo.",
      "status": "412"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
        "code": 412,
        "message": "No se ha enviado una accion válida",
        "eventId": "a3d1a85dc6b649a2b4cb5ccc10d3e1c3",
        "body": null
      },
      "subtitle": "Error en el valor del parámetro \"actionType\", para buscar un paciente el valor permitido del parámetro \"actionType\" es \"SEARCH\".",
      "status": "412"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
        "code": 412,
        "message": "Request method 'PUT' not supported",
        "eventId": "81cf71d0bf1b41f391ff690352cad890",
        "body": null
      },
      "subtitle": "El método usado no es compatible con el recurso enviado, recuerde que el método que utiliza este servicio es POST.",
      "status": "412"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
        "code": 412,
        "message": "Por favor envie los datos de paginacion de la consulta",
        "eventId": "7bd7685fd780428caa7cc5fb62dfd236",
        "body": null
      },
      "subtitle": "Los datos de paginación son obligatorios. debe colocar la página que desea mostrar y la cantidad de elementos que va contener cada página.",
      "status": "412"
    }]

  constructor() { }

  ngOnInit(): void {
  }

}
