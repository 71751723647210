import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-medicine-read-last',
  templateUrl: './medicine-read-last.component.html',
  styleUrls: ['./medicine-read-last.component.css']
})
export class MedicineReadLastComponent implements OnInit {

  title: string = "Leer los últimos Medicamentos recetados a un paciente";
  subTitle: string = "Leer los últimos medicamentos recetados a un paciente es muy facil, solo tiene que saber el tipo y número de documento del paciente.";

  paragraphTitle: string = "¿Cómo leer los últimos medicamentos recetados a un paciente?";
  paragraph: string =
    "Para leer los últimos medicamentos recetados a un paciente, primero debe tener un Token de acceso activo. Si su Token se venció, o aún no ha generado un Token de acceso. Genere un nuevo Token y consuma el evento de leer prescripción médica, sólo necesita saber el tipo y número de documento del paciente";

  parameters = [
    {
      "Parameters": "eventType",
      "requerido": true,
      "Type": "String",
      "Description": " Que se quiere invocar en este caso debe ser \"MEDICINE\"."
    },
    {
      "Parameters": "actionType",
      "requerido": true,
      "Type": "String",
      "Description": "Es la acción que se quiere realizar para leer una prescripción debe ser \"READ\""
    },
    {
      "Parameters": "documentType",
      "requerido": true,
      "Type": "Long",
      "Description": "Id del tipo de documento de identidad del paciente"
    },
    {
      "Parameters": "documentNumber",
      "requerido": true,
      "Type": "String",
      "Description": "Número del documento de identidad del paciente"
    },
  ]

  jsonEjemplos =
    [{
      "title": "Body",
      "body":
      {
        "eventType":"MEDICINE",
        "actionType":"READ",
        "body": {
          "search": {
            "documentType": 1,
            "documentNumber": "123456789"
          }
        }
      },
      "subtitle": "Body para leer los últimos medicamentos recetados a un paciente"
    }
    ]

  curlEjemplo = {
    "title": "cURL",
    "bodyPrueba": "curl --location --request POST 'https://saludtools.qa.carecloud.com.co/integration/sync/event/v1/' --header 'Authorization: Bearer XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX' --header 'Content-Type: application/json' --data-raw" + '\'{"eventType":"MEDICINE", "actionType":"READ", "body": { "search": { "doctorDocumentType": 1, "doctorDocumentNumber": "123456789" } } }\'',
    "bodyProduccion": "curl --location --request POST 'https://saludtools.carecloud.com.co/integration/sync/event/v1/' --header 'Authorization: Bearer XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX' --header 'Content-Type: application/json' --data-raw" + '\'{"eventType":"MEDICINE", "actionType":"READ", "body": { "search": { "doctorDocumentType": 1, "doctorDocumentNumber": "123456789" } } }\'',
    "subtitle": "Después de Bearer sustituya  las X por su access-token generado y cambie los parámetros por los datos de su preescripción a leer."
  }

  jsonResultados = [
    {
      "title": "OK 200",
      "body": {
        "id": null,
    "code": 200,
    "message": "Se consulta la informacion de  id: 81734",
    "eventId": "56c6c7e9e66747419218d4e9b3fd15d5",
    "body": {
        "id": 81734,
        "pageable": null,
        "name": "PrescripciÃ³n11-08-2021 01:13",
        "doctorDocumentType": 1,
        "doctorDocumentNumber": "11111",
        "encounterId": null,
        "patientId": 215161,
        "improved": true,
        "prescriptedMedicine": [
            {
                "id": 425563,
                "medicinePrescriptionType": "COMMERCIAL_PRESENTATION",
                "comercialProductName": "ADVIL CHILDREN JARABE 100 MG/5CC",
                "comercial_product_unique_code": "",
                "comercialProductId": 13753,
                "magistralPreparationFormula": null,
                "principleActiveName": null,
                "principleActiveType": null,
                "atcConcentrationId": null,
                "intakemethod": 1,
                "quantityImproved": 2.0,
                "quantityUnit": "p",
                "frecuencyImproved": 5.0,
                "frequencyUnit": 1,
                "durationImproved": 3.0,
                "durationUnit": 2,
                "totalQuantity": "864 p",
                "indicationsTaking": "Administrar 2 p cada 5 Hora(s) durante 3 Mes(es)",
                "comments": "",
                "pharmaForm": null
            }
        ]
    }
      },
      "subtitle": "La solicitud se realizó con éxito y se lee la prescripción con id 81734.",
      "status": "200"
    },
    {
      "title": "401 Unauthorized",
      "body": 
        "No tiene permisos para acceder al servidor",
      "subtitle": "Coloque un token de acceso válido.",
      "status": "401"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
    "code": 412,
    "message": "No se ha enviado un tipo de evento valido",
    "eventId": "fe645e7a430c40bb946d221fb688351d",
    "body": null
      },
      "subtitle": "Se omitió el parámetro \"eventType\", para leer una prescripción es obligatorio el parametro \"eventType\", por favor agrégalo al inicio del body como en el ejemplo.",
      "status": "212"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
        "code": 400,
        "message": "JSON parse error: Cannot deserialize value of type `com.saludtools.integration.enumeration.EventTypeEnum` from String \"MEDICINEa\": not one of the values accepted for Enum class: [MEDICINE, PATIENT, APPOINTMENT]; nested exception is com.fasterxml.jackson.databind.exc.InvalidFormatException: Cannot deserialize value of type `com.saludtools.integration.enumeration.EventTypeEnum` from String \"PATIENTa\": not one of the values accepted for Enum class: [MEDICINE, PATIENT, APPOINTMENT]\n at [Source: (PushbackInputStream); line: 2, column: 17] (through reference chain: com.saludtools.integration.entities.EventIn[\"eventType\"])",
        "eventId": "48c76fbf91cf46a19ebd948c3d2a6d5e",
        "body": null
      },
      "subtitle": "Error en el valor del parámetro \"eventType\". Para leer una prescripción el valor permitido del \"eventType\"es \"MEDICINE\".",
      "status": "400"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
    "code": 400,
    "message": "No se ha enviado una accion vÃ¡lida",
    "eventId": "dd24b96692b744e4b97b397e5a3c1dd9",
    "body": null
      },
      "subtitle": "Se omitió el parámetro \"actionType\", para leer una prescripción es obligatorio el parámetro \"actionType\" que indica la acción de leer la prescripción de medicamentos, por favor agrégalo al inicio del body como en el ejemplo.",
      "status": "400"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
        "code": 400,
        "message": "JSON parse error: Cannot deserialize value of type `com.saludtools.integration.enumeration.ActionTypeEnum` from String \"READA\": not one of the values accepted for Enum class: [SEARCH, UPDATE, DELETE, CREATE, READ]; nested exception is com.fasterxml.jackson.databind.exc.InvalidFormatException: Cannot deserialize value of type `com.saludtools.integration.enumeration.ActionTypeEnum` from String \"READA\": not one of the values accepted for Enum class: [SEARCH, UPDATE, DELETE, CREATE, READ]\n at [Source: (PushbackInputStream); line: 3, column: 18] (through reference chain: com.saludtools.integration.entities.EventIn[\"actionType\"])",
        "eventId": "a3d1a85dc6b649a2b4cb5ccc10d3e1c3",
        "body": null
      },
      "subtitle": "Error en el valor del parámetro \"actionType\". Para LEER una prescripción de medicamentos el valor permitido del parámetro \"actionType\" es \"READ\".",
      "status": "400"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
    "code": 412,
    "message": "Para Id enviado, no se ha encontrado una prescripcion en nuestra base de datos.",
    "eventId": "5e39649a8983495c9d2b749facc31790",
    "body": null
       
      },
      "subtitle": "El id de la prescripción no se encuentra en la base de datos, por favor verifique y corrija el id",
      "status": "400"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
    "code": 400,
    "message": "Request method 'PUT' not supported",
    "eventId": "81cf71d0bf1b41f391ff690352cad890",
    "body": null
      },
      "subtitle": "El método usado no es compatible con el recurso enviado, recuerde que el método que utiliza este servicio es POST.",
      "status": "400"
    }]

  constructor() { }

  ngOnInit(): void {
  }

}
