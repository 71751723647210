import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-appointment-read',
  templateUrl: './appointment-read.component.html',
  styleUrls: ['./appointment-read.component.css']
})
export class AppointmentReadComponent implements OnInit {

  title: string = "Leer una Cita";
  subTitle: string = "Lea los datos de una cita específica."

  paragraphTitle: string = "¿Cómo leer una cita?";
  paragraph: string = "Para leer una cita ya registrada en la base de datos de Saludtools, primero debe tener un Token de acceso activo. Si su Token se venció, o aún no ha generado un Token de acceso debe generar uno. Si ya tiene el Token de acceso solo tiene que saber el Id de la cita para leerla consumiendo el servicio.";

  parameters = [
    {
      "Parameters": "eventType",
      "requerido": true,
      "Type": "String",
      "Description": "Es el tipo de evento que se quiere invocar en este caso debe ser \"APPOINTMENT\"."
    },
    {
      "Parameters": "actionType",
      "requerido": true,
      "Type": "String",
      "Description": "Es la acción que se quiere realizar para leer una cita debe ser \"READ\""
    },
    {
      "Parameters": "id",
      "requerido": true,
      "Type": "String",
      "Description": "Id de la cita a buscar"
    }

  ]

  jsonEjemplos =
    [{
      "title": "Body",
      "body":
      {
        "eventType": "APPOINTMENT",
        "actionType": "READ",
        "body": {
          "id": "3295180"
        }
      },
      "subtitle": "Body para leer una cita."
    }
    ]

  curlEjemplo = {
    "title": "cURL",
    "bodyPrueba": "curl --location --request POST 'https://saludtools.qa.carecloud.com.co/integration/sync/event/v1/' --header 'Authorization: Bearer XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX' --header 'Content-Type: application/json' --data-raw" + '\'{ "eventType":"APPOINTMENT", "actionType":"READ", "body": { "id":"3295180" } }\'',
    "bodyProduccion": "curl --location --request POST 'https://saludtools.carecloud.com.co/integration/sync/event/v1/' --header 'Authorization: Bearer XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX' --header 'Content-Type: application/json' --data-raw" + '\'{ "eventType":"APPOINTMENT", "actionType":"READ", "body": { "id":"3295180" } }\'',
    "subtitle": "Después de Bearer sustituya  las X por su access-token generado y cambie los parámetros por los datos de la cita que se va a leer."
  }

  jsonResultados = [
    {
      "title": "OK 200",
      "body": {
        "id": null,
        "code": 200,
        "message": "Se consulta la informacion de  id: 2593842",
        "eventId": "3eb8d63a93be49d096c51f39b35d7bfd",
        "body": {
          "id": 2593842,
          "pageable": null,
          "startAppointment": "2021-05-04 08:00",
          "endAppointment": "2021-05-04 08:30",
          "patientDocumentType": 1,
          "patientDocumentNumber": "1414141",
          "doctorDocumentType": 1,
          "doctorDocumentNumber": "11111",
          "modality": "CONVENTIONAL",
          "stateAppointment": "PENDING",
          "notificationState": "ATTEND",
          "appointmentType": "CITADEPRUEBA",
          "clinic": 8,
          "comment": null
        }
      },
      "subtitle": "La solicitud se realizó con éxito y ahora puede observar la cita.",
      "status": "200"
    },
    {
      "title": "401 Unauthorized",
      "body":
        "No tiene permisos para acceder al servidor",
      "subtitle": "Coloque un Token de acceso válido.",
      "status": "401"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
        "code": 412,
        "message": "No se ha enviado un tipo de evento valido",
        "eventId": "fe645e7a430c40bb946d221fb688351d",
        "body": null
      },
      "subtitle": "Se omitió el parámetro \"eventType\", para leer una cita es obligatorio el parametro \"eventType\", por favor agrégalo al inicio del body como en el ejemplo.",
      "status": "412"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
        "code": 412,
        "message": "No se ha enviado un tipo de evento valido",
        "eventId": "81b1de90a8894eeea01ea5ce20fc65a5",
        "body": null
      },
      "subtitle": "Error en el valor del parámetro \"eventType\", para generar una cita el valor permitido del \"eventType\"es \"APPOINTMENT\".",
      "status": "412"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
        "code": 412,
        "message": "No se ha enviado una accion válida",
        "eventId": "dd24b96692b744e4b97b397e5a3c1dd9",
        "body": null
      },
      "subtitle": "Se omitió el parámetro \"actionType\", para leer una cita es obligatorio el parámetro \"actionType\" que indica la acción que va a realizar el evento, por favor agrégalo al inicio del body como en el ejemplo.",
      "status": "412"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
        "code": 412,
        "message": "No se ha enviado una accion válida",
        "eventId": "c4d2527a7731414cbb798cc2d5930bd9",
        "body": null

      },
      "subtitle": "Error en el valor del parámetro \"actionType\", para leer una cita el valor permitido del parámetro \"actionType\" es \"READ\".",
      "status": "412"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
        "code": 412,
        "message": "Request method 'PUT' not supported",
        "eventId": "81cf71d0bf1b41f391ff690352cad890",
        "body": null
      },
      "subtitle": "El método usado no es compatible con el recurso enviado, recuerde que el método que utiliza este servicio es POST.",
      "status": "412"
    }]

  constructor() { }

  ngOnInit(): void {
  }

}
