import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-patient',
  templateUrl: './patient.component.html',
  styleUrls: ['./patient.component.css']
})
export class PatientComponent implements OnInit {

  title: string ="Paciente";
  subTitle: string ="Consumiendo los servicios especificados desde la API de Saludtools usted puede crear, leer, actualizar, buscar y borrar pacientes, permitiendo al usuario una integración más sencilla."


  paragraphTitle: string ="¿La información de mis pacientes es confidencial?";
  paragraph: string ="TOTALMENTE. Saludtools es un sistema de primer nivel que garantiza totalmente la confidencialidad de la información personal de sus pacientes. NUNCA se compartirá con un tercero o se comercializará sin la autorización expresa del médico o el paciente. Este compromiso se documenta en nuestra licencia de uso, que deja este punto totalmente claro.";



  constructor() { }

  ngOnInit(): void {
  }

}
