<div class="modal-header">
    <h4 class="modal-title">{{title}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <table class="table table table-hover">
      <thead>
        <tr>
          <th scope="col" >id</th>
          <th scope="col" *ngIf="type == 'encountercommonid'">Tipo de encuentro</th>
          <th scope="col" >Fecha de creación</th>
          <th scope="col" *ngIf="type != 'antecedentspersonal'">Name</th>
          <th scope="col" *ngIf="type == 'antecedentspersonal'" >Creado por</th>
          <th scope="col" *ngIf="type == 'antecedentspersonal'">EncounterId</th>
        </tr>
      </thead>
      <tbody *ngFor="let json of json.content">
        <tr>
          <td *ngIf="type == 'encountercommonid'">{{json.encounterId}}</td>
          <td *ngIf="type == 'encountercommonid'">{{json.encounterType}}</td>
          <td *ngIf="type == 'encountercommonid'">{{json.date | date: 'dd/MM/yyyy hh:mm a'}}</td>
          <td *ngIf="type == 'encountercommonid'">{{json.name}}</td>
          <td *ngIf="type == 'remissioncontainerid'">{{json.id}}</td>
          <td *ngIf="type == 'remissioncontainerid'">{{json.createDate}}</td>
          <td *ngIf="type == 'remissioncontainerid'">{{json.containerName}}</td>
          <td *ngIf="type == 'antecedentspersonal'">{{json.id}}</td>
          <td *ngIf="type == 'antecedentspersonal'">{{json.creationTime | date: 'dd/MM/yyyy hh:mm a'}}</td>
          <td *ngIf="type == 'antecedentspersonal'">{{json.createdByUser}}</td>
          <td *ngIf="type == 'antecedentspersonal'">{{json.encounterCommonInfo}}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="modal-footer">
    <nav aria-label="...">
      <ul class="pagination">
        <li class="page-item" [ngClass]="disabledShowPrevious ? '' : 'disabled'">
          <a class="page-link pointer-link" (click)="previous()">Previous</a>
        </li>
        <li class="page-item"><a class="page-link">{{actualPage}}</a></li>
        <li class="page-item" [ngClass]="disabledShowNext ? '' : 'disabled'">
          <a class="page-link pointer-link" (click)="next()">Next</a>
        </li>
      </ul>
    </nav>
    <button style="margin-top: -14px;" type="button" class="btn btn-primary" (click)="activeModal.close('Close click')">Close</button>
  </div>
