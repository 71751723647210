<h4 *ngIf="title">{{title}}</h4>
<br *ngIf="title">
<table class="table">
    <tbody>
        <tr *ngFor="let tableRow of tableRow; let first=first" [ngClass]="{'header-table':first}">
            <td scope="row" *ngFor="let key of objectKeys(tableRow) ; let first=first" [ngClass]="{'bolt-font':first}">
                {{tableRow[key]}}
            </td>
        </tr>
    </tbody>
</table>




