import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-antecedent-personal-create',
  templateUrl: './antecedent-personal-create.component.html',
  styleUrls: ['./antecedent-personal-create.component.css']
})
export class AntecedentPersonalCreateComponent implements OnInit {

  title: string = "Crear Antecedente Personal";
  subTitle: string = "Cree un nuevo Antecedente Personal"

  paragraphTitle: string = "¿Cómo crear un Antecedente Personal?";
  paragraph: string = "Para crear un Antecedente Personal, primero debe tener un Token de acceso activo. Si su Token se venció, o aún no ha generado un Token de acceso. Genere un nuevo Token y consuma el evento de crear un Antecedente Personal.";

  parameters = [
    {
      "Parameters": "eventType",
      "requerido": true,
      "Type": "String",
      "Description": "Es el tipo de evento que se quiere invocar en este caso debe ser \"ANTECEDENT_PERSONAL\"."
    },
    {
      "Parameters": "actionType",
      "requerido": true,
      "Type": "String",
      "Description": "Es la acción que se quiere realizar para crear un Antecedente Personal debe ser \"CREATE\""
    },
    {
      "Parameters": "documentType",
      "requerido": true,
      "Type": "Long",
      "Description": "Id del tipo de documento de identidad del paciente"
    },
    {
      "Parameters": "documentNumber",
      "requerido": true,
      "Type": "String",
      "Description": "Número del documento de identidad del paciente"
    },

    {
      "Parameters": "encounterCommonInfo",
      "requerido": false,
      "Type": "Long",
      "Description": "id del encuentro común si el antecedente está relacionado a una antención"
    },
    {
      "Parameters": "friendlyNameId",
      "requerido": false,
      "Type": "Long",
      "Description": "id del nombre amigable del antecedente (solo para antecedentes personales dentro de una atención)"
    },
    {
      "Parameters": "othersDiagnosticText",
      "requerido": false,
      "Type": "String",
      "Description": "texto de otros global al grupo de antecedente (solo para antecedentes personales dentro de una atención)"
    },
    {
      "Parameters": "othersDiagnosticTextGroupId",
      "requerido": false,
      "Type": "Long",
      "Description": "id grupo de antecedentes necesario si se va a registrar othersDiagnosticText (solo para antecedentes personales dentro de una atención)"
    },
    {
      "Parameters": "diagnosticType",
      "requerido": false,
      "Type": "String",
      "Description": "Código del CIE10"
    },
    {
      "Parameters": "diagnosticText",
      "requerido": false,
      "Type": "String",
      "Description": "Texto cuando el antecedente es genérico y se crea fuera de una atención del CIE10"
    },
    {
      "Parameters": "diagnosisDate",
      "requerido": false,
      "Type": "LocalDate",
      "Description": "Fecha del diagnóstico (yyyy-mm-dd)"
    },
    {
      "Parameters": "antecedentStateType",
      "requerido": false,
      "Type": "Long",
      "Description": "id de la Clasificación del diagnóstico"
    },
    {
      "Parameters": "antecedentState",
      "requerido": false,
      "Type": "Long",
      "Description": "id del tipo de diagnóstico"
    },
    {
      "Parameters": "comments",
      "requerido": false,
      "Type": "String",
      "Description": "comentario de un antecedente personal"
    },
  
  ]

  jsonEjemplos =
    [{
      "title": "Body",
      "body":{
        "eventType":"ANTECEDENT_PERSONAL",
        "actionType":"CREATE",
        "body": {
          "documentType": 1,
          "documentNumber": "65135",
          "encounterCommonInfo": null,
          "friendlyNameId":null,
          "othersDiagnosticText": "aaaaaaaaaaaaaaaaaaaa",
          "othersDiagnosticTextGroupId": 16883,
          "diagnosticType": null,
          "diagnosticText":"111",
          "diagnosisDate": "2022-01-11" ,
          "antecedentStateType": 10,
          "antecedentState": 1,
          "comments": "ESTO ES UN COMENTARIO"
        }
    },
      "subtitle": "Body para crear un Antecedente Personal, sustituya los parámetros por los del Antecedente Personal a crear."
    }
    ]

  curlEjemplo = {
    "title": "cURL",
    "bodyPrueba": "curl --location --request POST 'https://saludtools.qa.carecloud.com.co/integration/sync/event/v1/' --header 'Authorization: Bearer XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX' --header 'Content-Type: application/json' --data-raw" + '\'{ "eventType":"ANTECEDENT_PERSONAL", "actionType":"CREATE", "body": '+ JSON.stringify(this.jsonEjemplos[0].body.body)+'}\'',
    "bodyProduccion": "curl --location --request POST 'https://saludtools.carecloud.com.co/integration/sync/event/v1/' --header 'Authorization: Bearer XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX' --header 'Content-Type: application/json' --data-raw" + '\'{ "eventType":"ANTECEDENT_PERSONAL", "actionType":"CREATE", "body": '+ JSON.stringify(this.jsonEjemplos[0].body.body)+'}\'',
    "subtitle": "Después de Bearer sustituya  las X por su access-token generado y cambie los parámetros por los datos a crear."
  }

  jsonResultados = [
    {
      "title": "OK 200",
      "body": {
        "id": 349705,
        "code": 200,
        "message": "Se registra  id: 349705",
        "eventId": "128b6f55ff094c9586cd4f3f2ca03cda",
        "body": null
      },
      "subtitle": "La solicitud se realizó con éxito y el Antecedente Personal ha sido creado.",
      "status": "200"
    },
    {
      "title": "401 Unauthorized",
      "body": 
        "No tiene permisos para acceder al servidor",
      "subtitle": "Coloque un token de acceso válido.",
      "status": "401"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
    "code": 412,
    "message": "No se ha enviado un tipo de evento valido",
    "eventId": "fe645e7a430c40bb946d221fb688351d",
    "body": null
      },
      "subtitle": "Se omitió el parámetro \"eventType\", para crear un Antecedente Personal es obligatorio el parametro \"eventType\", por favor agrégalo al inicio del body como en el ejemplo.",
      "status": "412"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
        "code": 412,
        "message": "No se ha enviado un tipo de evento valido",
        "eventId": "48c76fbf91cf46a19ebd948c3d2a6d5e",
        "body": null
      },
      "subtitle": "Error en el valor del parámetro \"eventType\". Para crear un Antecedente Personal el valor permitido del \"eventType\"es \"ANTECEDENT_PERSONAL\".",
      "status": "412"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
    "code": 412,
    "message": "No se ha enviado una accion válida",
    "eventId": "dd24b96692b744e4b97b397e5a3c1dd9",
    "body": null
      },
      "subtitle": "Se omitió el parámetro \"actionType\", para crear un Antecedente Personal es obligatorio el parámetro \"actionType\" que indica la acción de crear, por favor agrégalo al inicio del body como en el ejemplo.",
      "status": "412"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
        "code": 412,
        "message": "No se ha enviado una accion válida",
        "eventId": "a3d1a85dc6b649a2b4cb5ccc10d3e1c3",
        "body": null
      },
      "subtitle": "Error en el valor del parámetro \"actionType\". Para crear un Antecedente Personal el valor permitido del parámetro \"actionType\" es \"CREATE\".",
      "status": "412"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
        "code": 412,
        "message": "Request method 'PUT' not supported",
        "eventId": "81cf71d0bf1b41f391ff690352cad890",
        "body": null
      },
      "subtitle": "El método usado no es compatible con el recurso enviado, recuerde que el método que utiliza este servicio es POST.",
      "status": "412"
    }]

  constructor() { }

  ngOnInit(): void {
  }

}
