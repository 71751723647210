<nav aria-label="...">
    <ul class="pagination">
      <li class="page-item" [ngClass]="disabledShowPrevious ? '' : 'disabled'">
        <a class="page-link pointer-link" (click)="previous()">Previous</a>
      </li>
      <li class="page-item"><a class="page-link">{{actualPage}}</a></li>
      <li class="page-item" [ngClass]="disabledShowNext ? '' : 'disabled'">
        <a class="page-link pointer-link" (click)="next()">Next</a>
      </li>
    </ul>
  </nav>