import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-body-parameters',
  templateUrl: './body-parameters.component.html',
  styleUrls: ['./body-parameters.component.css']
})
export class BodyParametersComponent implements OnInit {

  @Input() parameters: JSON;
  @Input() isFormData: Boolean;

  constructor() { }

  ngOnInit(): void {
  }

}
