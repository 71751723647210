import { Component, OnInit, Input} from '@angular/core';
import { NavbarShowService } from 'src/app/services/navbar-show.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  @Input('class')
  public show: boolean = true;

  public showproduccion: boolean = true;

  constructor(private navbarShow: NavbarShowService) { }

  ngOnInit(): void {
    if(sessionStorage.getItem('environment') == undefined){
      sessionStorage.setItem('environment', "1");
      
    }

    this.environmentShow();
  }

  environmentShow(){
    if(sessionStorage.getItem('environment') == '0' ){
      this.showproduccion = false;
    }
  }


  onChangeClass(): void{
    this.show = !this.show;
    this.navbarShow.navbarShow = this.show;
  }

}
