<div id="layoutSidenav">
    <div id="layoutSidenav_content">
        <br>
        <div class="container">
            <app-main-title [title]="title" [subTitle]="subTitle"></app-main-title>
            <app-main-paragraph [paragraphTitle]="paragraphTitle" [paragraph]="paragraph"></app-main-paragraph>
         
            <h4>La prescripción de incapacidad de un paciente contiene los siguientes atributos</h4>
            <table class="table  table-bordered">
                <thead class="thead-dark">
                    <tr style="padding: 0px !important;">
                        <th scope="col">Nombre</th>
                        <th scope="col">Tipo</th>
                        <th scope="col">Descripción</th>
                        <th scope="col">Ejemplo</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th scope="row">documentType</th>
                        <td>Long</td>
                        <td>
                            Tipo de documento del Paciente:  
                                <ul>
                                    <li>1 = Cédula ciudadanía</li>
                                    <li>2 = Cédula de extranjería</li>
                                    <li>3 = Carné diplomático</li>
                                    <app-parameters-modal-button [type]="'documents'"></app-parameters-modal-button>
                                </ul>
                        </td>
                        <td>2</td> 
                    </tr>
                    <tr>
                        <th scope="row">documentNumber</th>
                        <td>String</td>
                        <td>Número de documento del paciente</td>
                        <td>"123456789"</td>     
                    </tr>
                    <tr>
                        <th scope="row">diagnosticCIE10ID</th>
                        <td>String</td>
                        <td>
                            Código del diagnóstico CIE10
                            <app-parameters-modal-button [type]="'diagnosticcie10'"></app-parameters-modal-button>
                        </td>
                        <td>"A010"</td>     
                    </tr>
                    <tr>
                        <th scope="row">consultationExternalCauseID</th>
                        <td>Long</td>
                        <td>
                            ID de la causa externa
                            <app-parameters-modal-button [type]="'externalcause'"></app-parameters-modal-button>
                        </td>
                        <td>1</td>     
                    </tr>
                    <tr>
                        <th scope="row">treatmentAreaOfApplicationID</th>
                        <td>Long</td>
                        <td>
                            ID de la especialidad
                            <app-parameters-modal-button [type]="'treatmenareatype'"></app-parameters-modal-button>
                        </td>
                        <td>82</td>     
                    </tr>
                    <tr>
                        <th scope="row">reoccurenceTypeID</th>
                        <td>Long</td>
                        <td>
                            ID del tipo de incapacidad
                            <app-parameters-modal-button [type]="'inabilityworktype'"></app-parameters-modal-button>
                        </td>
                        <td>1</td>     
                    </tr>
                    <tr>
                        <th scope="row">startInabilityDate</th>
                        <td>Date</td>
                        <td>Fecha de inicio de la incapacidad</td>
                        <td>2022-09-30</td>     
                    </tr>
                    <tr>
                        <th scope="row">endInabilityDate</th>
                        <td>Date</td>
                        <td>Fecha de fin de la incapacidad</td>
                        <td>2022-10-05</td>     
                    </tr>
                    <tr>
                        <th scope="row">comments</th>
                        <td>String</td>
                        <td>Comentarios de la incapacidad</td>
                        <td>"Dolores abdominales..."</td>     
                    </tr>
                </tbody>
            </table>
            <hr>
            <app-footer></app-footer>
        </div>
    </div>
</div>
