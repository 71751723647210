<ul class="nav nav-tabs" id="classtab">
    <li class="active"><a class="active" data-toggle="tab" *ngIf="show">Prueba</a></li>
    <li class="active"><a class="active" data-toggle="tab" *ngIf="!show">Producción</a></li>
</ul>
<div class="tab-content" id="classcont">
    <div class="tab-pane fade in active show">
        <div class="parameters" *ngIf="show">
            <h5>Tipos de documentos</h5>
            <p ><span class="pg-type type-get">GET</span>https://saludtools.qa.carecloud.com.co/integration/parametric/documents/v1/</p>
            <h5>Estados de la cita</h5>
            <p ><span class="pg-type type-get">GET</span>https://saludtools.qa.carecloud.com.co/integration/parametric/states/v1/</p>
            <h5>EPS</h5>
            <p ><span class="pg-type type-get">GET</span>https://saludtools.qa.carecloud.com.co/integration/parametric/eps/v1/</p>
            <h5>Clínicas</h5>
            <p ><span class="pg-type type-get">GET</span>https://saludtools.qa.carecloud.com.co/integration/parametric/clinics/v1/</p>
            <h5>Tipo de género</h5>
            <p ><span class="pg-type type-get">GET</span>https://saludtools.qa.carecloud.com.co/integration/parametric/genders/v1/</p>
            <h5>Principio Activo</h5>
            <p ><span class="pg-type type-get">GET</span>https://saludtools.qa.carecloud.com.co/integration/parametric/principleact/v1/</p>
            <h5>Concentración de principio activo</h5>
            <p ><span class="pg-type type-get">GET</span>https://saludtools.qa.carecloud.com.co/integration/parametric/atcconcentration/v1/</p>
            <h5>Método de administración</h5>
            <p ><span class="pg-type type-get">GET</span>https://saludtools.qa.carecloud.com.co/integration/parametric/intakemethod/v1/</p>
            <h5>Unidad de frecuencia</h5>
            <p ><span class="pg-type type-get">GET</span>https://saludtools.qa.carecloud.com.co/integration/parametric/frequencyunit/v1/</p>
            <h5>Unidad de duración</h5>
            <p ><span class="pg-type type-get">GET</span>https://saludtools.qa.carecloud.com.co/integration/parametric/durationunit/v1/</p>
            <h5>Id de la atención</h5>
            <p ><span class="pg-type type-get">GET</span>https://saludtools.qa.carecloud.com.co/integration/parametric/encountercommonid/v1/</p>
            <h5>Id de la configuración de historia clinica</h5>
            <p ><span class="pg-type type-get">GET</span>https://saludtools.qa.carecloud.com.co/integration/parametric/configurationclinichistoryid/v1/</p>
            <h5>Modalidad de la consulta</h5>
            <p ><span class="pg-type type-get">GET</span>https://saludtools.qa.carecloud.com.co/integration/parametric/attentionModality/v1/</p>
            <h5>Tipo de consulta</h5>
            <p ><span class="pg-type type-get">GET</span>https://saludtools.qa.carecloud.com.co/integration/parametric/encounterreasontype/v1/</p>
            <h5>Tipo de prescripción</h5>
            <p ><span class="pg-type type-get">GET</span>https://saludtools.qa.carecloud.com.co/integration/parametric/examprescriptiontype/v1/</p>
            <h5>Tipo de examen</h5>
            <p ><span class="pg-type type-get">GET</span>https://saludtools.qa.carecloud.com.co/integration/parametric/medicalexamtype/v1/</p>
            <h5>id de prescripción de examenes</h5>
            <p ><span class="pg-type type-get">GET</span>https://saludtools.qa.carecloud.com.co/integration/parametric/remissioncontainerid/v1/</p>
            <h5>id de causa externa</h5>
            <p ><span class="pg-type type-get">GET</span>https://saludtools.qa.carecloud.com.co/integration/parametric/externalcause/v1/</p>
            <h5>Códigos de diagnósticos cie10</h5>
            <p ><span class="pg-type type-get">GET</span>https://saludtools.qa.carecloud.com.co/integration/parametric/diagnosticcie10/v1/</p>
            <h5>Clasificación de diagnósticos</h5>
            <p ><span class="pg-type type-get">GET</span>https://saludtools.qa.carecloud.com.co/integration/parametric/diagnosticClasification/v1/</p>
            <h5>Tipo de diagnósticos</h5>
            <p ><span class="pg-type type-get">GET</span>https://saludtools.qa.carecloud.com.co/integration/parametric/diagnosticType/v1/</p>
            <h5>Ojo</h5>
            <p ><span class="pg-type type-get">GET</span>https://saludtools.qa.carecloud.com.co/integration/parametric/eyestype/v1/</p>
            <h5>Tipo de diabetes</h5>
            <p ><span class="pg-type type-get">GET</span>https://saludtools.qa.carecloud.com.co/integration/parametric/diabetesType/v1/</p>
            <h5>evaluation physical examination</h5>
            <p ><span class="pg-type type-get">GET</span>https://saludtools.qa.carecloud.com.co/integration/parametric/evaluationenum/v1/</p>
            <h5>Tipos de especialidades</h5>
            <p ><span class="pg-type type-get">GET</span>https://saludtools.qa.carecloud.com.co/integration/parametric/treatmenareatype/v1/</p>
            <h5>Tipos de incapacidad</h5>
            <p ><span class="pg-type type-get">GET</span>https://saludtools.qa.carecloud.com.co/integration/parametric/inabilityworktype/v1/</p>
            <h5>Método de planificación</h5>
            <p ><span class="pg-type type-get">GET</span>https://saludtools.qa.carecloud.com.co/integration/parametric/contraceptivetype/v1/</p> 
            <h5>Nombre amigable</h5>
            <p ><span class="pg-type type-get">GET</span>https://saludtools.qa.carecloud.com.co/integration/parametric/friendlyname/v1/</p>
            <h5>Id de grupo de antecedente</h5>
            <p ><span class="pg-type type-get">GET</span>https://saludtools.qa.carecloud.com.co/integration/parametric/personalantecedentsgroup/v1/</p>
            <h5>id de antecedentes personales</h5>
            <p ><span class="pg-type type-get">GET</span>https://saludtools.qa.carecloud.com.co/integration/parametric/antecedentspersonal/v1/</p>
            <h5>Parentesco con el familiar</h5>
            <p ><span class="pg-type type-get">GET</span>https://saludtools.qa.carecloud.com.co/integration/parametric/familiarRelationshipType/v1/</p>
        </div>

        <div class="parameters" *ngIf="!show">
            <h5>Tipos de documentos</h5>
            <p ><span class="pg-type type-get">GET</span>https://saludtools.carecloud.com.co/integration/parametric/documents/v1/</p>
            <h5>Estados de la cita</h5>
            <p ><span class="pg-type type-get">GET</span>https://saludtools.carecloud.com.co/integration/parametric/states/v1/</p>
            <h5>EPS</h5>
            <p ><span class="pg-type type-get">GET</span>https://saludtools.carecloud.com.co/integration/parametric/eps/v1/</p>
            <h5>Clinicas</h5>
            <p ><span class="pg-type type-get">GET</span>https://saludtools.carecloud.com.co/integration/parametric/clinics/v1/</p>
            <h5>Tipo de género</h5>
            <p ><span class="pg-type type-get">GET</span>https://saludtools.carecloud.com.co/integration/parametric/genders/v1/</p>
            <h5>Principio Activo</h5>
            <p ><span class="pg-type type-get">GET</span>https://saludtools.carecloud.com.co/integration/parametric/principleact/v1/</p>
            <h5>Concentración de principio activo</h5>
            <p ><span class="pg-type type-get">GET</span>https://saludtools.carecloud.com.co/integration/parametric/atcconcentration/v1/</p>
            <h5>Método de administración</h5>
            <p ><span class="pg-type type-get">GET</span>https://saludtools.carecloud.com.co/integration/parametric/intakemethod/v1/</p>
            <h5>Unidad de frecuencia</h5>
            <p ><span class="pg-type type-get">GET</span>https://saludtools.carecloud.com.co/integration/parametric/frequencyunit/v1/</p>
            <h5>Unidad de duración</h5>
            <p ><span class="pg-type type-get">GET</span>https://saludtools.carecloud.com.co/integration/parametric/durationunit/v1/</p>
            <h5>Id de la atención</h5>
            <p ><span class="pg-type type-get">GET</span>https://saludtools.carecloud.com.co/integration/parametric/encountercommonid/v1/</p>
            <h5>Id de la configuración de historia clinica</h5>
            <p ><span class="pg-type type-get">GET</span>https://saludtools.carecloud.com.co/integration/parametric/configurationclinichistoryid/v1/</p>
            <h5>Modalidad de la consulta</h5>
            <p ><span class="pg-type type-get">GET</span>https://saludtools.carecloud.com.co/integration/parametric/attentionModality/v1/</p>
            <h5>Tipo de consulta</h5>
            <p ><span class="pg-type type-get">GET</span>https://saludtools.carecloud.com.co/integration/parametric/encounterreasontype/v1/</p>
            <h5>Tipo de prescripción</h5>
            <p ><span class="pg-type type-get">GET</span>https://saludtools.carecloud.com.co/integration/parametric/examprescriptiontype/v1/</p>
            <h5>Tipo de examen</h5>
            <p ><span class="pg-type type-get">GET</span>https://saludtools.carecloud.com.co/integration/parametric/medicalexamtype/v1/</p>
            <h5>id de prescripción de examenes</h5>
            <p ><span class="pg-type type-get">GET</span>https://saludtools.carecloud.com.co/integration/parametric/remissioncontainerid/v1/</p>
            <h5>id de causa externa</h5>
            <p ><span class="pg-type type-get">GET</span>https://saludtools.carecloud.com.co/integration/parametric/externalcause/v1/</p>
            <h5>Códigos de diagnósticos cie10</h5>
            <p ><span class="pg-type type-get">GET</span>https://saludtools.carecloud.com.co/integration/parametric/diagnosticcie10/v1/</p>
            <h5>Clasificación de diagnósticos</h5>
            <p ><span class="pg-type type-get">GET</span>https://saludtools.carecloud.com.co/integration/parametric/diagnosticClasification/v1/</p>
            <h5>Tipo de diagnósticos</h5>
            <p ><span class="pg-type type-get">GET</span>https://saludtools.carecloud.com.co/integration/parametric/diagnosticType/v1/</p>
            <h5>Ojo</h5>
            <p ><span class="pg-type type-get">GET</span>https://saludtools.carecloud.com.co/integration/parametric/eyestype/v1/</p>
            <h5>Tipo de diabetes</h5>
            <p ><span class="pg-type type-get">GET</span>https://saludtools.carecloud.com.co/integration/parametric/diabetesType/v1/</p>
            <h5>evaluation physical examination</h5>
            <p ><span class="pg-type type-get">GET</span>https://saludtools.carecloud.com.co/integration/parametric/evaluationenum/v1/</p>
            <h5>Tipos de especialidades</h5>
            <p ><span class="pg-type type-get">GET</span>https://saludtools.carecloud.com.co/integration/parametric/treatmenareatype/v1/</p>
            <h5>Tipos de incapacidad</h5>
            <p ><span class="pg-type type-get">GET</span>https://saludtools.carecloud.com.co/integration/parametric/inabilityworktype/v1/</p> 
            <h5>Método de planificación</h5>
            <p ><span class="pg-type type-get">GET</span>https://saludtools.carecloud.com.co/integration/parametric/contraceptivetype/v1/</p>
            <h5>Nombre amigable</h5>
            <p ><span class="pg-type type-get">GET</span>https://saludtools.carecloud.com.co/integration/parametric/friendlyname/v1/</p>
            <h5>Id de grupo de antecedente</h5>
            <p ><span class="pg-type type-get">GET</span>https://saludtools.carecloud.com.co/integration/parametric/personalantecedentsgroup/v1/</p>
            <h5>id de antecedentes personales</h5>
            <p ><span class="pg-type type-get">GET</span>https://saludtools.carecloud.com.co/integration/parametric/antecedentspersonal/v1/</p>
            <h5>Parentesco con el familiar</h5>
            <p ><span class="pg-type type-get">GET</span>https://saludtools.carecloud.com.co/integration/parametric/familiarRelationshipType/v1/</p>            
        </div>
    </div>
</div>
<br>