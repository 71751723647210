import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-parameters-modal-content',
  templateUrl: './parameters-modal-content.component.html',
  styleUrls: ['./parameters-modal-content.component.css']
})
export class ParametersModalContentComponent implements OnInit {

  @Input() json;
  @Input() title:string = "Parametro";

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

}
