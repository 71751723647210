import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationError } from 'src/app/models/authentication-error';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ParametersService } from 'src/app/services/parameters.service';
import { ParametersModalEncounterFormComponent } from '../parameters-modal-encounter-form/parameters-modal-encounter-form.component';
import { ParametersModalFormComponent } from '../parameters-modal-form/parameters-modal-form.component';
import { ParametersModalPaginatorComponent } from '../parameters-modal-paginator/parameters-modal-paginator.component';
import { ParametersModalContentComponent } from '../parameters-modal/parameters-modal-content/parameters-modal-content.component';

@Component({
  selector: 'app-authentication-redirection',
  templateUrl: './authentication-redirection.component.html',
  styleUrls: ['./authentication-redirection.component.css']
})
export class AuthenticationRedirectionComponent implements OnInit {

  authenticationForm: FormGroup;
  submitted = false;
  json: any;
  showClass: boolean = false;
  showClass2: boolean = false;

  @Input() type:string;
  @Input() title:string;
  @Input() actPrinciple: string = null;



  onSubmit(){
    if (this.authenticationForm.invalid) {
      if (this.form.key.errors) {
        this.showClass = true;
      }

      if (this.form.secret.errors) {
        this.showClass2 = true;
      }
      return;
    }

    this.authenticationService.authentication(this.authenticationForm.value).subscribe(
      response => {
        localStorage.setItem('access_token', response.access_token);
        this.json = response;
        this.activeModal.close();

        if(this.type == "principleact" || this.type == "eps" || this.type == "commercialname" || this.type == "classificationtype" || this.type == "unittype" || this.type == "paraclinictype"){
          this.parametersService.getParameters(this.type, "null", "").subscribe(
            resp => {
            const modalRef = this.modalService.open(ParametersModalPaginatorComponent);
            modalRef.componentInstance.json = resp;
            modalRef.componentInstance.title = this.title;
            modalRef.componentInstance.type = this.type;
          },
          error =>{
            const modalRef = this.modalService.open(AuthenticationRedirectionComponent);
            modalRef.componentInstance.type = this.type;
            modalRef.componentInstance.title = this.title;
          },
          );} else if(this.type == "encountercommonid" ){

            const modalRef = this.modalService.open(ParametersModalEncounterFormComponent);
            modalRef.componentInstance.type = this.type;
            modalRef.componentInstance.title = this.title;
            
          }   else if(this.type != "atcconcentration" ){
            this.parametersService.getParameters(this.type, "null", "").subscribe(
              resp => {
              const modalRef = this.modalService.open(ParametersModalContentComponent);
              modalRef.componentInstance.json = resp;
              modalRef.componentInstance.title = this.title;
            },
            error =>{
              const modalRef = this.modalService.open(AuthenticationRedirectionComponent);
              modalRef.componentInstance.type = this.type;
              modalRef.componentInstance.title = this.title;
            },
            );} else {
            const modalRef = this.modalService.open(ParametersModalFormComponent);
            modalRef.componentInstance.type = this.type;
            modalRef.componentInstance.title = this.title;
          }
      },
      (error: AuthenticationError) => {
        this.json = error.error.message
      }
    )
    this.submitted = true;
  }

  constructor(public activeModal: NgbActiveModal, private formBuilder: FormBuilder, private authenticationService: AuthenticationService, private parametersService: ParametersService, private modalService: NgbModal) { }

  ngOnInit(): void {
    this.authenticationForm = this.formBuilder.group(
      {
        key: ["", Validators.required],
        secret: ["", Validators.required],
      },

    );
  }

  get form() {
    return this.authenticationForm.controls;
  }

  onReset() {
    this.submitted = false;
    this.authenticationForm.reset();
    this.showClass = false;
    this.showClass2 = false;
  }



}
