import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-apikey-create',
  templateUrl: './apikey-create.component.html',
  styleUrls: ['./apikey-create.component.css']
})
export class ApikeyCreateComponent implements OnInit {

  title: string ="Crear ApiKey";
  subTitle: string ="Cree un ApiKey en cualquier momento y empiece a utilizar los servicios de integración."

  paragraphTitle: string ="¿Cómo crear un ApiKey?";
  paragraph: string ="Crear una ApiKey es muy fácil. La puede crear directamente desde SaludTool. Solo debe seguir los siguientes pasos:";

  imgCard =   [
    {
      "imgCardTitle": "Paso 1: Ingrese a Saludtools y coloque su usuario y contraseña para loguearse normalmente",
      "imgCardhref": "assets/img/ApiKey/Paso1.png",
    },
    {
      "imgCardTitle": "Paso 2: Dentro de Saludtools hacer clic sobre su nombre de perfil para desplegar el menú",
      "imgCardhref": "assets/img/ApiKey/Paso2.png"
    },
    {
      "imgCardTitle": "Paso 3: En el menú desplegado hacer clic en configuración",
      "imgCardhref": "assets/img/ApiKey/Paso3.png"
    },
    {
      "imgCardTitle": "Paso 4: Ir al panel izquierdo y hacer clic en integraciones.",
      "imgCardhref": "assets/img/ApiKey/Paso4.png"
    },
    {
      "imgCardTitle": "Paso 5: En el menú desplegado hacer clic en ApI Key.",
      "imgCardhref": "assets/img/ApiKey/Paso5.png"
    },
    {
      "imgCardTitle": "Paso 6: Hacer clic en crear ApiKey.",
      "imgCardhref": "assets/img/ApiKey/Paso6.png"
    },
    {
      "imgCardTitle": "Felicidades, ya se creó la ApyKey correctamente. Haga clic en aceptar para ver sus nuevas credenciales.",
      "imgCardhref": "assets/img/ApiKey/Paso7.png"
    },
    {
      "imgCardTitle": "Así debe lucir su ApiKey.",
      "imgCardhref": "assets/img/ApiKey/ApiKey.png",
      "imgCardSub": "- Key: Es el nombre de usuario y está constituido por la palabra \"STAKOA\" seguido de 24 caracteres. Secret: Es la clave secreta encriptada y segura para su uso."
    }
    ]
  constructor() { }

  ngOnInit(): void {
  }

}
