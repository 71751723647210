import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home/home.component';
import { ComponentsModule } from '../components/components.module';
import { ApiKeyComponent } from './api-key/api-key.component';
import { PatientCreateComponent } from './patient-create/patient-create.component';
import { PatientComponent } from './patient/patient.component';
import { AppointmentComponent } from './appointment/appointment.component';
import { WebhookComponent } from './webhook/webhook.component';
import { ApikeyCreateComponent } from './apikey-create/apikey-create.component';
import { ApikeyDeleteComponent } from './apikey-delete/apikey-delete.component';
import { TokenComponent } from './token/token.component';
import { PatientReadComponent } from './patient-read/patient-read.component';
import { PatientUpdateComponent } from './patient-update/patient-update.component';
import { PatientSearchComponent } from './patient-search/patient-search.component';
import { AppointmentCreateComponent } from './appointment-create/appointment-create.component';
import { PatientDeleteComponent } from './patient-delete/patient-delete.component';
import { AppointmentReadComponent } from './appointment-read/appointment-read.component';
import { AppointmentUpdateComponent } from './appointment-update/appointment-update.component';
import { AppointmentSearchComponent } from './appointment-search/appointment-search.component';
import { AppointmentDeleteComponent } from './appointment-delete/appointment-delete.component';
import { ParameterComponent } from './parameter/parameter.component';
import { MedicineComponent } from './medicine/medicine.component';
import { MedicineCreateComponent } from './medicine-create/medicine-create.component';
import { MedicineReadComponent } from './medicine-read/medicine-read.component';
import { MedicineUpdateComponent } from './medicine-update/medicine-update.component';
import { MedicineDeleteComponent } from './medicine-delete/medicine-delete.component';
import { ClinicHistoryComponent } from './clinic-history/clinic-history.component';
import { ClinicHistoryReadComponent } from './clinic-history-read/clinic-history-read.component';
import { ClinicalLaboratoriesComponent } from './clinical-laboratories/clinical-laboratories.component';
import { ClinicHistoryCreateComponent } from './clinic-history-create/clinic-history-create.component';
import { ClinicalLaboratoriesCreateComponent } from './clinical-laboratories-create/clinical-laboratories-create.component';
import { ClinicalLaboratoriesReadComponent } from './clinical-laboratories-read/clinical-laboratories-read.component';
import { ExamsPrescriptionComponent } from './exams-prescription/exams-prescription.component';
import { ExamsPrescriptionCreateComponent } from './exams-prescription-create/exams-prescription-create.component';
import { ExamsPrescriptionReadComponent } from './exams-prescription-read/exams-prescription-read.component';
import { PatientFileCreateComponent } from './patient-file-create/patient-file-create.component';
import { PatientFileComponent } from './patient-file/patient-file.component';
import { PatientFileSearchComponent } from './patient-file-search/patient-file-search.component';
import { PatientFileReadComponent } from './patient-file-read/patient-file-read.component';
import { PatientInabilityWorkComponent } from './patient-inability-work/patient-inability-work.component';
import { PatientInabilityWorkCreateComponent } from './patient-inability-work-create/patient-inability-work-create.component';
import { PatientInabilityWorkReadComponent } from './patient-inability-work-read/patient-inability-work-read.component';
import { PatientInabilityWorkSearchComponent } from './patient-inability-work-search/patient-inability-work-search.component';
import { ExamsResultsComponent } from './exams-results/exams-results.component';
import { ExamsResultsCreateComponent } from './exams-results-create/exams-results-create.component';
import { ExamsResultsReadComponent } from './exams-results-read/exams-results-read.component';
import { ExamsResultsSearchComponent } from './exams-results-search/exams-results-search.component';
import { PatientGinecoInformationComponent } from './patient-gineco-information/patient-gineco-information.component';
import { PatientGinecoInformationCreateComponent } from './patient-gineco-information-create/patient-gineco-information-create.component';
import { PatientGinecoInformationReadComponent } from './patient-gineco-information-read/patient-gineco-information-read.component';
import { PatientFamiliarAntecedentComponent } from './patient-familiar-antecedent/patient-familiar-antecedent.component';
import { PatientFamiliarAntecedentCreateComponent } from './patient-familiar-antecedent-create/patient-familiar-antecedent-create.component';
import { PatientFamiliarAntecedentReadComponent } from './patient-familiar-antecedent-read/patient-familiar-antecedent-read.component';
import { PatientFamiliarAntecedentSearchComponent } from './patient-familiar-antecedent-search/patient-familiar-antecedent-search.component';
import { MedicineReadLastComponent } from './medicine-read-last/medicine-read-last.component';
import { AntecedentPersonalComponent } from './antecedent-personal/antecedent-personal.component';
import { AntecedentPersonalCreateComponent } from './antecedent-personal-create/antecedent-personal-create.component';
import { AntecedentPersonalReadComponent } from './antecedent-personal-read/antecedent-personal-read.component';


@NgModule({
  declarations: [
    HomeComponent,
    ApiKeyComponent,
    PatientCreateComponent,
    PatientComponent,
    AppointmentComponent,
    WebhookComponent,
    ApikeyCreateComponent,
    ApikeyDeleteComponent,
    TokenComponent,
    PatientReadComponent,
    PatientUpdateComponent,
    PatientSearchComponent,
    AppointmentCreateComponent,
    PatientDeleteComponent,
    AppointmentReadComponent,
    AppointmentUpdateComponent,
    AppointmentSearchComponent,
    AppointmentDeleteComponent,
    ParameterComponent,
    MedicineComponent,
    MedicineCreateComponent,
    MedicineReadComponent,
    MedicineUpdateComponent,
    MedicineDeleteComponent,
    ClinicHistoryComponent,
    ClinicHistoryCreateComponent,
    ClinicHistoryReadComponent,
    ClinicalLaboratoriesComponent,
    ClinicalLaboratoriesCreateComponent,
    ClinicalLaboratoriesReadComponent,
    PatientFileCreateComponent,
    PatientFileComponent,
    PatientFileSearchComponent,
    PatientFileReadComponent,
    ExamsPrescriptionComponent,
    ExamsPrescriptionCreateComponent,
    ExamsPrescriptionReadComponent,
    PatientInabilityWorkComponent,
    PatientInabilityWorkCreateComponent,
    PatientInabilityWorkReadComponent,
    PatientInabilityWorkSearchComponent,
    ExamsResultsComponent,
    ExamsResultsCreateComponent,
    ExamsResultsReadComponent,
    ExamsResultsSearchComponent,
    PatientGinecoInformationComponent,
    PatientGinecoInformationCreateComponent,
    PatientGinecoInformationReadComponent,
    MedicineReadLastComponent,
    AntecedentPersonalComponent,
    AntecedentPersonalCreateComponent,
    AntecedentPersonalReadComponent,
    PatientFamiliarAntecedentComponent,
    PatientFamiliarAntecedentCreateComponent,
    PatientFamiliarAntecedentReadComponent,
    PatientFamiliarAntecedentSearchComponent,
    MedicineReadLastComponent
  ],
  imports: [
    CommonModule,
    ComponentsModule
  ]
})
export class PagesModule { }
