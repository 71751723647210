import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavbarComponent } from './navbar/navbar.component';
import { MainTitleComponent } from './main-title/main-title.component';
import { RouterModule } from '@angular/router';
import { TableComponent } from './table/table.component';
import { AuthenticationNavTabsComponent } from './authentication-nav-tabs/authentication-nav-tabs.component';
import { EventNavTabsComponent } from './event-nav-tabs/event-nav-tabs.component';
import { FooterComponent } from './footer/footer.component';
import { BodyParametersComponent } from './body-parameters/body-parameters.component';
import { ExamplesCodeComponent } from './examples-code/examples-code.component';
import { ImgCardComponent } from './img-card/img-card.component';
import { MainParagraphComponent } from './main-paragraph/main-paragraph.component';
import { ExamplesCurlComponent } from './examples-curl/examples-curl.component';
import { AuthenticationComponent } from './authentication/authentication.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ParametersModalButtonComponent } from './parameters-modal/parameters-modal-button/parameters-modal-button.component';
import { ParametersModalContentComponent } from './parameters-modal/parameters-modal-content/parameters-modal-content.component';
import { HighlightModule, HIGHLIGHT_OPTIONS } from 'ngx-highlightjs';
import { DropdownComponent } from './dropdown/dropdown.component';
import { AuthenticationRedirectionComponent } from './authentication-redirection/authentication-redirection.component';
import { ParametersNavTabsComponent } from './parameters-nav-tabs/parameters-nav-tabs.component';
import { ParametersModalFormComponent } from './parameters-modal-form/parameters-modal-form.component';
import { PaginatorComponent } from './paginator/paginator.component';
import { ParametersModalPaginatorComponent } from './parameters-modal-paginator/parameters-modal-paginator.component';
import { ParametersModalEncounterFormComponent } from './parameters-modal-encounter-form/parameters-modal-encounter-form.component';
import { ParametersModalEncounterContentComponent } from './parameters-modal-encounter-content/parameters-modal-encounter-content.component';




@NgModule({
  declarations: [
    NavbarComponent,
    MainTitleComponent,
    TableComponent,
    AuthenticationNavTabsComponent,
    EventNavTabsComponent,
    FooterComponent,
    BodyParametersComponent,
    ExamplesCodeComponent,
    ImgCardComponent,
    MainParagraphComponent,
    ExamplesCurlComponent,
    AuthenticationComponent,
    ParametersModalButtonComponent,
    ParametersModalContentComponent,
    DropdownComponent,
    AuthenticationRedirectionComponent,
    ParametersNavTabsComponent,
    ParametersModalFormComponent,
    PaginatorComponent,
    ParametersModalPaginatorComponent,
    ParametersModalEncounterFormComponent,
    ParametersModalEncounterContentComponent,
  ],
  providers: [
    {
      provide: HIGHLIGHT_OPTIONS,
      useValue: {
        coreLibraryLoader: () => import('highlight.js/lib/core'),
        lineNumbersLoader: () => import('highlightjs-line-numbers.js'),
        languages: {
          json: () => import('highlight.js/lib/languages/json'),
        }
      }
    }
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    HighlightModule,
    FormsModule
    
  ],
  exports: [
    NavbarComponent,
    MainTitleComponent,
    TableComponent,
    AuthenticationNavTabsComponent,
    EventNavTabsComponent,
    FooterComponent,
    BodyParametersComponent,
    ExamplesCodeComponent,
    ImgCardComponent,
    MainParagraphComponent,
    ExamplesCurlComponent,
    AuthenticationComponent,
    ParametersModalButtonComponent,
    ParametersNavTabsComponent,
    PaginatorComponent
  ],
})
export class ComponentsModule { }
