import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-apikey-delete',
  templateUrl: './apikey-delete.component.html',
  styleUrls: ['./apikey-delete.component.css']
})
export class ApikeyDeleteComponent implements OnInit {

  title: string ="Borrar ApiKey";
  subTitle: string ="Borre un ApiKey totalmente del sistema. (más adelante podra crear una nueva ApiKey)."

  paragraphTitle: string ="¿Cómo borrar un ApiKey?";
  paragraph: string ="Si ya no desea usar el servicio de integración puede borrar su ApiKey cuando lo desee. Solo debe seguir los siguientes pasos:";

  imgCard =   [
    {
      "imgCardTitle": "Paso 1: Entre en Saludtools y coloque su usuario y contraseña para loguearse normalmente",
      "imgCardhref": "assets/img/ApiKey/Paso1.png",
    },
    {
      "imgCardTitle": "Paso 2: Dentro de Saludtools hacer clic sobre su nombre de perfil para desplegar el menú",
      "imgCardhref": "assets/img/ApiKey/Paso2.png"
    },
    {
      "imgCardTitle": "Paso 3: En el menú desplegado hacer clic en configuración",
      "imgCardhref": "assets/img/ApiKey/Paso3.png"
    },
    {
      "imgCardTitle": "Paso 4: Ir al panel izquierdo y hacer clic en integraciones.",
      "imgCardhref": "assets/img/ApiKey/Paso4.png"
    },
    {
      "imgCardTitle": "Paso 5: En el menú desplegado hacer clic en ApI Key.",
      "imgCardhref": "assets/img/ApiKey/Paso5.png"
    },
    {
      "imgCardTitle": "Paso 6: Hacer clic en Borrar ApiKey.",
      "imgCardhref": "assets/img/ApiKey/Paso6B.png"
    },
    {
      "imgCardTitle": "Paso 7: Hacer clic en continuar para confirmar el borrado de su ApiKey.",
      "imgCardhref": "assets/img/ApiKey/Paso7B.png"
    },
    {
      "imgCardTitle": "La ApiKey Fue borrada exitosamente, hacer clic en aceptar para volver a la pantalla principal de ApiKey donde observará que ya no existe.",
      "imgCardhref": "assets/img/ApiKey/Paso8.png",
    }
    ]

  constructor() { }

  ngOnInit(): void {
  }

}
