import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-clinical-laboratories',
  templateUrl: './clinical-laboratories.component.html',
  styleUrls: ['./clinical-laboratories.component.css']
})
export class ClinicalLaboratoriesComponent implements OnInit {

  title: string = "Laboratorios clínicos";
  subTitle: string = "Consumiendo los servicios especificados desde la API de Saludtools usted puede crear y leer resultados de laboratorios clínicos, permitiendo al usuario una integración completa."


  paragraphTitle: string = "¿La información de las atenciones es confidencial?";
  paragraph: string = "TOTALMENTE. Saludtools es un sistema de primer nivel que garantiza totalmente la confidencialidad de la información personal de sus pacientes. NUNCA se compartirá con un tercero o se comercializará sin la autorización expresa del médico o el paciente. Este compromiso se documenta en nuestra licencia de uso, que deja este punto totalmente claro.";

  constructor() { /* TODO document why this constructor is empty */  }

  ngOnInit(): void {
    // TODO document why this method 'ngOnInit' is empty
  
  }

}
