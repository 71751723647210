<div id="layoutSidenav">
    <div id="layoutSidenav_content">
        <br>
        <div class="container">
            <app-main-title [title]="title" [subTitle]="subTitle"></app-main-title>
            <app-main-paragraph [paragraphTitle]="paragraphTitle" [paragraph]="paragraph"></app-main-paragraph>

            <h4>Un laboratorio clínico contiene los siguientes atributos</h4>
            <table class="table  table-bordered">
                <thead class="thead-dark">
                    <tr style="padding: 0px !important;">
                        <th scope="col">Nombre</th>
                        <th scope="col">Tipo</th>
                        <th scope="col">Descripción</th>
                        <th scope="col">Ejemplo</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th scope="row">encounterId</th>
                        <td>Long</td>
                        <td>Si el resultado esta asociada a una cita debe colocar el id de la cita</td>
                        <td>2345</td>
                    </tr>
                    <tr>
                        <th scope="row">patientDocumentType</th>
                        <td>Long</td>
                        <td>
                            Tipo de documento del Paciente:
                            <ul>
                                <li>1 = Cédula ciudadanía</li>
                                <li>2 = Cédula de extranjería</li>
                                <li>3 = Carné diplomático</li>
                                <app-parameters-modal-button [type]="'documents'"></app-parameters-modal-button>
                            </ul>
                        </td>
                        <td>2</td>
                    </tr>
                    <tr>
                        <th scope="row">documentNumber</th>
                        <td>String</td>
                        <td>Número de documento del paciente</td>
                        <td>"123456789"</td>
                    </tr>

                </tbody>
            </table>
            <h4>ParaclinicBasicResponseDTO contiene los siguientes atributos</h4>
            <table class="table  table-bordered">
                <thead class="thead-dark">
                    <tr style="padding: 0px !important;">
                        <th scope="col">Nombre</th>
                        <th scope="col">Tipo</th>
                        <th scope="col">Descripción</th>
                        <th scope="col">Ejemplo</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th scope="row">value</th>
                        <td>Long</td>
                        <td>Valor del resultado del laboratorio clínicio</td>
                        <td>70</td>
                    </tr>
                    <tr>
                        <th scope="row">classification</th>
                        <td>Long</td>
                        <td>Este dato se recibirá como el ID de la clasificación <br>
                            <app-parameters-modal-button [type]="'classificationtype'"></app-parameters-modal-button>
                        </td>
                        <td>123</td>


                    </tr>
                    <tr>
                        <th scope="row">typeId</th>
                        <td>Long</td>
                        <td>Este dato se recibirá como el tipo del paraclínico <br>
                            <app-parameters-modal-button [type]="'paraclinictype'"></app-parameters-modal-button></td>
                        <td>12</td>
                    </tr>
                    <tr>
                        <th scope="row">unitId</th>
                        <td>Long</td>
                        <td>Este dato se recibirá como el ID de la unidad <br>
                            <app-parameters-modal-button [type]="'unittype'"></app-parameters-modal-button>
                        </td>
                        <td>8</td>
                    </tr>
                    <tr>
                        <th scope="row">comments</th>
                        <td>String</td>
                        <td>En este campo se debe registrar el texto que se registre de forma adicional</td>
                        <td>82</td>
                    </tr>
                    <tr>
                        <th scope="row">examDate</th>
                        <td>String</td>
                        <td>Este registro debe hacerse con el formato DD-MM-AAAA</td>
                        <td>"12-02-2022"</td>
                    </tr>

                </tbody>
            </table>
            <hr>
            <app-footer></app-footer>
        </div>
    </div>
</div>