<div id="layoutSidenav">
    <div id="layoutSidenav_content">
        <br>
        <div class="container">
            <app-main-title [title]="title" [subTitle]="subTitle"></app-main-title>
            <app-main-paragraph [paragraphTitle]="paragraphTitle" [paragraph]="paragraph"></app-main-paragraph>
         
            <h4>Un paciente contiene los siguientes atributos</h4>
                    <table class="table  table-bordered">
                        <thead class="thead-dark">
                            <tr style="padding: 0px !important;">
                                <th scope="col">Nombre</th>
                                <th scope="col">Tipo</th>
                                <th scope="col">Descripción</th>
                                <th scope="col">Ejemplo</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th scope="row">firstName</th>
                                <td>String</td>
                                <td>Primer Nombre del Paciente</td>
                                <td>"Ana"</td>
                            </tr>
                            <tr>
                                <th scope="row">secondName</th>
                                <td>String</td>
                                <td>Segundo Nombre del paciente</td>
                                <td>"Luisa"</td>
                            </tr>
                            <tr>
                                <th scope="row">firstLastName</th>
                                <td>String</td>
                                <td>Primer Apellido del paciente</td>
                                <td>"Gonzalez"</td>
                            </tr>
                            <tr>
                                <th scope="row">secondLastName</th>
                                <td>String</td>
                                <td>Segundo Apellido del paciente</td>
                                <td>"Perez"</td>     
                            </tr>
                            <tr>
                                <th scope="row">birthDate</th>
                                <td>LocalDate</td>
                                <td>Fecha de nacimiento del paciente ("yyyy-mm-dd")</td>
                                <td>"1998-12-31"</td> 
                            </tr>
                            <tr>
                                <th scope="row">gender</th>
                                <td>Long</td>
                                <td>
                                    Género del paciente:  
                                        <ul>
                                            <li>1 = Masculino</li>
                                            <li>2 = Femenino</li>
                                            <li>3 = Indeterminado</li>
                                            <app-parameters-modal-button [type]="'genders'"></app-parameters-modal-button>
                                        </ul>
                                </td>
                                <td>2</td> 
                            </tr>
                            <tr>
                                <th scope="row">documentType</th>
                                <td>Long</td>
                                <td>
                                    Tipo de documento del Paciente:  
                                        <ul>
                                            <li>1 = Cédula ciudadanía</li>
                                            <li>2 = Cédula de extranjería</li>
                                            <li>3 = Carné diplomático</li>
                                            <app-parameters-modal-button [type]="'documents'"></app-parameters-modal-button>
                                        </ul>
                                </td>
                                <td>2</td> 
                            </tr>
                            <tr>
                                <th scope="row">documentNumber</th>
                                <td>String</td>
                                <td>Número de documento del paciente</td>
                                <td>"123456789"</td>     
                            </tr>
                            <tr>
                                <th scope="row">phone</th>
                                <td>String</td>
                                <td>Número de teléfono del paciente</td>
                                <td>"0123456789"</td>     
                            </tr>
                            <tr>
                                <th scope="row">cellPhone</th>
                                <td>String</td>
                                <td>Número de celular del paciente</td>
                                <td>"0123456789"</td>     
                            </tr>
                            <tr>
                                <th scope="row">email</th>
                                <td>String</td>
                                <td>Correo electrónico del paciente</td>
                                <td>"ejemplo@empresaejemplo.com"</td>     
                            </tr>
                            <tr>
                                <th scope="row">eps</th>
                                <td>Long</td>
                                <td>
                                    Eps del Paciente:
                                    <app-parameters-modal-button [type]="'eps'"></app-parameters-modal-button>
                                </td>
                                <td>3</td> 
                            </tr>
                            <tr>
                                <th scope="row">habeasData</th>
                                <td>Boolean</td>
                                <td>
                                    (Habeas Data) autorización del paciente de recibir comunicación.
                                </td>
                                <td>true</td> 
                            </tr>
                        </tbody>
                    </table>
                    <hr>
                    <app-footer></app-footer>
        </div>
    </div>
</div>
