import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-patient-familiar-antecedent-create',
  templateUrl: './patient-familiar-antecedent-create.component.html',
  styleUrls: ['./patient-familiar-antecedent-create.component.css']
})
export class PatientFamiliarAntecedentCreateComponent implements OnInit {

  title: string = "Crear Antecedentes Familiares";
  subTitle: string = "Cree un nuevo Antecedente Familiar"

  paragraphTitle: string = "¿Cómo crear un Antecedente Familiar?";
  paragraph: string = "Para crear un Antecedente Familiar, primero debe tener un Token de acceso activo. Si su Token se venció, o aún no ha generado un Token de acceso. Genere un nuevo Token y consuma el evento de crear un Antecedente Familiar.";

  parameters = [
    {
      "Parameters": "eventType",
      "requerido": true,
      "Type": "String",
      "Description": "Es el tipo de evento que se quiere invocar en este caso debe ser \"FAMILY_HISTORY\"."
    },
    {
      "Parameters": "actionType",
      "requerido": true,
      "Type": "String",
      "Description": "Es la acción que se quiere realizar para crear un Antecedente Familiar debe ser \"CREATE\""
    },
    {
      "Parameters": "documentType",
      "requerido": true,
      "Type": "Long",
      "Description": "Id del tipo de documento de identidad del paciente"
    },
    {
      "Parameters": "documentNumber",
      "requerido": true,
      "Type": "String",
      "Description": "Número del documento de identidad del paciente"
    },
    {
      "Parameters": "encounterCommonInfo",
      "requerido": true,
      "Type": "Long",
      "Description": "ID de la historia clínica"
    },
    {
      "Parameters": "familiarRelationshipType",
      "requerido": true,
      "Type": "Long",
      "Description": "Parentesco con el familiar"
    },
    {
      "Parameters": "diagnosticText",
      "requerido": true,
      "Type": "String",
      "Description": "Detalles del diagnóstico, No se debe enviar el campo diagnosticType ya que se tomará este texto como el diagnóstico a registrar. (máximo 255 caracteres)"
    },
    {
      "Parameters": "diagnosticType",
      "requerido": true,
      "Type": "String",
      "Description": "Código del diagnóstico CIE10, No se debe enviar el campo diagnosticText ya que se tomará este código como el diagnóstico a registrar. (máximo 10 caracteres)"
    },
    {
      "Parameters": "diagnosisDate",
      "requerido": true,
      "Type": "LocalDate",
      "Description": "Fecha de diagnosis (yyyy-mm-dd)"
    },
    {
      "Parameters": "comments",
      "requerido": false,
      "Type": "String",
      "Description": "Comentarios del diagnóstico, (máximo 255 caracteres)"
    },   
  ]

  jsonEjemplos =
    [{
      "title": "Body",
      "body":{
        "eventType":"FAMILY_HISTORY",
        "actionType":"CREATE",
        "body": {
          "documentType": 1,
          "documentNumber": "123456789",
          "encounterCommonInfo": 3068372,
          "familiarRelationshipType": 10,
          "diagnosticText": "diagnostico de prueba",
          "diagnosticType":null,
          "diagnosisDate": "2022-11-04",
          "comments": "comentarios hijo"
        }
    },
      "subtitle": "Body para crear un Antecedente Familiar con el Id de la  historia clínica, sustituya los parámetros por los del Antecedente Familiar a crear.Los campos  diagnosticText y diagnosticType son mutuamente excluyentes, por lo tanto solo se debe enviar unos de estos dos campos por petición"
    }
    ]

  curlEjemplo = {
    "title": "cURL",
    "bodyPrueba": "curl --location --request POST 'https://saludtools.qa.carecloud.com.co/integration/sync/event/v1/' --header 'Authorization: Bearer XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX' --header 'Content-Type: application/json' --data-raw" + '\'{ "eventType":"FAMILY_HISTORY", "actionType":"CREATE", "body": '+ JSON.stringify(this.jsonEjemplos[0].body.body)+'}\'',
    "bodyProduccion": "curl --location --request POST 'https://saludtools.carecloud.com.co/integration/sync/event/v1/' --header 'Authorization: Bearer XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX' --header 'Content-Type: application/json' --data-raw" + '\'{ "eventType":"FAMILY_HISTORY", "actionType":"CREATE", "body": '+ JSON.stringify(this.jsonEjemplos[0].body.body)+'}\'',
    "subtitle": "Después de Bearer sustituya  las X por su access-token generado y cambie los parámetros por los datos de su antecedente familiar a crear."
  }

  jsonResultados = [
    {
      "title": "OK 200",
      "body": {
        "id": 8487,
        "code": 200,
        "message": "Se registra el Antecedente Familiar id: 8487",
        "eventId": "128b6f55ff094c9586cd4f3f2ca03cda",
        "body": null
      },
      "subtitle": "La solicitud se realizó con éxito y el Antecedente Familiar ha sido creado.",
      "status": "200"
    },
    {
      "title": "401 Unauthorized",
      "body": 
        "No tiene permisos para acceder al servidor",
      "subtitle": "Coloque un token de acceso válido.",
      "status": "401"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
    "code": 412,
    "message": "No se ha enviado un tipo de evento valido",
    "eventId": "fe645e7a430c40bb946d221fb688351d",
    "body": null
      },
      "subtitle": "Se omitió el parámetro \"eventType\", para crear un Antecedente Familiar es obligatorio el parámetro \"eventType\", por favor agrégalo al inicio del body como en el ejemplo.",
      "status": "412"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
        "code": 412,
        "message": "No se ha enviado un tipo de evento valido",
        "eventId": "48c76fbf91cf46a19ebd948c3d2a6d5e",
        "body": null
      },
      "subtitle": "Error en el valor del parámetro \"eventType\". Para crear un Antecedente Familiar el valor permitido del \"eventType\"es \"FAMILY_HISTORY\".",
      "status": "412"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
    "code": 412,
    "message": "No se ha enviado una accion válida",
    "eventId": "dd24b96692b744e4b97b397e5a3c1dd9",
    "body": null
      },
      "subtitle": "Se omitió el parámetro \"actionType\", para crear un Antecedente Familiar es obligatorio el parámetro \"actionType\" que indica la acción de crear, por favor agrégalo al inicio del body como en el ejemplo.",
      "status": "412"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
        "code": 412,
        "message": "No se ha enviado una accion válida",
        "eventId": "a3d1a85dc6b649a2b4cb5ccc10d3e1c3",
        "body": null
      },
      "subtitle": "Error en el valor del parámetro \"actionType\". Para crear un Antecedente Familiar el valor permitido del parámetro \"actionType\" es \"CREATE\".",
      "status": "412"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
        "code": 412,
        "message": "Request method 'PUT' not supported",
        "eventId": "81cf71d0bf1b41f391ff690352cad890",
        "body": null
      },
      "subtitle": "El método usado no es compatible con el recurso enviado, recuerde que el método que utiliza este servicio es POST.",
      "status": "412"
    }]

  constructor() { }

  ngOnInit(): void {
  }

}
