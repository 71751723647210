import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-patient-inability-work-create',
  templateUrl: './patient-inability-work-create.component.html',
  styleUrls: ['./patient-inability-work-create.component.css']
})
export class PatientInabilityWorkCreateComponent implements OnInit {

  title: string = "Crear Prescripción de incapacidad del paciente";
  subTitle: string = "Cree una nueva prescripción de incapacidad del paciente en Saludtools."

  paragraphTitle: string = "¿Cómo crear una nueva prescripción de incapacidad?";
  paragraph: string = "Para crear una nueva prescripción de incapacidad, primero debe tener un Token de acceso activo. Si su Token se venció, o aún no ha generado un Token de acceso. Genere un nuevo Token y consuma el evento de crear prescripción de incapacidad.";

  parameters = [
    {
      "Parameters": "eventType",
      "requerido": true,
      "Type": "String",
      "Description": "Es el tipo de evento que se quiere invocar en este caso debe ser \"INABILITYWORK\"."
    },
    {
      "Parameters": "actionType",
      "requerido": true,
      "Type": "String",
      "Description": "Es la acción que se quiere realizar para crear una prescripción de incapacidad debe ser \"CREATE\""
    },
    {
      "Parameters": "documentType",
      "requerido": true,
      "Type": "Long",
      "Description": "Id del tipo de documento de identidad del paciente"
    },
    {
      "Parameters": "documentNumber",
      "requerido": true,
      "Type": "String",
      "Description": "Número del documento de identidad del paciente"
    },
    {
      "Parameters": "diagnosticCIE10ID",
      "requerido": true,
      "Type": "String",
      "Description": "Código del diagnóstico CIE10"
    },
    {
      "Parameters": "consultationExternalCauseID",
      "requerido": true,
      "Type": "Long",
      "Description": "ID de la causa externa"
    },
    {
      "Parameters": "treatmentAreaOfApplicationID",
      "requerido": true,
      "Type": "Long",
      "Description": "ID de la especialidad"
    },
    {
      "Parameters": "reoccurenceTypeID",
      "requerido": true,
      "Type": "Long",
      "Description": "ID del tipo de incapacidad"
    },
    {
      "Parameters": "startInabilityDate",
      "requerido": true,
      "Type": "Date",
      "Description": "Fecha de inicio de la incapacidad"
    },
    {
      "Parameters": "endInabilityDate",
      "requerido": true,
      "Type": "Date",
      "Description": "Fecha de fin de la incapacidad"
    },
    {
      "Parameters": "comments",
      "requerido": false,
      "Type": "String",
      "Description": "Comentarios de la incapacidad"
    }
  ]

  jsonEjemplos =
    [{
      "title": "Body",
      "body":
      {
        "eventType":"INABILITYWORK",
        "actionType":"CREATE",
        "body": {
          "documentType": 1,
          "documentNumber": "123456789",
          "request": {
            "diagnosticCIE10ID": "A010",
            "consultationExternalCauseID": 1,
            "treatmentAreaOfApplicationID": 82,
            "reoccurenceTypeID": 1,
            "startInabilityDate": "2022-09-27",
            "endInabilityDate": "2022-09-27",
            "comments": "Dolores abdominales..."
          }
        }
      }
    }]

  curlEjemplo = {
    "title": "cURL",
    "bodyPrueba": "curl --location --request POST 'https://saludtools.qa.carecloud.com.co/integration/sync/event/v1/' --header 'Authorization: Bearer XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX' --header 'Content-Type: application/json' --data-raw" + '\'{ "eventType":"PATIENT", "actionType":"CREATE", "body": {"documentType": 1, "documentNumber": "123456789", "request": { "diagnosticCIE10ID": "A010", "consultationExternalCauseID": 1, "treatmentAreaOfApplicationID": 82, "reoccurenceTypeID": 1, "startInabilityDate": "2022-09-27", "endInabilityDate": "2022-09-27", "comments": "Dolores abdominales..." }}}\'',
    "bodyProduccion": "curl --location --request POST 'https://saludtools.carecloud.com.co/integration/sync/event/v1/' --header 'Authorization: Bearer XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX' --header 'Content-Type: application/json' --data-raw" + '\'{ "eventType":"PATIENT", "actionType":"CREATE", "body": {"documentType": 1, "documentNumber": "123456789", "request": { "diagnosticCIE10ID": "A010", "consultationExternalCauseID": 1, "treatmentAreaOfApplicationID": 82, "reoccurenceTypeID": 1, "startInabilityDate": "2022-09-27", "endInabilityDate": "2022-09-27", "comments": "Dolores abdominales..." }}}\'',
    "subtitle": "Después de Bearer sustituya las X por su access-token generado y cambie los parámetros por los datos de la prescripción de incapacidad a crear."
  }

  jsonResultados = [
    {
      "title": "OK 200",
      "body": {
        "id": 349705,
        "code": 200,
        "message": "Se registra  id: 3440",
        "eventId": "f9d873f2a8fe4424afda80c60df233cf",
        "body": null
      },
      "subtitle": "La solicitud se realizó con éxito y la prescripción de incapacidad ha sido creada.",
      "status": "200"
    },
    {
      "title": "401 Unauthorized",
      "body": 
        "No tiene permisos para acceder al servidor",
      "subtitle": "Coloque un token de acceso válido.",
      "status": "401"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
    "code": 412,
    "message": "No se ha enviado un tipo de evento valido",
    "eventId": "fe645e7a430c40bb946d221fb688351d",
    "body": null
      },
      "subtitle": "Se omitió el parámetro \"eventType\", para crear un paciente es obligatorio el parametro \"eventType\", por favor agrégalo al inicio del body como en el ejemplo.",
      "status": "412"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
        "code": 412,
        "message": "No se ha enviado un tipo de evento valido",
        "eventId": "48c76fbf91cf46a19ebd948c3d2a6d5e",
        "body": null
      },
      "subtitle": "Error en el valor del parámetro \"eventType\". Para crear un paciente el valor permitido del \"eventType\"es \"INABILITYWORK\".",
      "status": "412"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
    "code": 412,
    "message": "No se ha enviado una accion válida",
    "eventId": "dd24b96692b744e4b97b397e5a3c1dd9",
    "body": null
      },
      "subtitle": "Se omitió el parámetro \"actionType\", para crear un paciente es obligatorio el parámetro \"actionType\" que indica la acción de crear la prescripción de incapacidad, por favor agrégalo al inicio del body como en el ejemplo.",
      "status": "412"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
        "code": 412,
        "message": "No se ha enviado una accion válida",
        "eventId": "a3d1a85dc6b649a2b4cb5ccc10d3e1c3",
        "body": null
      },
      "subtitle": "Error en el valor del parámetro \"actionType\". Para crear un paciente el valor permitido del parámetro \"actionType\" es \"CREATE\".",
      "status": "412"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
        "code": 412,
        "message": "Request method 'PUT' not supported",
        "eventId": "81cf71d0bf1b41f391ff690352cad890",
        "body": null
      },
      "subtitle": "El método usado no es compatible con el recurso enviado, recuerde que el método que utiliza este servicio es POST.",
      "status": "412"
    }]

  constructor() { }

  ngOnInit(): void {
  }

}
