import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-exams-prescription-read',
  templateUrl: './exams-prescription-read.component.html',
  styleUrls: ['./exams-prescription-read.component.css']
})
export class ExamsPrescriptionReadComponent implements OnInit {

  title: string = "Leer Prescripción de exámenes";
  subTitle: string = "Leer una nueva Prescripción de exámenes"

  paragraphTitle: string = "¿Cómo leer una Prescripción de exámenes?";
  paragraph: string = "Para leer una Prescripción de exámenes, primero debe tener un Token de acceso activo. Si su Token se venció, o aún no ha generado un Token de acceso. Genere un nuevo Token y consuma el evento de leer Prescripción de exámenes.";

  parameters = [
    {
      "Parameters": "eventType",
      "requerido": true,
      "Type": "String",
      "Description": "Es el tipo de evento que se quiere invocar en este caso debe ser \"EXAMS_PRESCRIPTION\"."
    },
    {
      "Parameters": "actionType",
      "requerido": true,
      "Type": "String",
      "Description": "Es la acción que se quiere realizar para leer un prescripción de exámenes debe ser \"READ\""
    },
    {
      "Parameters": "id",
      "requerido": true,
      "Type": "Long",
      "Description": "Id del container de la prescripción de examen que se quiera leer"
    },
  ]

  jsonEjemplos =
    [{
      "title": "Body",
      "body":{
        "eventType":"EXAMS_PRESCRIPTION",
        "actionType":"READ",
        "body": {
            "id": "1515"
            }
    },
      "subtitle": "Body para leer una prescripción de examenes, sustituya el id de el container de prescripciones de exameness que desea leer."
    }
    ]

  curlEjemplo = {
    "title": "cURL",
    "bodyPrueba": "curl --location --request POST 'https://saludtools.carecloud.com.co/integration/sync/event/v1/' --header 'Authorization: Bearer XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX' --header 'Content-Type: application/json' --data-raw" + '\'{ "eventType":"EXAMS_PRESCRIPTION", "actionType":"READ", "body": { "id" :"123456"} }\'',
    "bodyProduccion": "curl --location --request POST 'https://saludtools.qa.carecloud.com.co/integration/sync/event/v1/' --header 'Authorization: Bearer XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX' --header 'Content-Type: application/json' --data-raw" + '\'{ "eventType":"EXAMS_PRESCRIPTION", "actionType":"READ", "body": { "id" :"123456"} }\'',
    "subtitle": "Después de Bearer sustituya  las X por su access-token generado y cambie los parámetros por los datos de su prescripción a leer."
  }

  jsonResultados = [
    {
      "title": "OK 200",
      "body": {
        "id": 349705,
        "code": 200,
        "message": "Se registra id: 349705",
        "eventId": "128b6f55ff094c9586cd4f3f2ca03cda",
        "body": null
      },
      "subtitle": "La solicitud se realizó con éxito y prescripción ha sido creada.",
      "status": "200"
    },
    {
      "title": "401 Unauthorized",
      "body": 
        "No tiene permisos para acceder al servidor",
      "subtitle": "Coloque un token de acceso válido.",
      "status": "401"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
    "code": 412,
    "message": "No se ha enviado un tipo de evento valido",
    "eventId": "fe645e7a430c40bb946d221fb688351d",
    "body": null
      },
      "subtitle": "Se omitió el parámetro \"eventType\", para crear una prescripción de examenes es obligatorio el parametro \"eventType\", por favor agrégalo al inicio del body como en el ejemplo.",
      "status": "412"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
        "code": 412,
        "message": "No se ha enviado un tipo de evento valido",
        "eventId": "48c76fbf91cf46a19ebd948c3d2a6d5e",
        "body": null
      },
      "subtitle": "Error en el valor del parámetro \"eventType\". Para crear una prescripción de examenes el valor permitido del \"eventType\"es \"EXAMS_PRESCRIPTION\".",
      "status": "412"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
    "code": 412,
    "message": "No se ha enviado una accion válida",
    "eventId": "dd24b96692b744e4b97b397e5a3c1dd9",
    "body": null
      },
      "subtitle": "Se omitió el parámetro \"actionType\", para crear una prescripción de examenes es obligatorio el parámetro \"actionType\" que indica la acción de crear, por favor agrégalo al inicio del body como en el ejemplo.",
      "status": "412"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
        "code": 412,
        "message": "No se ha enviado una accion válida",
        "eventId": "a3d1a85dc6b649a2b4cb5ccc10d3e1c3",
        "body": null
      },
      "subtitle": "Error en el valor del parámetro \"actionType\". Para crear una prescripción de examenes el valor permitido del parámetro \"actionType\" es \"CREATE\".",
      "status": "412"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
        "code": 412,
        "message": "Request method 'PUT' not supported",
        "eventId": "81cf71d0bf1b41f391ff690352cad890",
        "body": null
      },
      "subtitle": "El método usado no es compatible con el recurso enviado, recuerde que el método que utiliza este servicio es POST.",
      "status": "412"
    }]

  constructor() { }

  ngOnInit(): void {
  }

}
