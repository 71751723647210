import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.css']
})
export class DropdownComponent implements OnInit {

  environment: string = "Prueba";

  environmentShow(){
    if(sessionStorage.getItem('environment') == '0'){
      this.environment = "Producción";
    }
  }

  prueba() {
    sessionStorage.setItem('environment', "1");
  }

  produccion() {
    sessionStorage.setItem('environment', "0");
  }

  constructor() { }

  ngOnInit(): void {
    this.environmentShow();
  }

}
