import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-clinic-history-read',
  templateUrl: './clinic-history-read.component.html',
  styleUrls: ['./clinic-history-read.component.css']
})
export class ClinicHistoryReadComponent implements OnInit {

  title: string = "Leer atención de historia clínica";
  subTitle: string = "lea una atención existente"

  paragraphTitle: string = "¿Cómo leer una atención?";
  paragraph: string = "Para leer una atención, primero debe tener un Token de acceso activo. Si su Token se venció, o aún no ha generado un Token de acceso. Genere un nuevo Token y consuma el evento de leer atención.";

  parameters = [
    {
      "Parameters": "eventType",
      "requerido": true,
      "Type": "String",
      "Description": "Es el tipo de evento que se quiere invocar en este caso debe ser \"CLINIC_HISTORY\"."
    },
    {
      "Parameters": "actionType",
      "requerido": true,
      "Type": "String",
      "Description": "Es la acción que se quiere realizar para leer un atención debe ser \"READ\""
    },
    {
      "Parameters": "id",
      "requerido": true,
      "Type": "Long",
      "Description": "es el id de la atención que se quiere leer."
    }
  ]

  jsonEjemplos =
    [{
      "title": "Body",
      "body":
      {
        "eventType":"CLINIC_HISTORY",
        "actionType":"READ",
        "body": {
                  "id": 11111,
                }
      },
      "subtitle": "Body para leer una atención, sustituya los parámetros id por el id de su atención"
    }
    ]

  curlEjemplo = {
    "title": "cURL",
    "bodyPrueba": "curl --location --request POST 'https://saludtools.qa.carecloud.com.co/integration/sync/event/v1/' --header 'Authorization: Bearer XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX' --header 'Content-Type: application/json' --data-raw" + '\'{ "eventType":"CLINIC_HISTORY", "actionType":"READ", "body": { "id": 1111 }}\'',
    "bodyProduccion": "curl --location --request POST 'https://saludtools.carecloud.com.co/integration/sync/event/v1/' --header 'Authorization: Bearer XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX' --header 'Content-Type: application/json' --data-raw" + '\'{ "eventType":"CLINIC_HISTORY", "actionType":"READ", "body": { "id": 1111 }}\'',
    "subtitle": "Después de Bearer sustituya  las X por su access-token generado y cambie los parámetros por los datos de su atención a leer."
  }

  jsonResultados = [
    {
      "title": "OK 200",
      "body": {
        "id": null,
        "code": 200,
        "message": "Se consulta la informacion de  id: 307111",
        "eventId": "18e7d2a9521d4657baa3d6d6d6882785",
        "body": {
            "id": 3071141,
            "pageable": null,
            "appointmentId": null,
            "configurationClinicHistoryId": 8678,
            "patientDocumentType": 1,
            "patientDocumentNumber": "123456",
            "patientVitalSignsRecord": {
                "id": 306585,
                "weight": 121.01,
                "heightCm": 2.01,
                "systolicBloodPressure": 3.03,
                "diastolicBloodPressure": 4.8,
                "heartRate": 5,
                "breathingRate": 6,
                "temperature": 7.05,
                "karnofsy": 8,
                "imc": 9.25,
                "sp02": 10.8,
                "bodyArea": 11.5,
                "bodyFatPercentage": 12.56,
                "vo2Maximum": 13.56,
                "abdominalCircunference": 14.5,
                "waistCircunference": 15.6,
                "hipCircunference": 16.7,
                "waistOverHipIndex": 17.8,
                "amountOfChestFlexionsIn30Seconds": 18.9,
                "amountOfAbsIn30Seconds": 19.8,
                "amountOfBurpeesIn30Seconds": 20.1,
                "flexibilityInCm": 21.3,
                "musculoskeletalWeightKg": 22.03,
                "percentageBodyFat": 23.03,
                "visceralFatLevel": 24.5,
                "amountOfBodyWaterLitres": 25.62,
                "amountOfBodyProteinesKg": 26.87,
                "amountOfBodyMineralsKg": 27.56,
                "amountOfBodyFatKg": 28.96,
                "amountOfLeftArmMuscleKg": 29.63,
                "amountOfRightArmMuscleKg": 30.15,
                "amountOfBodyTrunkMuscleKg": 31.25,
                "amountOfLeftLegMuscleKg": 32.58,
                "amountOfRightLegMuscleKg": 33.65,
                "amountOfLeftArmFatKg": 34.68,
                "amountOfRightArmFatKg": 34.98,
                "amountOfBodyTrunkFatKg": 35.6,
                "amountOfLeftLegFatKg": 36.12,
                "amountOfRightLegFatKg": 37.52,
                "restingMetabolicRateKcalDay": 38.02,
                "usualWeight": 39.58,
                "brachialCircumcourse": 40.21,
                "tricipitalFold": 41.21,
                "circumferencePalette": 42.25,
                "muscularCircumferenceCmbBranch": 43.65,
                "charlsonComorbidityIndex": 48.56,
                "mediumArterialTension": 47.85,
                "comments": "48 esto es un comentario",
                "neckCircumference": 49.85,
                "uterineHeight": 50.21,
                "fetalHeartRate1": 51.23,
                "fetalHeartRate2": 52.78,
                "fetalHeartRate3": 53.08,
                "maximumWeight": 54.85,
                "poem": 55.95,
                "dlqi": 56.35,
                "escorad": 57.14,
                "easi": 58.95,
                "imcMaximum": 59.0,
                "asaClassification": 60.35,
                "ocularPressureEyeLeft": 61.25,
                "ocularPressureEyeRight": 62.35,
                "pulse": 63.25,
                "headCircunference": 64.12,
                "headCircunferenceXAge": 65.02,
                "weightXAge": 66.32,
                "weightXHeight": 67.5,
                "heightXAge": 68.12,
                "imcPreoperative": 69.23,
                "peImc": 70.25,
                "generalHealthPatientEval": 71.05,
                "testWells": 72.25,
                "crestIliacCi": 73.56,
                "spineIliacEi": 74.25,
                "abdominalAbd": 75.89,
                "thighMu": 76.25,
                "calfPi": 77.26,
                "neckCircumferenceCc": 78.05,
                "subScapularSkinfoldsSe": 79.05,
                "bicepsBc": 80.05,
                "glomerularFiltrationCg": null,
                "estadio": null,
                "das28": null,
                "paraclinicDas28": null,
                "brachialCircumcourseXAge": 85.85,
                "imcXAge": 86.86,
                "headCircunferenceFetal": 87.87,
                "imcPostoperative": 88.88,
                "additionalComents": "COMENTARIO ADICIONAL"
            },
            "impression": "prueba de impresion integrando",
            "managementPlan": "prueba de plan de manejo integrando",
            "currentIllness": {
              "attentionModality": "TELEMEDICINE",
              "consultationReason": 1,
              "consultationName": "Nombre de la consulta",
              "attentionConsecutive": "Consecutivo 16",
              "consultationActualDisease": "Enfermedad actual y motivo de la consulta",
              "actualDiseaseComment": "comentarios",
              "attenderUserDocumentType": 1,
              "attenderUserDocumentNumber": "1258"
          },
          "sectionDiagnostic":{
            "externalCause": 1,
            "diagnosticList":[
                {
                "diagnosticCie10": "A000",
                "diagnosticDate": "2021-04-30 08:00",
                "diagnosticClasification": 1,
                "diagnosticType": 2,
                "diagnosticObservations": "Comentario 1"
                },
                {
                "diagnosticCie10": "H400",
                "diagnosticDate": "2021-04-30 08:00",
                "diagnosticClasification": 1,
                "diagnosticType": 2,
                "diagnosticObservations": "comentario 2",
                "eyes": 3
                },
                {
                "diagnosticCie10": "E100",
                "diagnosticDate": "2021-04-30 08:00",
                "diagnosticClasification": 1,
                "diagnosticType": 2,
                "diagnosticObservations": "esto es un comentario",
                "diabetesType": 3
                }
            ]
        },
        "patientPhysicalExamination":{
            "headEvaluation": "NORMAL",
	        "headFinding": "prueba 1",
	        "neckEvaluation": "NORMAL",
	        "neckFinding": "prueba 2",
	        "chestEvaluation": "NORMAL",
	        "chestFinding": "Prueba 3",
	        "abdomenEvaluation": "NORMAL",
	        "abdomenFinding": "Prueba 4",
            "neurologicalEvaluation": "NORMAL",
	        "neurologicalFinding": "Prueba 5",
	        "extremitiesEvaluation": "ANORMAL",
	        "extremitiesFinding": "Prueba 6",
	        "skinFacerEvaluation": "ANORMAL",
	        "skinFacerFinding": "Prueba 7"
        },
        "systemReview": [
          {
            "id": 104,
            "type": 2,
            "name": "Cardiovascular",
            "symptoms": ["Mareos", "Dolor de cabeza"]
          }
        ],
        }
    },
      "subtitle": "La solicitud se realizó con éxito y se ven los datos de la atención.",
      "status": "200"
    },
    {
      "title": "401 Unauthorized",
      "body": 
        "No tiene permisos para acceder al servidor",
      "subtitle": "Coloque un token de acceso válido.",
      "status": "401"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
    "code": 412,
    "message": "debe enviar un id de encuentro comun",
    "eventId": "fe645e7a430c40bb946d221fb688351d",
    "body": null
      },
      "subtitle": "debe colocar un id de encuentro",
      "status": "412"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
    "code": 412,
    "message": "Encuentro comun no encontrado",
    "eventId": "fe645e7a430c40bb946d221fb688351d",
    "body": null
      },
      "subtitle": "El id diligenciado no se encuentra en nuestra base de datos, por favor confirme el id",
      "status": "412"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
    "code": 412,
    "message": "No se ha enviado un tipo de evento valido",
    "eventId": "fe645e7a430c40bb946d221fb688351d",
    "body": null
      },
      "subtitle": "Se omitió el parámetro \"eventType\", para leer una antención es obligatorio el parametro \"eventType\", por favor agrégalo al inicio del body como en el ejemplo.",
      "status": "412"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
        "code": 412,
        "message": "No se ha enviado un tipo de evento valido",
        "eventId": "48c76fbf91cf46a19ebd948c3d2a6d5e",
        "body": null
      },
      "subtitle": "Error en el valor del parámetro \"eventType\". Para leer una atención el valor permitido del \"eventType\"es \"CLINIC_HISTORY\".",
      "status": "412"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
    "code": 412,
    "message": "No se ha enviado una accion válida",
    "eventId": "dd24b96692b744e4b97b397e5a3c1dd9",
    "body": null
      },
      "subtitle": "Se omitió el parámetro \"actionType\", para leer una atención es obligatorio el parámetro \"actionType\" que indica la acción, por favor agrégalo al inicio del body como en el ejemplo.",
      "status": "412"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
        "code": 412,
        "message": "No se ha enviado una accion válida",
        "eventId": "a3d1a85dc6b649a2b4cb5ccc10d3e1c3",
        "body": null
      },
      "subtitle": "Error en el valor del parámetro \"actionType\". Para leer una atención el valor permitido del parámetro \"actionType\" es \"READ\".",
      "status": "412"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
        "code": 412,
        "message": "Request method 'PUT' not supported",
        "eventId": "81cf71d0bf1b41f391ff690352cad890",
        "body": null
      },
      "subtitle": "El método usado no es compatible con el recurso enviado, recuerde que el método que utiliza este servicio es POST.",
      "status": "412"
    }]

  constructor() { }

  ngOnInit(): void {
  }

}
