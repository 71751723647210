import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-patient-gineco-information-read',
  templateUrl: './patient-gineco-information-read.component.html',
  styleUrls: ['./patient-gineco-information-read.component.css']
})
export class PatientGinecoInformationReadComponent implements OnInit {

  title: string = "Leer Antecedentes Ginecoobstétricos";
  subTitle: string = "Lee un Antecedente Ginecoobstétrico"

  paragraphTitle: string = "¿Cómo leer un Antecedente Ginecoobstétrico?";
  paragraph: string = "Para leer un Antecedente Ginecoobstétrico, primero debe tener un Token de acceso activo. Si su Token se venció, o aún no ha generado un Token de acceso. Genere un nuevo Token y consuma el evento de leer un  Antecedente Ginecoobstétrico.";

  parameters = [
    {
      "Parameters": "eventType",
      "requerido": true,
      "Type": "String",
      "Description": "Es el tipo de evento que se quiere invocar en este caso debe ser \"GYNECOOBS_HISTORY\"."
    },
    {
      "Parameters": "actionType",
      "requerido": true,
      "Type": "String",
      "Description": "Es la acción que se quiere realizar para leer una prescripción de exámenes debe ser \"READ\""
    },
    {
      "Parameters": "documentType",
      "requerido": true,
      "Type": "Long",
      "Description": "Id del tipo de documento de identidad del paciente"
    },
    {
      "Parameters": "documentNumber",
      "requerido": true,
      "Type": "String",
      "Description": "Número del documento de identidad del paciente"
    },
  ]

  jsonEjemplos =
    [{
      "title": "Body",
      "body":{
        "eventType":"GYNECOOBS_HISTORY",
        "actionType":"READ",
        "body": {
          "documentType": 1,
          "documentNumber": "177400432"
          }
    },
      "subtitle": "Body para leer un Antecedente Ginecoobstétrico, Sustituya los parámetros documentType y documentNumber por los datos del paciente para leer su Antecedente Ginecoobstétrico."
    }
    ]

  curlEjemplo = {
    "title": "cURL",
    "bodyPrueba": "curl --location --request POST 'https://saludtools.carecloud.com.co/integration/sync/event/v1/' --header 'Authorization: Bearer XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX' --header 'Content-Type: application/json' --data-raw" + '\'{ "eventType":"EXAMS_PRESCRIPTION", "actionType":"READ", "body": { "id" :"123456"} }\'',
    "bodyProduccion": "curl --location --request POST 'https://saludtools.qa.carecloud.com.co/integration/sync/event/v1/' --header 'Authorization: Bearer XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX' --header 'Content-Type: application/json' --data-raw" + '\'{ "eventType":"EXAMS_PRESCRIPTION", "actionType":"READ", "body": { "id" :"123456"} }\'',
    "subtitle": "Después de Bearer sustituya  las X por su access-token generado y cambie los parámetros por los datos de su prescripción a leer."
  }

  jsonResultados = [
    {
      "title": "OK 200",
      "body": {
        "id": null,
        "code": 200,
        "message": "Se consulta la información de el antecedente ginecoobstétrico id: 8487",
        "eventId": "7d972fc0d40447e1a22bb68f502699bf",
        "body": {
          "id": 8487,
          "pageable": null,
          "documentType": 1,
          "documentNumber": "65135",
          "menarche": 8,
          "ceases": 5,
          "irs": 11,
          "fur": "2022-07-05",
          "fup": "2020-07-13",
          "contraceptiveType": null,
          "detailContraceptiveMethod": "Detalle del método de planificación",
          "observations": "Gestación actual",
          "aliveBirths": 1,
          "pregnancies": 2,
          "births": 3,
          "cesareans": 4,
          "molas": 6,
          "ectopics": 7,
          "pubarchy": 9,
          "telarchy": 10,
          "sexualPartners": 12,
          "menstrualCycles": "Ciclos menstruales, duración u periocidad",
          "menstrualRegularity": "REGULAR",
          "lastCytologyDate": "2022-10-10",
          "currentlyPregnant": true,
          "isTrustworthy": true,
          "previousGestationalObservations": "observaciones de las gestaciones",
          "ultrasoundDate": "2022-07-12",
          "gestationWeek": 6,
          "gestationDay": 2,
          "weeksOfPregnancy": 20,
          "otherObservations": "Detalles del parto"
        }
      },
      "subtitle": "La solicitud se realizó con éxito y se ha leído el  antecedente ginecoobstétrico del paciente exitosamente.",
      "status": "200"
    },
    {
      "title": "401 Unauthorized",
      "body": 
        "No tiene permisos para acceder al servidor",
      "subtitle": "Coloque un token de acceso válido.",
      "status": "401"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
    "code": 412,
    "message": "No se ha enviado un tipo de evento valido",
    "eventId": "fe645e7a430c40bb946d221fb688351d",
    "body": null
      },
      "subtitle": "Se omitió el parámetro \"eventType\", para crear un antecedente ginecoobstétrico es obligatorio el parametro \"eventType\", por favor agrégalo al inicio del body como en el ejemplo.",
      "status": "412"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
        "code": 412,
        "message": "No se ha enviado un tipo de evento valido",
        "eventId": "48c76fbf91cf46a19ebd948c3d2a6d5e",
        "body": null
      },
      "subtitle": "Error en el valor del parámetro \"eventType\". Para crear un antecedente ginecoobstétrico el valor permitido del \"eventType\"es \"GYNECOOBS_HISTORY\".",
      "status": "412"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
    "code": 412,
    "message": "No se ha enviado una accion válida",
    "eventId": "dd24b96692b744e4b97b397e5a3c1dd9",
    "body": null
      },
      "subtitle": "Se omitió el parámetro \"actionType\", para crear un antecedente ginecoobstétrico es obligatorio el parámetro \"actionType\" que indica la acción de crear, por favor agrégalo al inicio del body como en el ejemplo.",
      "status": "412"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
        "code": 412,
        "message": "No se ha enviado una accion válida",
        "eventId": "a3d1a85dc6b649a2b4cb5ccc10d3e1c3",
        "body": null
      },
      "subtitle": "Error en el valor del parámetro \"actionType\". Para crear un antecedente ginecoobstétrico el valor permitido del parámetro \"actionType\" es \"CREATE\".",
      "status": "412"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
        "code": 412,
        "message": "Request method 'PUT' not supported",
        "eventId": "81cf71d0bf1b41f391ff690352cad890",
        "body": null
      },
      "subtitle": "El método usado no es compatible con el recurso enviado, recuerde que el método que utiliza este servicio es POST.",
      "status": "412"
    }]

  constructor() { }

  ngOnInit(): void {
  }

}
