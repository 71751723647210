import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ParametersService } from 'src/app/services/parameters.service';
import { AuthenticationRedirectionComponent } from '../../authentication-redirection/authentication-redirection.component';
import { ParametersModalEncounterFormComponent } from '../../parameters-modal-encounter-form/parameters-modal-encounter-form.component';
import { ParametersModalFormComponent } from '../../parameters-modal-form/parameters-modal-form.component';
import { ParametersModalPaginatorComponent } from '../../parameters-modal-paginator/parameters-modal-paginator.component';
import { ParametersModalContentComponent } from '../parameters-modal-content/parameters-modal-content.component';

@Component({
  selector: 'app-parameters-modal-button',
  templateUrl: './parameters-modal-button.component.html',
  styleUrls: ['./parameters-modal-button.component.css']
})
export class ParametersModalButtonComponent implements OnInit {

  @Input() type: string;
  title: string = "parametros";

  constructor(private page: ParametersService, private modalService: NgbModal, private parametersService: ParametersService) { }

  open() {
    this.page.pages = "0";
     if(["principleact", "eps", "commercialname", "configurationclinichistoryid","paraclinictype","unittype","classificationtype", "medicalexamtype", "diagnosticcie10","examclassificationtype", "treatmenareatype"].includes(this.type) ){
    this.parametersService.getParameters(this.type, "null", "").subscribe(
      resp => {
      const modalRef = this.modalService.open(ParametersModalPaginatorComponent);
      modalRef.componentInstance.json = resp;
      modalRef.componentInstance.title = this.title;
      modalRef.componentInstance.type = this.type;
    },
    error =>{
      const modalRef = this.modalService.open(AuthenticationRedirectionComponent);
      modalRef.componentInstance.type = this.type;
      modalRef.componentInstance.title = this.title;
    },
    );} else if(this.type == "encountercommonid" || this.type == "remissioncontainerid" || this.type == "antecedentspersonal"){

      const modalRef = this.modalService.open(ParametersModalEncounterFormComponent);
      modalRef.componentInstance.type = this.type;
      modalRef.componentInstance.title = this.title;

    } else if (this.type != "atcconcentration") {
      this.parametersService.getParameters(this.type, "null", "").subscribe(
        resp => {
          const modalRef = this.modalService.open(ParametersModalContentComponent);
          modalRef.componentInstance.json = resp;
          modalRef.componentInstance.title = this.title;
        },
        error => {
          const modalRef = this.modalService.open(AuthenticationRedirectionComponent);
          modalRef.componentInstance.type = this.type;
          modalRef.componentInstance.title = this.title;
        },
      );
    } else {
      const modalRef = this.modalService.open(ParametersModalFormComponent);
      modalRef.componentInstance.type = this.type;
      modalRef.componentInstance.title = this.title;
    }
  }

  TITLE_TYPE = {
      documents: "Tipos de Documentos",
      eps: "Tipos de eps",
      clinics: "Tipos Clínicas",
      genders: "Tipos de géneros",
      states: "Estados de la cita",
      principleact: "Id Principio Activo",
      commercialname: "Nombre Comercial",
      atcconcentration: "Id concentracion",
      intakemethod: "Método de administración",
      frequencyunit: "Unidad de frecuencia",
      durationunit: "Unidad de duración",
      encountercommonid:"Id de la atención",
      configurationclinichistoryid: "Id Configuración de historia clínica",
      classificationtype: "Tipos de clasificación de paraclinicos",
      unittype: "Unidades de medición de paraclinicos",
      paraclinictype: "Tipos de paraclinicos",
      attentionModality: "Modalidad de la consulta",
      encounterreasontype: "Tipo de consulta",
      examprescriptiontype: "Tipo de prescripción",
      medicalexamtype: "Tipo de examen",
      remissioncontainerid: "Id de prescripción de examenes",
      examclassificationtype:"Id de la clasificación del examen",
      externalcause: "Id causa externa",
      diagnosticcie10: "Código de diagnóstico cie10",
      diagnosticClasification: "Clasificación del diagnóstico",
      diagnosticType: "Tipo de diagnóstico",
      eyestype: "Ojo",
      diabetesType: "Tipo de diabetes",
      evaluationenum: "Evaluación de la región",
      treatmenareatype: "Tipos de especialidades",
      inabilityworktype: "Tipos de incapacidad",
      contraceptivetype: "Método de planificación",
      friendlyname: "Nombre amigable",
      personalantecedentsgroup: "Id de grupo de antecedente",
      antecedentspersonal: "id de antecedentes personales",
      familiarRelationshipType: "Parentesco con el familiar"
    }

  getTitle() {
    this.title = this.TITLE_TYPE[this.type] || ""
  }

  ngOnInit(): void {
    this.getTitle();
  }

}
