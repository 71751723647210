import { Component, Input, OnInit } from '@angular/core';
import { ParametersService } from 'src/app/services/parameters.service';
import { ParametersModalPaginatorComponent } from '../parameters-modal-paginator/parameters-modal-paginator.component';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.css']
})
export class PaginatorComponent implements OnInit {

    actualPage: number =1;
    disabledShowPrevious: boolean = true;
    disabledShowNext: boolean = true;
    @Input() totalPages: number;
    
  


  constructor(private page: ParametersService, private modal: ParametersModalPaginatorComponent) { }

  

  ngOnInit(): void {
    this.page.pages = (this.actualPage -1).toString();
    this.totalPages = this.modal.json.totalPages
    this.showPrevious();
    this.showNext();
  }

  previous(){
    this.actualPage = this.actualPage -1;
    this.ngOnInit()
    this.page.pages = (this.actualPage -1).toString();
    this.modal.open();
  }

  next(){
    this.actualPage = this.actualPage +1;
    this.ngOnInit()
    this.page.pages = (this.actualPage -1).toString();
    this.modal.open();
  }

  showPrevious(){
    if (this.actualPage == 1){
      this.disabledShowPrevious = false;
    } else {
      this.disabledShowPrevious = true;
    }
  }

  showNext(){
    if (this.actualPage == this.totalPages || this.actualPage > this.totalPages ){
      this.disabledShowNext = false;
    } else {
      this.disabledShowNext = true;
    }
  }

}
