<div id="layoutSidenav">
    <div id="layoutSidenav_content">
        <br>
        <div class="container">
            <app-main-title [title]="title" [subTitle]="subTitle"></app-main-title>
            <app-main-paragraph [paragraphTitle]="paragraphTitle" [paragraph]="paragraph"></app-main-paragraph>
         
            <h4>Una Atencion clínica contiene los siguientes atributos</h4>
                    <table class="table  table-bordered">
                        <thead class="thead-dark">
                            <tr style="padding: 0px !important;">
                                <th scope="col">Nombre</th>
                                <th scope="col">Tipo</th>
                                <th scope="col">Descripción</th>
                                <th scope="col">Ejemplo</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th scope="row">appointmentId</th>
                                <td>Number</td>
                                <td>Si la atención esta asociada a una cita debe colocar el id de la cita</td>
                                <td>2345</td>
                            </tr>
                            <tr>
                                <th scope="row">impression</th>
                                <td>String</td>
                                <td>Impresión diagnosticqa</td>
                                <td>"Esta es la impresión diagnostica"</td>
                            </tr>
                            <tr>
                                <th scope="row">managementPlan</th>
                                <td>String</td>
                                <td>Plan de manejo</td>
                                <td>"Este es el plan de manejo"</td>
                            </tr>
                            <tr>
                                <th scope="row">configurationClinicHistoryId</th>
                                <td>Number</td>
                                <td>Id de la configuracion de la historia clínica que se quiere crear</td>
                                <td>1234</td>
                            </tr>
                            <tr>
                                <th scope="row">patientDocumentType</th>
                                <td>Number</td>
                                <td>
                                    Tipo de documento del Paciente:  
                                        <ul>
                                            <li>1 = Cédula ciudadanía</li>
                                            <li>2 = Cédula de extranjería</li>
                                            <li>3 = Carné diplomático</li>
                                            <app-parameters-modal-button [type]="'documents'"></app-parameters-modal-button>
                                        </ul>
                                </td>
                                <td>2</td> 
                            </tr>
                            <tr>
                                <th scope="row">documentNumber</th>
                                <td>String</td>
                                <td>Número de documento del paciente</td>
                                <td>"123456789"</td>     
                            </tr>
                            <tr>
                                <th scope="row">patientVitalSignsRecord</th>
                                <td>PatientVitalSignsRecord</td>
                                <td>Signos vitales que se van a diligenciar.</td>
                                <td></td>
                            </tr>
                            <tr>
                                <th scope="row">sectionDiagnostic</th>
                                <td>SectionDiagnostic</td>
                                <td>Seccion de diagnósticos de la atención</td>
                                <td></td>
                            </tr>
                            <tr>
                                <th scope="row">patientPhysicalExamination</th>
                                <td>PatientPhysicalExamination</td>
                                <td>Sección de examen físico completo</td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>

                    <h4>PatientVitalSignsRecord contiene los siguientes atributos</h4>
                    <table class="table  table-bordered">
                        <thead class="thead-dark">
                            <tr style="padding: 0px !important;">
                                <th scope="col">Nombre</th>
                                <th scope="col">Tipo</th>
                                <th scope="col">Descripción</th>
                                <th scope="col">Ejemplo</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th scope="row">weight</th>
                                <td>Float</td>
                                <td>Peso corporal KG</td>
                                <td>70</td>
                            </tr>
                            <tr>
                                <th scope="row">heightCm</th>
                                <td>Float</td>
                                <td>Altura en cm</td>
                                <td>123</td>
                            </tr>
                            <tr>
                                <th scope="row">systolicBloodPressure</th>
                                <td>Float</td>
                                <td>Tensión arterial sistólica mmHg</td>
                                <td>12</td>     
                            </tr>
                            <tr>
                                <th scope="row">diastolicBloodPressure</th>
                                <td>Float</td>
                                <td>Tensión arterial sistólica mmHg</td>
                                <td>8</td>
                            </tr>
                            <tr>
                                <th scope="row">heartRate</th>
                                <td>Number</td>
                                <td>Frecuencia cardiaca ppm</td>
                                <td>82</td>
                            </tr>
                            <tr>
                                <th scope="row">breathingRate</th>
                                <td>Number</td>
                                <td>Frecuencia respiratoria r/m</td>
                                <td>86</td>
                            </tr>
                            <tr>
                                <th scope="row">temperature</th>
                                <td>Float</td>
                                <td>Temperatura corporal °C</td>
                                <td>37</td>
                            </tr>
                            <tr>
                                <th scope="row">karnofsy</th>
                                <td>Number</td>
                                <td>karnofsy / ECOG mmHg</td>
                                <td>8</td>
                            </tr>
                            <tr>
                                <th scope="row">imc</th>
                                <td>Float</td>
                                <td>Índice de masa corporal</td>
                                <td>130</td>
                            </tr>
                            <tr>
                                <th scope="row">sp02</th>
                                <td>Float</td>
                                <td>Saturación oxígeno %</td>
                                <td>99 %</td>
                            </tr>
                            <tr>
                                <th scope="row">bodyArea</th>
                                <td>Float</td>
                                <td>Area corporal ASC</td>
                                <td>80</td>
                            </tr>
                            <tr>
                                <th scope="row">vo2Maximum</th>
                                <td>Float</td>
                                <td>vo2 maximo ml/kg-min</td>
                                <td>8</td>
                            </tr>
                            <tr>
                                <th scope="row">abdominalCircunference</th>
                                <td>Float</td>
                                <td>Circunferencia abdominal (cm)</td>
                                <td>75</td>
                            </tr>
                            <tr>
                                <th scope="row">waistCircunference</th>
                                <td>Float</td>
                                <td>Circunferencia de cintura (cm)</td>
                                <td>75</td>
                            </tr>
                            <tr>
                                <th scope="row">hipCircunference</th>
                                <td>Float</td>
                                <td>Circunferencia de cadera (cm)</td>
                                <td>60</td>
                            </tr>
                            <tr>
                                <th scope="row">waistOverHipIndex</th>
                                <td>Float</td>
                                <td>Relación (Cintura / Cadera)</td>
                                <td>75</td>
                            </tr>
                            <tr>
                                <th scope="row">amountOfChestFlexionsIn30Seconds</th>
                                <td>Float</td>
                                <td>Cantidad de flexiones de pecho en 30''</td>
                                <td>60</td>
                            </tr>
                            <tr>
                                <th scope="row">amountOfAbsIn30Seconds</th>
                                <td>Float</td>
                                <td>Cantidad de abdominales en 30''</td>
                                <td>23</td>
                            </tr>
                            <tr>
                                <th scope="row">amountOfBurpeesIn30Seconds</th>
                                <td>Float</td>
                                <td>Cantidad de burpress en 30''</td>
                                <td>13</td>
                            </tr>
                            <tr>
                                <th scope="row">flexibilityInCm</th>
                                <td>Float</td>
                                <td>Flexibilidad (cm)</td>
                                <td>63</td>
                            </tr>
                            <tr>
                                <th scope="row">musculoskeletalWeightKg</th>
                                <td>Float</td>
                                <td>Peso musculo-escelético (Kg)</td>
                                <td>75</td>
                            </tr>
                            <tr>
                                <th scope="row">percentageBodyFat</th>
                                <td>Float</td>
                                <td>Porcentaje de grasa corporal (%)</td>
                                <td>75</td>
                            </tr>
                            <tr>
                                <th scope="row">visceralFatLevel</th>
                                <td>Float</td>
                                <td>Nivel de grasa visceral</td>
                                <td>5</td>
                            </tr>
                            <tr>
                                <th scope="row">amountOfBodyWaterLitres</th>
                                <td>Float</td>
                                <td>Cantidad de agua corporal (L)</td>
                                <td>20</td>
                            </tr>
                            <tr>
                                <th scope="row">amountOfBodyProteinesKg</th>
                                <td>Float</td>
                                <td>Cantidad de protenias corporales (Kg)</td>
                                <td>15</td>
                            </tr>
                            <tr>
                                <th scope="row">amountOfBodyMineralsKg</th>
                                <td>Float</td>
                                <td>Cantidad de minerales corporales (Kg)</td>
                                <td>16</td>
                            </tr>
                            <tr>
                                <th scope="row">amountOfBodyFatKg</th>
                                <td>Float</td>
                                <td>Cantidad de grasa corporal (Kg)</td>
                                <td>7</td>
                            </tr>
                            <tr>
                                <th scope="row">amountOfLeftArmMuscleKg</th>
                                <td>Float</td>
                                <td>Cantidad músculo brazo izquierdo (Kg)</td>
                                <td>8</td>
                            </tr>
                            <tr>
                                <th scope="row">amountOfRightArmMuscleKg</th>
                                <td>Float</td>
                                <td>Cantidad músculo brazo derecho (Kg)</td>
                                <td>6</td>
                            </tr>
                            <tr>
                                <th scope="row">amountOfBodyTrunkMuscleKg</th>
                                <td>Float</td>
                                <td>Cantidad músculo tronco (Kg)</td>
                                <td>11</td>
                            </tr>
                            <tr>
                                <th scope="row">amountOfLeftLegMuscleKg</th>
                                <td>Float</td>
                                <td>Cantidad músculo pierna izquierda (Kg)</td>
                                <td>5</td>
                            </tr>
                            <tr>
                                <th scope="row">amountOfRightLegMuscleKg</th>
                                <td>Float</td>
                                <td>Cantidad músculo pierna derecha (Kg)</td>
                                <td>4</td>
                            </tr>
                            <tr>
                                <th scope="row">amountOfLeftArmFatKg</th>
                                <td>Float</td>
                                <td>Cantidad grasa brazo izquierdo (Kg)</td>
                                <td>12</td>
                            </tr>
                            <tr>
                                <th scope="row">amountOfRightArmFatKg</th>
                                <td>Float</td>
                                <td>Cantidad grasa brazo derecho (Kg)</td>
                                <td>11.5</td>
                            </tr>
                            <tr>
                                <th scope="row">amountOfBodyTrunkFatKg</th>
                                <td>Float</td>
                                <td>Cantidad grasa tronco (Kg)</td>
                                <td>14</td>
                            </tr>
                            <tr>
                                <th scope="row">amountOfLeftLegFatKg</th>
                                <td>Float</td>
                                <td>Cantidad grasa pierna izquierda (Kg)</td>
                                <td>18</td>
                            </tr>
                            <tr>
                                <th scope="row">amountOfRightLegFatKg</th>
                                <td>Float</td>
                                <td>Cantidad grasa pierna derecha (Kg)</td>
                                <td>16</td>
                            </tr>
                            <tr>
                                <th scope="row">restingMetabolicRateKcalDay</th>
                                <td>Float</td>
                                <td>Índice metabólico en reponso (KCal)</td>
                                <td>5</td>
                            </tr>
                            <tr>
                                <th scope="row">usualWeight</th>
                                <td>Float</td>
                                <td>Peso Usual (Kg)</td>
                                <td>50</td>
                            </tr>
                            <tr>
                                <th scope="row">brachialCircumcourse</th>
                                <td>Float</td>
                                <td>Circunferencia Braquial (cm)</td>
                                <td>15</td>
                            </tr>
                            <tr>
                                <th scope="row">tricipitalFold</th>
                                <td>Float</td>
                                <td>Pliegue Tricipital (mm)</td>
                                <td>500</td>
                            </tr>
                            <tr>
                                <th scope="row">circumferencePalette</th>
                                <td>Float</td>
                                <td>Circunferencia Pantorrilla (cm)</td>
                                <td>75</td>
                            </tr>
                            <tr>
                                <th scope="row">muscularCircumferenceCmbBranch</th>
                                <td>Float</td>
                                <td>Circunferencia muscular del brazo CMB (cm)</td>
                                <td>15</td>
                            </tr>
                            <tr>
                                <th scope="row">charlsonComorbidityIndex</th>
                                <td>Float</td>
                                <td>Índice de Comorbilidad de Charlson</td>
                                <td>5</td>
                            </tr>
                            <tr>
                                <th scope="row">mediumArterialTension</th>
                                <td>Float</td>
                                <td>Tensión Arterial Media</td>
                                <td>12</td>
                            </tr>
                            <tr>
                                <th scope="row">neckCircumference</th>
                                <td>Float</td>
                                <td>Circunferencia de Cuello (cm)</td>
                                <td>53</td>
                            </tr>
                            <tr>
                                <th scope="row">uterineHeight</th>
                                <td>Float</td>
                                <td>Altura Uterina (Cm)</td>
                                <td>23</td>
                            </tr>
                            <tr>
                                <th scope="row">fetalHeartRate1</th>
                                <td>Float</td>
                                <td>Frecuencia cardíaca fetal (Feto 1)</td>
                                <td>13</td>
                            </tr>
                            <tr>
                                <th scope="row">fetalHeartRate2</th>
                                <td>Float</td>
                                <td>Frecuencia cardíaca fetal (Feto 2)</td>
                                <td>14</td>
                            </tr>
                            <tr>
                                <th scope="row">fetalHeartRate3</th>
                                <td>Float</td>
                                <td>Frecuencia cardíaca fetal (Feto 3)</td>
                                <td>13</td>
                            </tr>
                            <tr>
                                <th scope="row">maximumWeight</th>
                                <td>Float</td>
                                <td>Peso máximo (Kg)</td>
                                <td>130</td>
                            </tr>
                            <tr>
                                <th scope="row">poem</th>
                                <td>Float</td>
                                <td>POEM</td>
                                <td>13</td>
                            </tr>
                            <tr>
                                <th scope="row">dlqi</th>
                                <td>Float</td>
                                <td>DLQI</td>
                                <td>13</td>
                            </tr>
                            <tr>
                                <th scope="row">escorad</th>
                                <td>Float</td>
                                <td>ESCORAD</td>
                                <td>13</td>
                            </tr>
                            <tr>
                                <th scope="row">easi</th>
                                <td>Float</td>
                                <td>EASI</td>
                                <td>13</td>
                            </tr>
                            <tr>
                                <th scope="row">imcMaximum</th>
                                <td>Float</td>
                                <td>IMC máximo</td>
                                <td>13</td>
                            </tr>
                            <tr>
                                <th scope="row">asaClassification</th>
                                <td>Float</td>
                                <td>Clasificación ASA</td>
                                <td>13</td>
                            </tr>
                            <tr>
                                <th scope="row">ocularPressureEyeLeft</th>
                                <td>Float</td>
                                <td>Presión intraocular ojo izquierdo (mmHg)</td>
                                <td>13</td>
                            </tr>
                            <tr>
                                <th scope="row">ocularPressureEyeRight</th>
                                <td>Float</td>
                                <td>Presión intraocular ojo derecho (mmHg)</td>
                                <td>13</td>
                            </tr>
                            <tr>
                                <th scope="row">pulse</th>
                                <td>Float</td>
                                <td>Pulso</td>
                                <td>13</td>
                            </tr>
                            <tr>
                                <th scope="row">headCircunference</th>
                                <td>Float</td>
                                <td>Perímetro Cefálico</td>
                                <td>13</td>
                            </tr>
                            <tr>
                                <th scope="row">imcPreoperative</th>
                                <td>Float</td>
                                <td>IMC Preoperatorio</td>
                                <td>13</td>
                            </tr>
                            <tr>
                                <th scope="row">peImc</th>
                                <td>Float</td>
                                <td>PE IMC</td>
                                <td>13</td>
                            </tr>
                            <tr>
                                <th scope="row">generalHealthPatientEval</th>
                                <td>Float</td>
                                <td>Evaluación de salud general por parte del paciente</td>
                                <td>13</td>
                            </tr>
                            <tr>
                                <th scope="row">testWells</th>
                                <td>Float</td>
                                <td>Test de wells (cm)</td>
                                <td>13</td>
                            </tr>
                            <tr>
                                <th scope="row">crestIliacCi</th>
                                <td>Float</td>
                                <td>Cresta iliaca CI (mm)</td>
                                <td>13</td>
                            </tr>
                            <tr>
                                <th scope="row">spineIliacEi</th>
                                <td>Float</td>
                                <td>Espina iliaca EI (mm)</td>
                                <td>13</td>
                            </tr>
                            <tr>
                                <th scope="row">abdominalAbd</th>
                                <td>Float</td>
                                <td>Abdominal ABD (mm)</td>
                                <td>13</td>
                            </tr>
                            <tr>
                                <th scope="row">thighMu</th>
                                <td>Float</td>
                                <td>Muslo MU (mm)</td>
                                <td>13</td>
                            </tr>
                            <tr>
                                <th scope="row">calfPi</th>
                                <td>Float</td>
                                <td>Pantorrilla PI (mm)</td>
                                <td>13</td>
                            </tr>
                            <tr>
                                <th scope="row">neckCircumferenceCc</th>
                                <td>Float</td>
                                <td>Circunferencia de cuello CC (cm)</td>
                                <td>13</td>
                            </tr>
                            <tr>
                                <th scope="row">subScapularSkinfoldsSe</th>
                                <td>Float</td>
                                <td>Pliegues cutáneos sub escapular SE (mm)</td>
                                <td>13</td>
                            </tr>
                            <tr>
                                <th scope="row">bicepsBc</th>
                                <td>Float</td>
                                <td>Biceps BC (mm)</td>
                                <td>13</td>
                            </tr>
                            <tr>
                                <th scope="row">headCircunferenceFetal</th>
                                <td>Float</td>
                                <td>Perímetro Cefálico Fetal</td>
                                <td>13</td>
                            </tr>
                            <tr>
                                <th scope="row">imcPostoperative</th>
                                <td>Float</td>
                                <td>IMC Postoperatorio</td>
                                <td>13</td>
                            </tr>
                            <tr>
                                <th scope="row">additionalComents</th>
                                <td>String</td>
                                <td>Examen físico</td>
                                <td>13</td>
                            </tr>
                        </tbody>
                    </table>

                    <h4>currentIllness contiene los siguientes atributos</h4>
                    <table class="table  table-bordered">
                        <thead class="thead-dark">
                            <tr style="padding: 0px !important;">
                                <th scope="col">Nombre</th>
                                <th scope="col">Tipo</th>
                                <th scope="col">Descripción</th>
                                <th scope="col">Ejemplo</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th scope="row">attentionModality</th>
                                <td>String</td>
                                <td>
                                    Modalidad de la consulta:  
                                        <ul>
                                            <li>Convencional = CONVENTIONAL</li>
                                            <li>Telemedicina = TELEMEDICINE</li>
                                            <li>Asistida = ASSISTED</li>
                                            <li>No aplica = NO_APLICA</li>
                                        </ul>
                                </td>
                                <td>"CONVENTIONAL"</td> 
                            </tr>
                            <tr>
                                <th scope="row">consultationReason</th>
                                <td>Number</td>
                                <td>
                                    id del Tipo de consulta:  
                                        <ul>
                                            <li>1 = Primera Vez</li>
                                            <li>2 = Control</li>
                                            <li>4 = Otro</li>
                                        </ul>
                                </td>
                                <td>1</td> 
                            </tr>
                            <tr>
                                <th scope="row">consultationName</th>
                                <td>String</td>
                                <td>Nombre de la consulta</td>
                                <td>"Consulta de prueba"</td>
                            </tr>
                            <tr>
                                <th scope="row">attentionConsecutive</th>
                                <td>String</td>
                                <td>Consecutivo de la consulta. Si se deja null el sistema lo calculara</td>
                                <td>"Atención 16"</td>
                            </tr>
                            <tr>
                                <th scope="row">consultationActualDisease</th>
                                <td>String</td>
                                <td>Enfermedad actual y motivo de la consulta</td>
                                <td>"Enfermedad actual"</td>
                            </tr>
                            <tr>
                                <th scope="row">actualDiseaseComment</th>
                                <td>String</td>
                                <td>Observaciones</td>
                                <td>"comentario"</td>
                            </tr>
                            <tr>
                                <th scope="row">attenderUserDocumentType</th>
                                <td>Number</td>
                                <td>
                                    Tipo de documento del especialista que asiste:  
                                        <ul>
                                            <li>1 = Cédula ciudadanía</li>
                                            <li>2 = Cédula de extranjería</li>
                                            <li>3 = Carné diplomático</li>
                                            <app-parameters-modal-button [type]="'documents'"></app-parameters-modal-button>
                                        </ul>
                                </td>
                                <td>2</td> 
                            </tr>
                            <tr>
                                <th scope="row">attenderUserDocumentNumber</th>
                                <td>String</td>
                                <td>Número de documento del especialista que asiste</td>
                                <td>"123456789"</td>     
                            </tr>
                        </tbody>
                    </table>

                    <h4>SectionDiagnostic contiene los siguientes atributos</h4>
                    <table class="table  table-bordered">
                        <thead class="thead-dark">
                            <tr style="padding: 0px !important;">
                                <th scope="col">Nombre</th>
                                <th scope="col">Tipo</th>
                                <th scope="col">Descripción</th>
                                <th scope="col">Ejemplo</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th scope="row">externalCause</th>
                                <td>Number</td>
                                <td>id de causa externa</td>
                                <td>1</td> 
                            </tr>
                            <tr>
                                <th scope="row">diagnosticList</th>
                                <td>Object[]</td>
                                <td>Lista de diagnosticos</td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>

                    <h4>PatientDiagnostic contiene los siguientes atributos</h4>
                    <table class="table  table-bordered">
                        <thead class="thead-dark">
                            <tr style="padding: 0px !important;">
                                <th scope="col">Nombre</th>
                                <th scope="col">Tipo</th>
                                <th scope="col">Descripción</th>
                                <th scope="col">Ejemplo</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th scope="row">diagnosticCie10</th>
                                <td>String</td>
                                <td>Codigo del cie10</td>
                                <td>"A000"</td> 
                            </tr>
                            <tr>
                                <th scope="row">diagnosticDate</th>
                                <td>ZonedDateTime</td>
                                <td>Fecha y hora del diagnóstico en formato yyyy-MM-dd HH:mm</td>
                                <td>"2022-04-30 09:15"</td>
                            </tr>
                            <tr>
                                <th scope="row">diagnosticClasification</th>
                                <td>Number</td>
                                <td>id de la Clasificación del diagnóstico</td>
                                <td>1</td>
                            </tr>
                            <tr>
                                <th scope="row">diagnosticType</th>
                                <td>Number</td>
                                <td>id del tipo de diagnóstico</td>
                                <td>1</td>
                            </tr>
                            <tr>
                                <th scope="row">diagnosticObservations</th>
                                <td>String</td>
                                <td>observaciones del diagnóstico</td>
                                <td>"estas son observaciones"</td>
                            </tr>
                            <tr>
                                <th scope="row">eyes</th>
                                <td>Number</td>
                                <td>id del ojo con glaucoma</td>
                                <td>1</td>
                            </tr>
                            <tr>
                                <th scope="row">diabetesType</th>
                                <td>Number</td>
                                <td>id del tipo de diabetes</td>
                                <td>1</td>
                            </tr>
                        </tbody>
                    </table>

                    <h4>PatientPhysicalExamination contiene los siguientes atributos</h4>
                    <table class="table  table-bordered">
                        <thead class="thead-dark">
                            <tr style="padding: 0px !important;">
                                <th scope="col">Nombre</th>
                                <th scope="col">Tipo</th>
                                <th scope="col">Descripción</th>
                                <th scope="col">Ejemplo</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th scope="row">headEvaluation</th>
                                <td>String</td>
                                <td>Evaluación de la region cabeza</td>
                                <td>"NORMAL"</td> 
                            </tr>
                            <tr>
                                <th scope="row">headFinding</th>
                                <td>String</td>
                                <td>Hallazgos de la region cabeza</td>
                                <td>"esta área fue evaluada y se encontro"</td> 
                            </tr>
                            <tr>
                                <th scope="row">neckEvaluation</th>
                                <td>String</td>
                                <td>Evaluación de la region cuello</td>
                                <td>"ANORMAL"</td> 
                            </tr>
                            <tr>
                                <th scope="row">neckFinding</th>
                                <td>String</td>
                                <td>Hallazgos de la region cuello</td>
                                <td>"esta área fue evaluada y se encontro"</td> 
                            </tr>
                            <tr>
                                <th scope="row">chestEvaluation</th>
                                <td>String</td>
                                <td>Evaluación de la region pecho</td>
                                <td>"NO_EVALUADO"</td> 
                            </tr>
                            <tr>
                                <th scope="row">chestFinding</th>
                                <td>String</td>
                                <td>Hallazgos de la region pecho</td>
                                <td>"esta área fue evaluada y se encontro"</td> 
                            </tr>
                            <tr>
                                <th scope="row">abdomenEvaluation</th>
                                <td>String</td>
                                <td>Evaluación de la region abdómen</td>
                                <td>"NORMAL"</td> 
                            </tr>
                            <tr>
                                <th scope="row">abdomenFinding</th>
                                <td>String</td>
                                <td>Hallazgos de la region abdómen</td>
                                <td>"esta área fue evaluada y se encontro"</td> 
                            </tr>
                            <tr>
                                <th scope="row">neurologicalEvaluation</th>
                                <td>String</td>
                                <td>Evaluación de la region neurológica</td>
                                <td>"NORMAL"</td> 
                            </tr>
                            <tr>
                                <th scope="row">neurologicalFinding</th>
                                <td>String</td>
                                <td>Hallazgos de la region neurológica</td>
                                <td>"esta área fue evaluada y se encontro"</td> 
                            </tr>
                            <tr>
                                <th scope="row">extremitiesEvaluation</th>
                                <td>String</td>
                                <td>Evaluación de la region extremidades</td>
                                <td>"NORMAL"</td> 
                            </tr>
                            <tr>
                                <th scope="row">extremitiesFinding</th>
                                <td>String</td>
                                <td>Hallazgos de la region neurológica</td>
                                <td>"esta área fue evaluada y se encontro"</td> 
                            </tr>
                            <tr>
                                <th scope="row">skinFacerEvaluation</th>
                                <td>String</td>
                                <td>Evaluación de la region piel y fanera</td>
                                <td>"NORMAL"</td> 
                            </tr>
                            <tr>
                                <th scope="row">skinFacerFinding</th>
                                <td>String</td>
                                <td>Hallazgos de la region piel y fanera</td>
                                <td>"esta área fue evaluada y se encontro"</td> 
                            </tr>
                        
                        </tbody>
                    </table>

                    <h4>SystemReview contiene los siguientes atributos</h4>
                    <table class="table  table-bordered">
                        <thead class="thead-dark">
                            <tr style="padding: 0px !important;">
                                <th scope="col">Nombre</th>
                                <th scope="col">Tipo</th>
                                <th scope="col">Descripción</th>
                                <th scope="col">Ejemplo</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td scope="row">type</td>
                                <td>Number</td>
                                <td>Id de la revisión por sistemas</td>
                                <td>1</td>
                            </tr>
                            <tr>
                                <td scope="row">symptoms</td>
                                <td>Object[]</td>
                                <td>Sintomas</td>
                                <td>["Mareos", "Dolor de cabeza"]</td>
                            </tr>
                        </tbody>
                    </table>

                    <hr>
                    <app-footer></app-footer>
        </div>
    </div>
</div>
