import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-examples-curl',
  templateUrl: './examples-curl.component.html',
  styleUrls: ['./examples-curl.component.css']
})
export class ExamplesCurlComponent implements OnInit {

  @Input() curlEjemplo:any;
  @Input() curlProduccion:any;

  show=true;

  environmentShow(){
    if(sessionStorage.getItem('environment') == '0' ){
      this.show = false;
    }
  }

  constructor() { }

  ngOnInit(): void {
    this.environmentShow();
  }

}
