<div id="layoutSidenav">
    <div id="layoutSidenav_content">
        <br>
        <div class="container">
            <app-main-title [title]="title" [subTitle]="subTitle"></app-main-title>

            <h4>Una cita contiene los siguientes atributos</h4>

                    <table class="table table-bordered">
                        <thead class="thead-dark">
                            <tr style="padding: 0px !important;">
                                <th scope="col">Nombre</th>
                                <th scope="col">Tipo</th>
                                <th scope="col">Descripción</th>
                                <th scope="col">Ejemplo</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th scope="row">startAppointment</th>
                                <td>ZonedDateTime</td>
                                <td>Fecha y hora de inicio de la cita médica en formato yyyy-MM-dd HH:mm</td>
                                <td>"2021-04-30 08:45"</td>
                            </tr>
                            <tr>
                                <th scope="row">endAppointment</th>
                                <td>ZonedDateTime</td>
                                <td>Fecha y hora de finalización de la cita médica en formato yyyy-MM-dd HH:mm</td>
                                <td>"2021-04-30 09:15"</td>
                            </tr>
                            <tr>
                                <th scope="row">patientDocumentType</th>
                                <td>Long</td>
                                <td>
                                    Tipo de documento del Paciente:  
                                        <ul>
                                            <li>1 = Cédula ciudadanía</li>
                                            <li>2 = Cédula de extranjería</li>
                                            <li>3 = Carné diplomático</li>
                                            <app-parameters-modal-button [type]="'documents'"></app-parameters-modal-button> 
                                        </ul>
                                </td>
                                <td>2</td> 
                            </tr>
                            <tr>
                                <th scope="row">patientDocumentNumber</th>
                                <td>String</td>
                                <td>Número de documento del paciente</td>
                                <td>"123456789"</td>     
                            </tr>
                            <tr>
                                <th scope="row">doctorDocumentType</th>
                                <td>Long</td>
                                <td>
                                    Tipo de documento del doctor:  
                                        <ul>
                                            <li>1 = Cédula ciudadanía</li>
                                            <li>2 = Cédula de extranjería</li>
                                            <li>3 = Carné diplomático</li>
                                            <app-parameters-modal-button [type]="'documents'"></app-parameters-modal-button>
                                        </ul>
                                </td>
                                <td>1</td> 
                            </tr>
                            <tr>
                                <th scope="row">doctorDocumentNumber</th>
                                <td>String</td>
                                <td>Número de documento del doctor</td>
                                <td>"123456789"</td>     
                            </tr>
                            <tr>
                                <th scope="row">modality</th>
                                <td>String</td>
                                <td>modalidad de la atención:
                                    <ul>
                                        <li>CONVENTIONAL = cita convencional en el consultorio del doctor</li>
                                        <li>TELEMEDICINE = cita por video llamada</li>
                                        <li>DOMICILIARY = cita en la a domicilio del paciente</li>            
                                    </ul>
                                </td>
                                <td>"CONVENTIONAL"</td>
                            </tr>
                            <tr>
                                <th scope="row">stateAppointment</th>
                                <td>String</td>
                                <td>Estado de la cita <app-parameters-modal-button [type]="'states'"></app-parameters-modal-button> </td>
                                <td>"PENDING"</td>     
                            </tr>
                            <tr>
                                <th scope="row">notificationState</th>
                                <td>String</td>
                                <td>Estado de confirmación por parte del paciente:
                                    <ul>
                                        <li>ATTEND = (Confirmado) el Paciente confirma asistir a la cita</li>
                                        <li>NOT_ATTEND = (Rechazado) el paciente no asistira a la cita</li>
                                        <li>NOT_RESPOND = (Desconocido) no se ha contactado con el paciente</li>            
                                    </ul>
                                </td>
                                <td>"ATTEND"</td>
                            </tr>
                            <tr>
                                <th scope="row">appointmentType</th>
                                <td>String</td>
                                <td>tipo de cita</td>
                                <td>"cita de prueba"</td> 
                            </tr>
                            <tr>
                                <th scope="row">clinic</th>
                                <td>Long</td>
                                <td>Id de la clínica donde será la cita:
                                    <app-parameters-modal-button [type]="'clinics'"></app-parameters-modal-button>
                                </td>
                                <td>8</td> 
                            </tr>
                            <tr>
                                <th scope="row">comment</th>
                                <td>String</td>
                                <td>comentarios de la cita</td>
                                <td>"el paciente viene acompañado de su hijo"</td> 
                            </tr>
                        </tbody>
                    </table>
                    <hr>
          
                    <app-footer></app-footer>
        </div>
    </div>
</div>
