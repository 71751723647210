<div id="layoutSidenav">
    <div id="layoutSidenav_content">
        <br>
        <div class="container">
            <app-main-title [title]="title" [subTitle]="subTitle"></app-main-title>
            <app-main-paragraph [paragraphTitle]="paragraphTitle" [paragraph]="paragraph"></app-main-paragraph>
         
            <h4>Los Antecedentes Familiares del paciente contienen los siguientes atributos</h4>
            <table class="table table-bordered">
                <thead class="thead-dark">
                    <tr style="padding: 0px !important;">
                        <th scope="col">Nombre</th>
                        <th scope="col">Tipo</th>
                        <th scope="col">Descripci&oacute;n</th>
                        <th scope="col">Ejemplo</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th scope="row">documentNumber</th>
                        <td>String</td>
                        <td>Número de documento del paciente</td>
                        <td>"123456789"</td>     
                    </tr>
                    <tr>
                        <th scope="row">documentType</th>
                        <td>Long</td>
                        <td>
                            Tipo de documento del Paciente:  
                                <ul>
                                    <li>1 = Cédula ciudadanía</li>
                                    <li>2 = Cédula de extranjería</li>
                                    <li>3 = Carné diplomático</li>
                                    <app-parameters-modal-button [type]="'documents'"></app-parameters-modal-button>
                                </ul>
                        </td>
                        <td>2</td> 
                    </tr>
                    <tr>
                        <th scope="row">encounterCommonInfo</th>
                        <td>Long</td>
                        <td>ID de la historia clínica</td>
                        <td>46574</td>
                    </tr> 
                    <tr>
                        <th scope="row">familiarRelationshipType</th>
                        <td>Long</td>
                        <td>
                            Parentesco con el familiar:
                            <ul>
                                <li>1 = Hijo</li>
                                <li>2 = Hermanos</li>
                                <li>3 = Madre</li>
                                <li>4 = Padre</li>
                                <li>5 = T&iacute;o</li>
                                <li>6 = Primo</li>
                                <li>7 = Familiar en segundo grado</li>
                                <li>8 = Colega</li>
                                <li>9 = Nieto</li>
                                <li>10 = Abuelo</li>
                                <li>11 = Amigo</li>
                                <li>12 = Ninguno</li>
                                <li>13 = Otro</li>
                                <li>14 = No aplica</li>
                                <li>15 = C&oacute;nyuge</li>
                                <li>16 = Padres</li>
                                <app-parameters-modal-button [type]="'familiarRelationshipType'"></app-parameters-modal-button> 
                            </ul>
                        </td>
                        <td>
                            5
                        </td>
                    </tr> 
                    <tr>
                        <th scope="row">diagnosticText</th>
                        <td>String</td>
                        <td>Detalles del diagn&oacute;stico,
                            No se debe enviar el campo <b>diagnosticType</b> ya que se tomará este texto como el diagnóstico a registrar. (máximo 255 caracteres)</td>
                        <td>"Detalles del diagn&oacute;stico"</td>
                    </tr> 
                    <tr>
                        <th scope="row">diagnosticType</th>
                        <td>String</td>
                        <td>C&oacute;digo del diagn&oacute;stico CIE10,
                            No se debe enviar el campo <b>diagnosticText</b> ya que se tomará este c&oacute;digo como el diagn&oacute;stico a registrar. (máximo 10 caracteres)</td>
                        <td>"A000"</td>
                    </tr> 
                    <tr>
                        <th scope="row">diagnosisDate</th>
                        <td>LocalDate</td>
                        <td>Fecha de diagnosis ("yyyy-mm-dd")</td>
                        <td>"2022-10-10"</td>
                    </tr> 
                    <tr>
                        <th scope="row">comments</th>
                        <td>String</td>
                        <td>Comentarios del diagn&oacute;stico, (máximo 255 caracteres)</td>
                        <td>
                            "Comentario del diagn&oacute;stico"
                        </td>
                    </tr>
                </tbody>
            </table>
            <hr>
            <app-footer></app-footer>
        </div>
    </div>
</div>
