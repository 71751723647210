<h4>Enlaces relacionados</h4>
<a href="apikey">ApiKey</a>
<br>
<a href="patient">Pacientes</a>
<br>
<a href="appointment">Citas</a>
<br>
<a href="webhook">WebHooks</a>


<footer class="py-4 bg-light mt-auto">
    <div class="d-flex align-items-center justify-content-between small">
        <div class="text-muted">Derechos Reservados 2021 &copy; CareCloud S.A.S.</div>
    </div>
</footer>