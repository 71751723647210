<div id="layoutSidenav">
    <div id="layoutSidenav_content">
        <br>
        <div class="container">
            <app-main-title [title]="title" [subTitle]="subTitle"></app-main-title>
            <app-main-paragraph [paragraphTitle]="paragraphTitle" [paragraph]="paragraph"></app-main-paragraph>
            <hr>
            <h4>Servicio</h4>
            <app-authentication-nav-tabs></app-authentication-nav-tabs>
            <hr>
            <app-body-parameters [parameters]="parameters" ></app-body-parameters>
            <hr>
            <h4>Ejemplo</h4>
            <app-examples-code [json]="jsonEjemplos"></app-examples-code>
            <app-examples-curl [curlEjemplo]="curlEjemplo" ></app-examples-curl>
            <hr>
            <h4>Resultados</h4>
            <app-examples-code [json]="jsonResultados"></app-examples-code>
            <hr>
            <app-authentication></app-authentication>
            <hr>
            <app-footer></app-footer>
        </div>
    </div>
</div>
