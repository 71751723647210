import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ParametersService {

  pages: string = "0";

  constructor(private http: HttpClient) { }

  getParameters(type:string, act:string, textSearch:string){

    let url:string = 'https://saludtools.qa.carecloud.com.co/integration/parametric/';

    if(sessionStorage.environment == 0){
      url= 'https://saludtools.carecloud.com.co/integration/parametric/';
    }
    if(sessionStorage.environment == 1){
      url= 'https://saludtools.qa.carecloud.com.co/integration/parametric/';
    }

    const urlParametersLocal = 'http://localhost:8090/integration/parametric/';

    const accessToken=localStorage.access_token;
                                                                                                                                                                           
    return this.http.get(url + type + "/v1/", {
      headers: {
        Authorization: 'Bearer ' + accessToken,
      },
      params:{principleact: act, page: this.pages, textSearch: textSearch}});
  }

  getEncounterId(type:string, documentType:string,documentNumber:string, startDate:string, endDate:string){

    let url:string = 'https://saludtools.qa.carecloud.com.co/integration/parametric/';

    if(sessionStorage.environment == 0){
      url= 'https://saludtools.carecloud.com.co/integration/parametric/';
    }
    if(sessionStorage.environment == 1){
      url= 'https://saludtools.qa.carecloud.com.co/integration/parametric/';
    }

    const urlParametersLocal = 'http://localhost:8090/integration/parametric/';

    const accessToken=localStorage.access_token;
                                                                                                                                                                           
    return this.http.get(url + type + "/v1/", {
      headers: {
        Authorization: 'Bearer ' + accessToken,
      },
      params:{documentType:documentType,documentNumber:documentNumber, startDate: startDate, endDate:endDate, page: this.pages }});
  }

  getPersonalAntecedent(type:string, documentType:string,documentNumber:string){

    let url:string = 'https://saludtools.qa.carecloud.com.co/integration/parametric/';

    if(sessionStorage.environment == 0){
      url= 'https://saludtools.carecloud.com.co/integration/parametric/';
    }
    if(sessionStorage.environment == 1){
      url= 'https://saludtools.qa.carecloud.com.co/integration/parametric/';
    }

    const urlParametersLocal = 'http://localhost:8090/integration/parametric/';

    const accessToken=localStorage.access_token;
                                                                                                                                                                           
    return this.http.get(url + type + "/v1/", {
      headers: {
        Authorization: 'Bearer ' + accessToken,
      },
      params:{documentType:documentType,documentNumber:documentNumber, page: this.pages }});
  }
  
}
