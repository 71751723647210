<div id="layoutSidenav">
    <div id="layoutSidenav_content">
        <br>
        <div class="container">
            <app-main-title [title]="title" [subTitle]="subTitle"></app-main-title>
            <app-main-paragraph [paragraphTitle]="paragraphTitle" [paragraph]="paragraph"></app-main-paragraph>
            <hr>
            <h4>Consulta los Parametros</h4>
            <ul> 
                <li><app-parameters-modal-button [type]="'documents'"></app-parameters-modal-button></li> 
                <li><app-parameters-modal-button [type]="'eps'"></app-parameters-modal-button></li> 
                <li><app-parameters-modal-button [type]="'clinics'"></app-parameters-modal-button></li>
                <li><app-parameters-modal-button [type]="'genders'"></app-parameters-modal-button></li> 
                <li><app-parameters-modal-button [type]="'states'"></app-parameters-modal-button></li>
                <li><app-parameters-modal-button [type]="'commercialname'"></app-parameters-modal-button></li> 
                <li><app-parameters-modal-button [type]="'principleact'"></app-parameters-modal-button></li>
                <li><app-parameters-modal-button [type]="'atcconcentration'"></app-parameters-modal-button></li>
                <li><app-parameters-modal-button [type]="'intakemethod'"></app-parameters-modal-button></li>
                <li><app-parameters-modal-button [type]="'frequencyunit'"></app-parameters-modal-button></li>
                <li><app-parameters-modal-button [type]="'durationunit'"></app-parameters-modal-button></li>
                <li><app-parameters-modal-button [type]="'encountercommonid'"></app-parameters-modal-button></li>
                <li><app-parameters-modal-button [type]="'configurationclinichistoryid'"></app-parameters-modal-button></li>
                <li><app-parameters-modal-button [type]="'classificationtype'"></app-parameters-modal-button></li>
                <li><app-parameters-modal-button [type]="'unittype'"></app-parameters-modal-button></li>
                <li><app-parameters-modal-button [type]="'paraclinictype'"></app-parameters-modal-button></li>
                <li><app-parameters-modal-button [type]="'attentionModality'"></app-parameters-modal-button></li>
                <li><app-parameters-modal-button [type]="'encounterreasontype'"></app-parameters-modal-button></li>
                <li><app-parameters-modal-button [type]="'examprescriptiontype'"></app-parameters-modal-button></li>
                <li><app-parameters-modal-button [type]="'medicalexamtype'"></app-parameters-modal-button></li>
                <li><app-parameters-modal-button [type]="'remissioncontainerid'"></app-parameters-modal-button></li>
                <li><app-parameters-modal-button [type]="'externalcause'"></app-parameters-modal-button></li>
                <li><app-parameters-modal-button [type]="'diagnosticcie10'"></app-parameters-modal-button></li>
                <li><app-parameters-modal-button [type]="'diagnosticClasification'"></app-parameters-modal-button></li>
                <li><app-parameters-modal-button [type]="'diagnosticType'"></app-parameters-modal-button></li>
                <li><app-parameters-modal-button [type]="'eyestype'"></app-parameters-modal-button></li>
                <li><app-parameters-modal-button [type]="'diabetesType'"></app-parameters-modal-button></li>
                <li><app-parameters-modal-button [type]="'evaluationenum'"></app-parameters-modal-button></li>
                <li><app-parameters-modal-button [type]="'examclassificationtype'"></app-parameters-modal-button></li>
                <li><app-parameters-modal-button [type]="'treatmenareatype'"></app-parameters-modal-button></li>
                <li><app-parameters-modal-button [type]="'inabilityworktype'"></app-parameters-modal-button></li>
                <li><app-parameters-modal-button [type]="'contraceptivetype'"></app-parameters-modal-button></li>
                <li><app-parameters-modal-button [type]="'friendlyname'"></app-parameters-modal-button></li>
                <li><app-parameters-modal-button [type]="'personalantecedentsgroup'"></app-parameters-modal-button></li>
                <li><app-parameters-modal-button [type]="'antecedentspersonal'"></app-parameters-modal-button></li>
                <li><app-parameters-modal-button [type]="'familiarRelationshipType'"></app-parameters-modal-button></li>
         </ul>
           
            <hr>
            <h4>Servicio</h4>
            <app-parameters-nav-tabs></app-parameters-nav-tabs>
            <hr>
            <h4>Resultados</h4>
            <app-examples-code [json]="jsonResultados"></app-examples-code>
            <hr>
            <app-footer></app-footer>
        </div>
    </div>
</div>
