import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-clinical-laboratories-read',
  templateUrl: './clinical-laboratories-read.component.html',
  styleUrls: ['./clinical-laboratories-read.component.css']
})
export class ClinicalLaboratoriesReadComponent implements OnInit {

  title: string = "Leer resultados de laboratorios cliínicos";
  subTitle: string = "lea resultados clinicos"

  paragraphTitle: string = "¿Cómo leer un resultado?";
  paragraph: string = "Para leer un resultado, primero debe tener un Token de acceso activo. Si su Token se venció, o aún no ha generado un Token de acceso. Genere un nuevo Token y consuma el evento de leer resultado.";

  parameters = [
    {
      "Parameters": "eventType",
      "requerido": true,
      "Type": "String",
      "Description": "Es el tipo de evento que se quiere invocar en este caso debe ser \"PARACLINICS\"."
    },
    {
      "Parameters": "actionType",
      "requerido": true,
      "Type": "String",
      "Description": "Es la acción que se quiere realizar para leer un atención debe ser \"READ\""
    },
    {
      "Parameters": "encounterID",
      "requerido": false,
      "Type": "Long",
      "Description": "es el id de la atención que se quiere consultar."
    },
    {
      "Parameters": "patientDocumentType",
      "requerido": true,
      "Type": "Long",
      "Description": "es el id del tipo de documento del Paciente."
    },
    {
      "Parameters": "patientDocumentNumber",
      "requerido": true,
      "Type": "String",
      "Description": "es el numero de documento del paciente que se quiere consultar."
    }
  ]

  jsonEjemplos =
    [{
      "title": "Body",
      "body":
      {
        "eventType": "PARACLINICS",
        "actionType": "READ",
        "body": {
          "encounterID": 306791,
          "patientDocumentType": 1,
          "patientDocumentNumber": "77837"
        }
      },
      "subtitle": "Body para leer un resultado clinico, sustituya los parámetros encounterID por el id de su atención y documento del paciente"
    }
    ]

  curlEjemplo = {
    "title": "cURL",
    "bodyPrueba": "curl --location --request POST 'http://localhost:8090/integration/sync/event/v1/' --header 'Authorization: Bearer XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX' --header 'Content-Type: application/json' --data-raw" + '\'{"eventType": "PARACLINICS","actionType": "READ","body": {"encounterID": 306791,"patientDocumentType": 1,"patientDocumentNumber": "77837"}}',
    "bodyProduccion": "curl --location --request POST 'https://saludtools.carecloud.com.co/integration/sync/event/v1/' --header 'Authorization: Bearer XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX' --header 'Content-Type: application/json' --data-raw" + '\'{"eventType": "PARACLINICS","actionType": "READ","body": {"encounterID": 306791,"patientDocumentType": 1,"patientDocumentNumber": "77837"}}\'',
    "subtitle": "Después de Bearer sustituya  las X por su access-token generado y cambie los parámetros por los datos de sus resultados a leer."
  }

  jsonResultados = [
    {
      "title": "OK 200",
      "body": {
        "id": null,
        "code": 200,
        "message": "Se consulta la informacion de  id: 307111",
        "eventId": "18e7d2a9521d4657baa3d6d6d6882785",
        "body": {
          "id": null,
          "pageable": null,
          "encounterID": 306791,
          "patientDocumentType": 1,
          "patientDocumentNumber": "77837",
          "patientParaclinic": [
            {
              "encounterId": null,
              "patientId": null,
              "value": "2000",
              "classification": 1,
              "unitId": 105,
              "typeId": 1,
              "comments": null,
              "examDate": "2022-09-05 00:00",
              "idCompany": null
            },
            {
              "encounterId": null,
              "patientId": null,
              "value": "2000",
              "classification": 1,
              "unitId": 2,
              "typeId": 1,
              "comments": null,
              "examDate": "2022-09-05 00:00",
              "idCompany": null
            }
          ]
        }
      },
      "subtitle": "La solicitud se realizó con éxito y se ven los datos de la atención.",
      "status": "200"
    },
    {
      "title": "401 Unauthorized",
      "body":
        "No tiene permisos para acceder al servidor",
      "subtitle": "Coloque un token de acceso válido.",
      "status": "401"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
        "code": 412,
        "message": "debe enviar un id de encuentro comun",
        "eventId": "fe645e7a430c40bb946d221fb688351d",
        "body": null
      },
      "subtitle": "debe colocar un id de encuentro",
      "status": "412"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
        "code": 412,
        "message": "Encuentro comun no encontrado",
        "eventId": "fe645e7a430c40bb946d221fb688351d",
        "body": null
      },
      "subtitle": "El id diligenciado no se encuentra en nuestra base de datos, por favor confirme el id",
      "status": "412"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
        "code": 412,
        "message": "No se ha enviado un tipo de evento valido",
        "eventId": "fe645e7a430c40bb946d221fb688351d",
        "body": null
      },
      "subtitle": "Se omitió el parámetro \"eventType\", para leer una antención es obligatorio el parametro \"eventType\", por favor agrégalo al inicio del body como en el ejemplo.",
      "status": "412"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
        "code": 412,
        "message": "No se ha enviado un tipo de evento valido",
        "eventId": "48c76fbf91cf46a19ebd948c3d2a6d5e",
        "body": null
      },
      "subtitle": "Error en el valor del parámetro \"eventType\". Para leer una atención el valor permitido del \"eventType\"es \"CLINIC_HISTORY\".",
      "status": "412"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
        "code": 412,
        "message": "No se ha enviado una accion válida",
        "eventId": "dd24b96692b744e4b97b397e5a3c1dd9",
        "body": null
      },
      "subtitle": "Se omitió el parámetro \"actionType\", para leer una atención es obligatorio el parámetro \"actionType\" que indica la acción, por favor agrégalo al inicio del body como en el ejemplo.",
      "status": "412"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
        "code": 412,
        "message": "No se ha enviado una accion válida",
        "eventId": "a3d1a85dc6b649a2b4cb5ccc10d3e1c3",
        "body": null
      },
      "subtitle": "Error en el valor del parámetro \"actionType\". Para leer una atención el valor permitido del parámetro \"actionType\" es \"READ\".",
      "status": "412"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
        "code": 412,
        "message": "Request method 'PUT' not supported",
        "eventId": "81cf71d0bf1b41f391ff690352cad890",
        "body": null
      },
      "subtitle": "El método usado no es compatible con el recurso enviado, recuerde que el método que utiliza este servicio es POST.",
      "status": "412"
    }]

  constructor() { }

  ngOnInit(): void {
  }

}
