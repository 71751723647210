<div id="layoutSidenav">
    <div id="layoutSidenav_content">
        <br>
        <div class="container">
            <app-main-title [title]="title" [subTitle]="subTitle"></app-main-title>
            <app-main-paragraph [paragraphTitle]="paragraphTitle" [paragraph]="paragraph"></app-main-paragraph>
         
            <h4>Una prescripción de medicamentos contiene los siguientes atributos:</h4>
                    <table class="table  table-bordered">
                        <thead class="thead-dark">
                            <tr style="padding: 0px !important;">
                                <th scope="col">Nombre</th>
                                <th scope="col">Tipo</th>
                                <th scope="col">Descripción</th>
                                <th scope="col">Ejemplo</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th scope="row">doctorDocumentType</th>
                                <td>Long</td>
                                <td>Tipo de documento del doctor quien hace o actualiza la prescripción 
                                    <app-parameters-modal-button [type]="'documents'"></app-parameters-modal-button>
                                </td>
                                <td>1</td>
                            </tr>
                            <tr>
                                <th scope="row">doctorDocumentNumber</th>
                                <td>Long</td>
                                <td>Número de documento del doctor quien hace o actualiza la prescripción</td>
                                <td>1111111</td>
                            </tr>
                            <tr>
                                <th scope="row">encounterId</th>
                                <td>Long</td>
                                <td>Id de la atención si la prescripcion esta vinculada a una atención médica</td>
                                <td>12500</td>
                            </tr>
                            <tr>
                                <th scope="row">PatientId</th>
                                <td>Long</td>
                                <td>Id del paciente</td>
                                <td>12800</td>
                            </tr>
                            <tr>
                                <th scope="row">improved</th>
                                <td>Boolean</td>
                                <td></td>
                                <td>true</td>
                            </tr>
                            <tr>
                                <th scope="row">prescriptedMedicine</th>
                                <td>List&lt;PatientMedicinePrescriptionBasicResponseDTO&gt;</td>
                                <td>Lista de medicamentos recetados en la prescripción</td>
                                <td></td>     
                            </tr>
                        </tbody>
                    </table>

                    <h4>Cada PatientMedicinePrescriptionBasicResponseDTO contiene los siguientes atributos:</h4>
                    <table class="table  table-bordered">
                        <thead class="thead-dark">
                            <tr style="padding: 0px !important;">
                                <th scope="col">Nombre</th>
                                <th scope="col">Tipo</th>
                                <th scope="col">Descripción</th>
                                <th scope="col">Ejemplo</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th scope="row">medicinePrescriptionType</th>
                                <td>String</td>
                                <td>Tipo de medicamento a prescribir:
                                    <ul>
                                        <li>ACTIVE_PRINCIPLE = principio activo</li>
                                        <li>MASTER_FORMULA = fórmula maestra</li>
                                        <li>COMMERCIAL_PRESENTATION = marca comercial</li>            
                                    </ul></td>
                                <td>"ACTIVE_PRINCIPLE"</td>
                            </tr>
                            <tr>
                                <th scope="row">comercialProductName</th>
                                <td>String</td>
                                <td>Nombre comercial del medicamento. (si es de tipo COMMERCIAL_PRESENTATION)</td>
                                <td>"COZAAR XQ 50 MG/5MG TABLETAS RECUBIERTAS / 5 mg - TABLETA"</td>
                            </tr>
                            <tr>
                                <th scope="row">comercialProductId</th>
                                <td>Long</td>
                                <td>Id del nombre comercial del medicamento. (si es de tipo COMMERCIAL_PRESENTATION) 
                                    <app-parameters-modal-button [type]="'commercialname'"></app-parameters-modal-button>
                                </td>
                                <td>315</td>
                            </tr>
                            <tr>
                                <th scope="row">magistralPreparationFormula</th>
                                <td>String</td>
                                <td>Nombre de la fórmula magistral (si es de tipo MASTER_FORMULA)</td>
                                <td>"preparado magistral"</td>
                            </tr>
                            <tr>
                                <th scope="row">principleActiveType</th>
                                <td>Long</td>
                                <td>id del principio Activo del medicamento a recetar (si es de tipo ACTIVE_PRINCIPLE)
                                    <app-parameters-modal-button [type]="'principleact'"></app-parameters-modal-button>
                                </td>
                                <td>347</td>     
                            </tr>
                            <tr>
                                <th scope="row">atcConcentrationId</th>
                                <td>Long</td>
                                <td>id de la concentracion del principio Activo del medicamento a recetar (si es de tipo ACTIVE_PRINCIPLE)
                                    <app-parameters-modal-button [type]="'atcconcentration'"></app-parameters-modal-button>
                                </td>
                                <td>13250</td>     
                            </tr>
                            <tr>
                                <th scope="row">intakemethod</th>
                                <td>Long</td>
                                <td>id del método de administración del medicamento
                                    <app-parameters-modal-button [type]="'intakemethod'"></app-parameters-modal-button>
                                </td>
                                <td>8</td>     
                            </tr>
                            <tr>
                                <th scope="row">quantityImproved</th>
                                <td>Double</td>
                                <td>Dosis a administrar</td>
                                <td>1</td>     
                            </tr>
                            <tr>
                                <th scope="row">quantityUnit</th>
                                <td>String</td>
                                <td>Unidad de la Dosis a administrar</td>
                                <td>"Tabletas"</td>     
                            </tr>
                            <tr>
                                <th scope="row">frecuencyImproved</th>
                                <td>Double</td>
                                <td>Frecuencia de la dosis a administrar</td>
                                <td>2</td>     
                            </tr>
                            <tr>
                                <th scope="row">frequencyUnit</th>
                                <td>Long</td>
                                <td>id de la unidad de la frecuencia a administrar
                                    <app-parameters-modal-button [type]="'frequencyunit'"></app-parameters-modal-button>
                                </td>
                                <td>3</td>     
                            </tr>
                            <tr>
                                <th scope="row">durationImproved</th>
                                <td>Double</td>
                                <td>Duración del tratamiento</td>
                                <td>1</td>     
                            </tr>
                            <tr>
                                <th scope="row">durationUnit</th>
                                <td>Long</td>
                                <td>id de la unidad de duración del tratamiento
                                    <app-parameters-modal-button [type]="'durationunit'"></app-parameters-modal-button>
                                </td>
                                <td>3</td>     
                            </tr>
                            <tr>
                                <th scope="row">totalQuantity</th>
                                <td>String</td>
                                <td>Es la cantidad de despacho del medicamento (si se deja en blanco y todas las unidades estan llenas se calcula automaticamente)</td>
                                <td>"12 Tabletas"</td>     
                            </tr>
                            <tr>
                                <th scope="row">indicationsTaking</th>
                                <td>String</td>
                                <td>Es la indicación de administración (si se deja en blanco se calcula automaticamente)</td>
                                <td>"Administrar 1 Tableta cada 2 Hora(s) durante 1 Día(s)"</td>     
                            </tr>
                            <tr>
                                <th scope="row">comments</th>
                                <td>String</td>
                                <td>Comentarios del doctor</td>
                                <td>"Tomar prefereiblemente despues del desayuno"</td>     
                            </tr>
                            <tr>
                                <th scope="row">pharmaForm</th>
                                <td>String</td>
                                <td>Forma farmaceútica</td>
                                <td></td>     
                            </tr>
                        </tbody>
                    </table>
                    <hr>
                    <app-footer></app-footer>

                    
        </div>
    </div>
</div>
