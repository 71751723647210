import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-authentication-nav-tabs',
  templateUrl: './authentication-nav-tabs.component.html',
  styleUrls: ['./authentication-nav-tabs.component.css']
})
export class AuthenticationNavTabsComponent implements OnInit {

  show=true;

  constructor() { }

  environmentShow(){
    if(sessionStorage.getItem('environment') == '0' ){
      this.show = false;
    }
  }

 

  ngOnInit(): void {
    this.environmentShow();
  }

}
