import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-exams-prescription',
  templateUrl: './exams-prescription.component.html',
  styleUrls: ['./exams-prescription.component.css']
})
export class ExamsPrescriptionComponent implements OnInit {

  title: string ="Prescripción de exámenes";
  subTitle: string ="Consumiendo los servicios especificados desde la API de Saludtools usted puede crear y leer prescripciones de exámenes, permitiendo al usuario una integración completa."

  paragraphTitle: string ="¿La información de las atenciones es confidencial?";
  paragraph: string ="TOTALMENTE. Saludtools es un sistema de primer nivel que garantiza totalmente la confidencialidad de la información personal de sus pacientes. NUNCA se compartirá con un tercero o se comercializará sin la autorización expresa del médico o el paciente. Este compromiso se documenta en nuestra licencia de uso, que deja este punto totalmente claro.";
  constructor() { }

  ngOnInit(): void {
  }

}
