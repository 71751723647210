<div class="modal-header">
    <h4 class="modal-title">Consulta de la concentración</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="row">
        <div class="col-md-12">
            <div class="body-param">
                <form (ngSubmit)="onSubmit()" [formGroup]="authenticationForm">
                    <h3>Ingrese el id del principio activo</h3>
                    <div class="input-whith-icon">
                        <input type="text" formControlName="principleactive" class="form-control" placeholder="Principio activo"
                            [class.is-invalid]="(authenticationForm.get('principleactive').touched && authenticationForm.get('principleactive').invalid) || showClass" />
                        <i class="fas fa-prescription-bottle-alt"></i>
                        <div *ngIf="form.principleactive.errors" class="invalid-feedback">
                            <div *ngIf="form.principleactive.errors.required">El principio activo es obligatorio es obligatorio</div>
                        </div>
                    </div>
                    <br>
                    <div class="col-md-12 text-right">
                        <button class="stlinkbutton" type="reset" (click)="onReset()">Reset</button>
                        <button class="stbutton">Enviar</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="activeModal.close('Close click')">Cancelar</button>
</div>
