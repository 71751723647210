import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-medicine-delete',
  templateUrl: './medicine-delete.component.html',
  styleUrls: ['./medicine-delete.component.css']
})
export class MedicineDeleteComponent implements OnInit {

  title: string = "Borrar Prescripción de Medicamentos";
  subTitle: string = "Borrar una nueva prescripción es muy facil, solo tiene que saber el Id de la prescripción."

  paragraphTitle: string = "¿Cómo borrar una nueva prescripción de medicamentos?";
  paragraph: string = "Para borrar una nueva prescripción demedicamentos, primero debe tener un Token de acceso activo. Si su Token se venció, o aún no ha generado un Token de acceso. Genere un nuevo Token y consuma el evento de borrar prescripción médica, sólo necesita saber el id de la prescripción que se quiere borrar";

  parameters = [
    {
      "Parameters": "eventType",
      "requerido": true,
      "Type": "String",
      "Description": "Es el tipo de evento que se quiere invocar en este caso debe ser \"MEDICINE\"."
    },
    {
      "Parameters": "actionType",
      "requerido": true,
      "Type": "String",
      "Description": "Es la acción que se quiere realizar para borrar una prescripción debe ser \"DELETE\""
    },
    {
      "Parameters": "id",
      "requerido": true,
      "Type": "Long",
      "Description": "Id de la preescripción que se desea borrar"
    }
  ]

  jsonEjemplos =
    [{
      "title": "Body",
      "body":
      {
        "eventType":"MEDICINE",
        "actionType":"DELETE",
        "body": {
            "id":816068
        }
      },
      "subtitle": "Body para borrar la prescripción de medicamentos con id 816068"
    }
    ]

  curlEjemplo = {
    "title": "cURL",
    "bodyPrueba": "curl --location --request POST 'https://saludtools.qa.carecloud.com.co/integration/sync/event/v1/' --header 'Authorization: Bearer XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX' --header 'Content-Type: application/json' --data-raw" + '\'{"eventType":"MEDICINE", "actionType":"DELETE", "body": { "id":81606 } }\'',
    "bodyProduccion": "curl --location --request POST 'https://saludtools.carecloud.com.co/integration/sync/event/v1/' --header 'Authorization: Bearer XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX' --header 'Content-Type: application/json' --data-raw" + '\'{"eventType":"MEDICINE", "actionType":"DELETE", "body": { "id":81606 } }\'',
    "subtitle": "Después de Bearer sustituya  las X por su access-token generado y cambie los parámetros por los datos de su preescripción a borrar."
  }

  jsonResultados = [
    {
      "title": "OK 200",
      "body": {
        "id": null,
        "code": 200,
        "message": "Se elimina  id: 81629",
        "eventId": "4f5af533b5f84279bc2582c09de250d4",
        "body": null
      },
      "subtitle": "La solicitud se realizó con éxito y la prescripción de medicamentos con id=81629 ha sido creada.",
      "status": "200"
    },
    {
      "title": "401 Unauthorized",
      "body": 
        "No tiene permisos para acceder al servidor",
      "subtitle": "Coloque un token de acceso válido.",
      "status": "401"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
    "code": 412,
    "message": "No se ha enviado un tipo de evento valido",
    "eventId": "fe645e7a430c40bb946d221fb688351d",
    "body": null
      },
      "subtitle": "Se omitió el parámetro \"eventType\", para borrar una prescripción es obligatorio el parametro \"eventType\", por favor agrégalo al inicio del body como en el ejemplo.",
      "status": "412"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
        "code": 400,
        "message": "JSON parse error: Cannot deserialize value of type `com.saludtools.integration.enumeration.EventTypeEnum` from String \"MEDICINEa\": not one of the values accepted for Enum class: [MEDICINE, PATIENT, APPOINTMENT]; nested exception is com.fasterxml.jackson.databind.exc.InvalidFormatException: Cannot deserialize value of type `com.saludtools.integration.enumeration.EventTypeEnum` from String \"PATIENTa\": not one of the values accepted for Enum class: [MEDICINE, PATIENT, APPOINTMENT]\n at [Source: (PushbackInputStream); line: 2, column: 17] (through reference chain: com.saludtools.integration.entities.EventIn[\"eventType\"])",
        "eventId": "48c76fbf91cf46a19ebd948c3d2a6d5e",
        "body": null
      },
      "subtitle": "Error en el valor del parámetro \"eventType\". Para borrar una prescripción el valor permitido del \"eventType\"es \"MEDICINE\".",
      "status": "400"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
    "code": 400,
    "message": "No se ha enviado una accion vÃ¡lida",
    "eventId": "dd24b96692b744e4b97b397e5a3c1dd9",
    "body": null
      },
      "subtitle": "Se omitió el parámetro \"actionType\", para borrar una prescripción es obligatorio el parámetro \"actionType\" que indica la acción de borrar la prescripción de medicamentos, por favor agrégalo al inicio del body como en el ejemplo.",
      "status": "400"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
        "code": 400,
        "message": "JSON parse error: Cannot deserialize value of type `com.saludtools.integration.enumeration.ActionTypeEnum` from String \"READA\": not one of the values accepted for Enum class: [SEARCH, UPDATE, DELETE, CREATE, READ]; nested exception is com.fasterxml.jackson.databind.exc.InvalidFormatException: Cannot deserialize value of type `com.saludtools.integration.enumeration.ActionTypeEnum` from String \"READA\": not one of the values accepted for Enum class: [SEARCH, UPDATE, DELETE, CREATE, READ]\n at [Source: (PushbackInputStream); line: 3, column: 18] (through reference chain: com.saludtools.integration.entities.EventIn[\"actionType\"])",
        "eventId": "a3d1a85dc6b649a2b4cb5ccc10d3e1c3",
        "body": null
      },
      "subtitle": "Error en el valor del parámetro \"actionType\". Para borrar una prescripción de medicamentos el valor permitido del parámetro \"actionType\" es \"DELETE\".",
      "status": "400"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
    "code": 412,
    "message": "Para Id enviado, no se ha encontrado una prescripcion en nuestra base de datos.",
    "eventId": "5e39649a8983495c9d2b749facc31790",
    "body": null
       
      },
      "subtitle": "El id de la prescripción no se encuentra en la base de datos, por favor verifique y corrija el id",
      "status": "400"
    },
    {
      "title": "412 Precondition Failed",
      "body": {
        "id": null,
    "code": 400,
    "message": "Request method 'PUT' not supported",
    "eventId": "81cf71d0bf1b41f391ff690352cad890",
    "body": null
      },
      "subtitle": "El método usado no es compatible con el recurso enviado, recuerde que el método que utiliza este servicio es POST.",
      "status": "400"
    }]


  constructor() { }

  ngOnInit(): void {
  }

}
